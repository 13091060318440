import { LessonPageData, LessonWord, WordsTagged } from '@/definitions/langoid'

export function getEnhancedWords (words: WordsTagged[], allWords: LessonWord[]) {
  return (words && words.length > 0)
    ? words.map((word: WordsTagged) => {
      return word.isWord
        ? {
          ...word,
          learningStatus: allWords?.[word.id_word]?.['learning-status'] ? allWords[word.id_word]['learning-status'] : 'L'
        }
        : word
    })
    : []
}

export function checkIsFinished (finished: boolean, sentencesKeys: number[], lesson: LessonPageData) {
  if (finished) return false
  const arr: any = []

  sentencesKeys.forEach((key) => {
    lesson.sentences[key].tagged.forEach((word) => {
      if (word.isWord && word.id_word > 0) {
        arr.push(lesson.words[word.id_word]?.['learning-status'] === 'L')
      }
    })
  })

  return arr.every((item: any) => !item)
}
