<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  export let current = 1,
    count = 1

  const around = 4
  const twoSides = 2
  const dispatch = createEventDispatcher()
  let disableFirst = false, disableLast = false

  function range (size: number, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt)
  }

  function changePage (page: number, prevent = false) {
    if (prevent) {
      return
    }
    if (page !== current) {
      dispatch('change', page)
    }
  }

  $:disableFirst = current < 2
  $:disableLast = current >= count
</script>
{#if count > 1}
  <nav class='pagination-container'>
    <div class='_row'>
      <FakeButton class='_icon {disableFirst?`disabled`:``}' onClick={() => changePage(1,disableFirst)}>
        <Icon icon='CaretDoubleLeft' weight='thin' />
      </FakeButton>
      <FakeButton class='_icon {disableFirst?`disabled`:``}' onClick={() => changePage(current - 1,disableFirst)}>
        <Icon icon='CaretLeft' weight='thin' />
      </FakeButton>
    </div>
    <div class='_row'>
      {#if count < around * twoSides}
        {#each range(count, 1) as page}
          <button class='_tertiary-btn' class:active={page === current} type='button' on:click={() => changePage(page)}>{page}</button>
        {/each}
      {:else}
        {#each range(current <= around ? around - (around - current) - 1 : around, current <= around ? 1 : current - around) as page}
          <button class='_tertiary-btn' type='button' on:click={() => changePage(page)}>{page}</button>
        {/each}
        <button class='active' type='button'>{current}</button>
        {#each range(count - current < around ? count - current : around, current + 1) as page}
          <button class='_tertiary-btn' type='button' on:click={() => changePage(page)}>{page}</button>
        {/each}
      {/if}
    </div>
    <div class='_row'>
      <FakeButton class='_icon {disableLast?`disabled`:``}' onClick={() => changePage(current + 1,disableLast)}>
        <Icon icon='CaretRight' weight='thin' />
      </FakeButton>
      <FakeButton class='_icon {disableLast?`disabled`:``}' onClick={() => changePage(count,disableLast)}>
        <Icon icon='CaretDoubleRight' weight='thin' />
      </FakeButton>
      <div class='counter' class:disabled={count <= around}>{current} / {count}</div>
    </div>
  </nav>
{/if}
<style lang='scss'>
  .pagination-container {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;

    > div {
      > button {
        line-height: 2.4rem;

        &.active {
          color: var(--Base-White);
          background-color: var(--Primary-Medium);
          border: none;
          outline: none;
          cursor: default;
        }
      }

      > :global(.disabled) {
        color: var(--Gray-Light);
        border-color: var(--Gray-Light);
        cursor: auto !important;
      }

      > .counter {
        margin-left: 1rem;
        font-size: 1rem;
        color: var(--text-primary-color);
      }
    }
  }
</style>
