<script lang='ts'>
  import { onDestroy } from 'svelte'

  import { toolsState } from '@/helpers/mixHelpers'

  import Keyboard from '@/components/chat/Keyboard.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import NotesBox from '@/components/tools/NotesBox.svelte'
  import PlaybackRateControl from '@/components/tools/PlaybackRateControl.svelte'
  import Tools from '@/components/tools/Tools.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import { _ } from '@/libs/i18n'
  import { chatSelectedWord, feat, toolsMenuStore } from '@/store'

  let showTools = false
  let toolsPage = 'wordInfo'
  let showNotes = false
  let type: 'word' | 'sentence' = 'word' // used on add word section
  let showKeyboard = false
  let toolsOpened = false

  let word = ''

  let chatWord: any = {}
  $:toolsOpened = showTools || showNotes
  const toggleKeyboard = () => {
    showKeyboard = !showKeyboard
  }

  const unsubChatSelectedWord = chatSelectedWord.subscribe(w => {
    chatWord = w
  })
  $: if (chatWord?.word || chatWord?.content) {
    showTools = true
    if (chatWord?.id) {
      toolsPage = 'wordInfo'
      word = chatWord?.word
    } else if (chatWord?.content) {
      toolsPage = 'addWord'
      type = 'word'
      word = chatWord.content
    } else {
      word = ''
    }
    // Pass word to any method inside Tools component...
  }

  function openTools (type: string, tabType: 'word' | 'sentence' = 'word') {
    toolsMenuStore.update(state => toolsState(state, type, tabType))
  }

  const unsubscribeTools = toolsMenuStore.subscribe(state => {
    showNotes = state.showNotes
    showTools = state.showTools
    toolsPage = state.toolsPage
    type = state.addWordsType
  })

  onDestroy(() => {
    unsubChatSelectedWord()
    unsubscribeTools()
  })
</script>
{#if toolsOpened}
  <Overlay onClick={() => {if (!showKeyboard) {showNotes = false; showTools = false}}} variant='rightContent'>
    <div class='menu-items'>
      <div class='buttons-wrapper'>
        {#if $feat.vocabulary}
          <button
            class:_tertiary-btn={toolsPage !== 'wordInfo'}
            type='button'
            on:click={() => openTools('wordInfo')}
          >
            {$_('tools.wordInfo')}
          </button>
        {/if}
        <button
          class:_tertiary-btn={toolsPage !== 'addWord'}
          type='button'
          on:click={() => openTools('addWord')}
        >
          {$_('tools.addToDictionary')}
        </button>
        <button
          class:_tertiary-btn={toolsPage !== 'addNotes'}
          type='button'
          on:click={() => openTools('addNotes')}
        >
          {$_('notes.notes')}
        </button>
      </div>
      <div class='keyboard-audio'>
        <PlaybackRateControl />
        <FakeButton onClick={toggleKeyboard} toggleKeyboard>
          <span class='_icon' class:-open={showKeyboard}><Icon icon='Keyboard' weight='regular' /></span>
        </FakeButton>
        <FakeButton class='_icon' onClick={() => { showTools = false; showNotes = false }}>
          <Icon icon='X' weight='regular' />
        </FakeButton>
      </div>
    </div>
    <div class='tools-info'>
      {#if showTools}
        <Tools {toolsPage} {type} {word} bind:showTools />
      {:else if showNotes}
        <NotesBox />
      {/if}
    </div>
  </Overlay>
{/if}
{#if showKeyboard}
  <div class='keyboard-control'>
    <Keyboard iso='' />
  </div>
{/if}
<style lang='scss'>
  button {
    font: var(--Medium-300);
  }

  .keyboard-control {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .menu-items {
    display: flex;
    gap: 1.6rem;
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;
  }

  .tools-info {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    overflow: auto;
    width: 100%;
    height: calc(100vh - 8rem);
  }

  .keyboard-audio, .buttons-wrapper {
    display: flex;
    gap: 0.8rem;

    > :global(.playback-control) {
      min-width: 8rem;
      height: 4rem;
      padding-inline: 0.8rem;
      color: var(--text-primary-color);
    }
  }

  ._icon {
    &.-open {
      background-color: var(--text-background)
    }
  }

  @media(max-width: 768px) {
    .menu-items {
      flex-direction: column-reverse;
    }

    .buttons-wrapper {
      flex-wrap: wrap;
    }

    .keyboard-audio {
      align-self: end;
    }
  }
</style>
