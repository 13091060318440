import { Writable, writable } from 'svelte/store'

import {
  ChatMessage,
  LanguageGroups,
  LanguageStatus,
  LevelName,
  SelectedWord,
  ToolsState,
  UserGoal,
  VocabularyType
} from '@/definitions/langoid'
import { MixedStoreData, PersistentStore } from '@/definitions/stores'

/**
 * Used to easily invalidate all keys at once.
 * We could add some unique autogenerated sufix in the future.
 */
export const storeKeys = {
  currentLevelForLessons: 'store.currentLevelForLessons',
  findMissionTabValue: 'store.findMissionTabValue',
  findTaskFilterValue: 'store.findTaskFilterValue',
  forumFilters: 'store.forumFilters',
  languages: 'store.languages',
  lastLearningLanguage: 'store.lastLearningLanguage',
  menuLanguage: 'store.menuLanguage',
  mixed: 'store.mixede',
  playBackRate: 'store.playbackRate',
  taskTranslateFromLanguage: 'store.taskTranslateFromLanguage',
  taskTranslateToLanguage: 'store.taskTranslateToLanguage',
  toolsMenuStore: 'storeKeys.toolsMenuStore',
  toolsMyLanguage: 'store.toolsMyLanguage',
  toolsTranslateToLanguage: 'store.toolsTranslateToLanguage',
  userConfig: 'store.userConfig2',
  vocabularyType: 'store.vocabularyType'
}

/**
 * Create a persistent store that will be stored in localStorage.
 *
 * @param name - Name of key we will use in localStorage.  Should be in format `store.name`
 * @param initialValue - Initial value of the store
 */
export const createPersistentStore = <T> (name: string, initialValue: T): PersistentStore<T> => {
  let store = writable(initialValue)
  const storedValue = localStorage.getItem(name)
  const finalValue = storedValue
    ? typeof initialValue !== 'string'
      ? JSON.parse(storedValue)
      : storedValue
    : initialValue

  store = writable(finalValue, () => {
    const unsubscribe = store.subscribe((value) => {
      if (typeof initialValue === 'string') {
        localStorage.setItem(name, value as string)
      } else if (typeof initialValue === 'number') {
        localStorage.setItem(name, JSON.stringify(value))
      } else if (typeof initialValue === 'object') {
        localStorage.setItem(name, JSON.stringify(value))
      } else {
        console.error(`Unknown initial store type:`, typeof initialValue, initialValue)
        localStorage.setItem(name, JSON.stringify(value))
      }
    })

    const updateStore = (e: StorageEvent) => {
      if (e.key === name && e.newValue !== e.oldValue) {
        const newValue = typeof initialValue === 'object' ? JSON.parse(e.newValue || '') : e.newValue
        store.set(newValue)
      }
    }

    window.addEventListener('storage', updateStore)

    return () => {
      window.removeEventListener('storage', updateStore)
      unsubscribe()
    }
  })

  return {
    ...store,
    reset: () => store.set(initialValue),
    setKey: (key: keyof T, value: T[keyof T]) => store.update((storeValue) => ({
      ...storeValue,
      [key]: value
    })),
    setKeys: (keys: Record<string, T>) => store.update((storeValue) => ({ ...storeValue, ...keys }))
  }
}

const initialUser = JSON.parse(localStorage.getItem(storeKeys.userConfig) || '{}')
// This store is populated from the component Menu. Used to have fresh userData without calling database
export const userStorredConfig = createPersistentStore(storeKeys.userConfig, initialUser)
const emptyForumConfig: LanguageGroups = {
  known: [],
  learn: []
}
// Used for filters in the forum.
export const forumConfig: PersistentStore<LanguageGroups> = createPersistentStore(storeKeys.forumFilters, emptyForumConfig)

// used for languages filter on task select page
export const taskTranslateToLanguage: PersistentStore<number> = createPersistentStore(storeKeys.taskTranslateToLanguage, 0)
export const lastLearningLanguage: PersistentStore<number> = createPersistentStore(storeKeys.lastLearningLanguage, 0)
export const taskTranslateFromLanguage: PersistentStore<number> = createPersistentStore(storeKeys.taskTranslateFromLanguage, 0)
// For filters on tools page
export const toolsTranslateToLanguage: PersistentStore<number> = createPersistentStore(storeKeys.toolsTranslateToLanguage, 0)
// For my language selection on addWord and addSentence on tools page
export const toolsMyLanguage: PersistentStore<number> = createPersistentStore(storeKeys.toolsMyLanguage, 0)

export const currentLevelForLessons: PersistentStore<LevelName> = createPersistentStore(storeKeys.currentLevelForLessons, 'A0' as LevelName)

// used for active filter on tasks page
export const findTaskFilterValue = createPersistentStore(storeKeys.findTaskFilterValue, 'active')

// used for active tab on missions
export const findMissionTabValue = createPersistentStore(storeKeys.findMissionTabValue, 'regular')

const emptyLanguagesStore = {} as Record<string, LanguageStatus>
export const languagesStore: PersistentStore<Record<string, LanguageStatus>> = createPersistentStore(storeKeys.languages, emptyLanguagesStore)
/**
 * Try to create store for multiple things in one place.
 */
export const mixStore: PersistentStore<MixedStoreData> = createPersistentStore(storeKeys.mixed, {
  messagesCounts: {},
  notificationsCount: 0
})

/* Chat store, no need to make them persistent, we are using them just inside current tab */
export const messagesStore: Writable<ChatMessage[]> = writable([])
export const chatSelectedUser: Writable<number> = writable(0)
export const chatSelectedWord: Writable<SelectedWord | null> = writable(null)

// When creating new goals used to store goals.
export const goalsStore: Writable<UserGoal[]> = writable([])

// used to hold last clicked element for virtual keyboard
export const lastFocusedInput: Writable<HTMLInputElement | HTMLTextAreaElement | null> = writable<HTMLInputElement | HTMLTextAreaElement | null>(null)

// to prevent to audios playing at the same time - no need to save it in ls
export const playingAudio = writable<HTMLAudioElement | null>(null)

// Audio speed on listen pages
export const playbackRate: PersistentStore<number> = createPersistentStore(storeKeys.playBackRate, 1)

export const menuLanguage: PersistentStore<string> = createPersistentStore(storeKeys.menuLanguage, '')

export const feat = writable({}) // used for permissions

export const toolsMenuStore = writable<ToolsState>({
  addWordsType: 'word',
  showNotes: false,
  showTools: false,
  toolsPage: 'wordInfo',
})

export const vocabularyType: PersistentStore<VocabularyType> = createPersistentStore(storeKeys.vocabularyType, 'words' as VocabularyType)
