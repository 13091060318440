<script>
  import { AUDIO_PLAYER_SPEED, BATCH_OF_AUDIO_WORDS, PAUSE_BETWEEN_AUDIO_WORDS } from '@/config/constants'

  import Accordion from '@/components/ui/Accordion.svelte'

  import { _ } from '@/libs/i18n'

  export let direction = 'interface',
    number,
    speed = 1,
    silenceLength

  let show = false

  const closeOptions = () => {
    show = false
  }

  const receiveValue = (e) => {
    show = e.detail
  }
</script>

<Accordion {show} on:optionVisability={receiveValue}>
  <h4 slot='header'> {$_('listenadvancedOptions')} </h4>
  <div slot='content' class='play-options-wrapper'>
    <div class='play-option'>
      <h5>{$_('listenhowToPlay')}</h5>
      <label class='custom-radio-circle'>
        <input checked type='radio' value='interface' bind:group={direction} name='direction' />
        <span class='radioIcon'>{$_('listeninterfaceFirst')}</span>
      </label>
      <label class='custom-radio-circle'>
        <input type='radio' value='learning' bind:group={direction} name='direction' />
        <span class='radioIcon'>{$_('listenlearningFirst')}</span>
      </label>
      <label class='custom-radio-circle'>
        <input type='radio' value='learning-only' bind:group={direction} name='direction' />
        <span class='radioIcon'>{$_('listenjustLearning')}</span>
      </label>
    </div>
    <div class='play-option'>
      <h5>{$_('listennumOfAudios')}</h5>
      <div class='custom-radios-square'>
        {#each Object.keys(BATCH_OF_AUDIO_WORDS) as batch}
          <label>
            <input type='radio' value={+batch} bind:group={number} name='number' />
            <span>{BATCH_OF_AUDIO_WORDS[batch]}</span>
          </label>
        {/each}
      </div>
    </div>
    <div class='play-option'>
      <h5>{$_('listenspeed')}</h5>
      <div class='custom-radios-square'>
        {#each Object.keys(AUDIO_PLAYER_SPEED) as audioSpeed}
          <label>
            <input type='radio' value={+audioSpeed} bind:group={speed} name='speed' />
            <span>{AUDIO_PLAYER_SPEED[audioSpeed]}</span>
          </label>
        {/each}
      </div>
    </div>
    <div class='play-option'>
      <h5>{$_('listensilenceLength')}</h5>
      <div class='custom-radios-square'>
        {#each Object.keys(PAUSE_BETWEEN_AUDIO_WORDS) as pauseValue}
          <label>
            <input type='radio' value={+pauseValue} bind:group={silenceLength} name='silenceLength' />
            <span>{PAUSE_BETWEEN_AUDIO_WORDS[pauseValue]}</span>
          </label>
        {/each}
      </div>
    </div>
    <hr />
    <input type='button' value={$_('listensave')} on:click={closeOptions} />
  </div>
</Accordion>

<style lang='scss'>
  .play-options-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    > input {
      align-self: end;
    }
  }

  .play-option {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .custom-radio-circle {
    font: var(--Regular-400);
  }

  .custom-radios-square > label {
    > span {
      width: fit-content;
      min-width: 2rem;
    }
  }

  @media (max-width: 768px) {
    .play-options-wrapper {
      gap: 1.6rem;
    }
  }
</style>
