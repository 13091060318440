<script lang='ts'>
  import { onDestroy } from 'svelte'

  import { _ } from '@/helpers/i18n'

  import LanguagePageLink from '@/components/languagePage/LanguagePageLink.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { LanguageStatus } from '@/definitions/langoid'
  import { languagesStore } from '@/store'

  export let iso = ''

  let languageStatus = $languagesStore[iso]?.status as LanguageStatus
  const unsubscribe = languagesStore.subscribe(value => {
    languageStatus = value[iso]
  })

  onDestroy(() => {unsubscribe()})
</script>
{#if languageStatus}
  <Breadcrumb
    breads={[
    {text:'',url:`/${iso}`},
    {text:$_('levelPage.listen')}
    ]}
  >
    <h2 class='heading-mobile'>{$_('levelPage.listen')}</h2>
    <div slot='pageHelperIcons'>
      <PageInfo kebabItem pageName='listen' />
    </div>
  </Breadcrumb>
  <div class='action-tasks _gap24'>
    <LanguagePageLink
      icon='Headphones'
      {languageStatus}
      priv='listen.words'
      title={$_('levelPage.listenWords')}
      url={`/${iso}/listen-words`}
    />
    <LanguagePageLink
      icon='Headphones'
      {languageStatus}
      priv='listen.sentences'
      title={$_('levelPage.listenSentences')}
      url={`/${iso}/listen-sentences`}
    />
  </div>
{/if}
