<script lang='ts'>
  import { diff2 } from '@/helpers/diff'
  import { _ } from '@/helpers/i18n'
  import { formatScriptLine } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import Icon from '@/components/icons/Icon.svelte'

  type BatchDetailsApiAnswer = {
    batch: {
      active_lines: string,
      correct: number,
      numOfQuestionsAnswered: number,
      numOfQuestionsInBatch: number,
      numOfQuestionsInScript: number,
      time: number,
      wrong: number
    },
    results: {
      answer: string,
      answered?: boolean | undefined,
      line: number,
      newAnswer?: string | undefined,
      original: string,
      rating: number,
      translation: string
    }[]
  }
  export let details = {} as BatchDetailsApiAnswer
  let formatType: 'diff' | 'normal' | 'learn' = 'diff'
  details.results = (Object.values(details.results)).map((result: any) => {
    result.newAnswer = result.answer
    return result
  })
  const { batch, results } = details
  const getClassForRating = (rating: number): string => {
    if (rating >= 5) return '-correct'
    if (rating > 3) return '-notBad'
    return '-wrong'
  }
  const getTimeFormatted = (time: number): string => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = Math.floor(time % 60) % 60
    let returnString = ''
    if (hours > 0) returnString += `${hours}h `
    if (minutes > 0) returnString += `${minutes}m `
    if (seconds > 0) returnString += `${seconds}s`
    return returnString
  }

  const checkAnswer = (result: any, index: number) => {
    const answer = result.newAnswer
    const original = result.original
    if (answer === result.answer) {
      notifier.error('Answer is the same as previous answer')
      return false
    }
    result.answered = true
    if (diff2(answer, original) === answer) {
      result.rating = 5
      result.newAnswer = ''
    } else {
      result.rating = 0
    }
    results[index] = result
  }
  const resetAnswer = (result: any, index: number) => {
    result.newAnswer = result.answer
    result.answered = false
    results[index] = result
  }
</script>
<h2>{$_('script.batchDetails')}</h2>
<table>
  <tr>
    <td>{$_('script.answeredQuestions')}</td>
    <td>{batch.numOfQuestionsAnswered}
      / {batch.numOfQuestionsInScript > batch.numOfQuestionsInBatch ? `${batch.numOfQuestionsInBatch} this round, (${batch.numOfQuestionsInScript} total)` : batch.numOfQuestionsInScript}

      {#if batch.numOfQuestionsAnswered < batch.numOfQuestionsInScript}
        &nbsp;&nbsp;&nbsp;&nbsp;<small>{Math.round(batch.numOfQuestionsAnswered / batch.numOfQuestionsInScript * 100)}%</small>
      {/if}
    </td>
  </tr>
  <tr>
    <td>{$_('mix.correct')}</td>
    <td>{batch.correct}</td>
  </tr>
  <tr>
    <td>{$_('mix.wrong')}</td>
    <td>{batch.wrong}</td>
  </tr>
  <tr>
    <td>{$_('script.time')}</td>
    <td>{getTimeFormatted(batch.time)}</td>
  </tr>
</table>
{#if Object.keys(results || {}).length}
  <h2>{$_('script.answers')}</h2>
  <p class='diff-options'>
    <label for='formatType'>{$_('script.format')}</label>
    <select id='formatType' bind:value={formatType}>
      <option value='diff'>{$_('script.difference')}</option>
      <option value='normal'>{$_('script.normal')}</option>
      <option value='learn'>{$_('script.learn')}</option>
    </select>
  </p>
  <table>
    <tr>
      <th>{$_('script.line')}</th>
      <th>{$_('mix.original')}</th>
      <th>{$_('mix.answer')}</th>
    </tr>
    {#each results as result,index}
      {@const original = formatScriptLine(result.original, false)}
      {@const answer = formatScriptLine(result.answer, false)}
      {#if !(formatType === 'learn' && result.answered === undefined && result.rating === 5)}
        <tr class='result-row'>
          <td>{1 + result.line}</td>
          <td>
            {#if formatType === 'learn'}
              {result.translation}
            {:else}
              {original}
            {/if}
          </td>
          {#if formatType === 'diff'}
            <td>
              {#if result.rating !== 5}
                {@html diff2(result.answer, original)}
              {:else}
                {result.answer}
                {#if result.answered}<small>(corrected)</small>{/if}
              {/if}
            </td>
          {:else if formatType === 'learn'}
            <td
              class='no-padding'
              class:green={result.rating === 5 && result.answered}
            >
              <div>
                <button
                  type='button'
                  on:click={() => {resetAnswer(result,index)}}
                  style:display={result.answered ? 'block' : 'none'}
                >
                  <Icon icon='ArrowClockwise' />
                </button>
                {#if result.answered}
                  <div class='new-answer'>
                    {@html diff2(result?.newAnswer || '', original)}
                  </div>
                {:else}
                  <textarea bind:value={result.newAnswer} />
                  <button type='button' on:click={() => {checkAnswer(result,index)}}>
                    <Icon icon='PaperPlaneTilt' />
                  </button>
                {/if}
              </div>
            </td>
          {:else}
            <td class='answer {getClassForRating(result.rating)}' title={result.rating.toString()}>{answer}
              {#if result.answered}<small>(corrected)</small>{/if}
            </td>
          {/if}
        </tr>
      {/if}
    {/each}
  </table>
{/if}
<style lang='scss'>
  .result-row {
    > .answer {
      &.-correct {
        color: var(--success-text);
      }

      &.-wrong {
        color: var(--error-text);
      }

      &.-notBad {
        color: var(--warning-text);
      }

    }
  }

  td, th {
    border-right: none;
    border-left: none;
  }

  td.no-padding {
    width: 50%;
    padding: 0;

    > div {
      display: flex;
      gap: 0.2rem;
      align-items: center;

      > textarea {
        width: 100%;
        height: 4rem;
        padding: 0.2rem;
        border-radius: 0;
      }

      > button {
        width: auto;
        height: 2.8rem;
        padding: 0;
        color: var(--Primary-Medium);
        background: none;
        border: none;
      }
    }
  }

  tr:has(td.green) td {
    background: var(--Success-Medium);
  }

  .diff-options {
    display: flex;
    gap: 1.2rem;
    align-items: center;
  }
</style>
