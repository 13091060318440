import {diffWords} from 'diff'
const options = {
  ignoreCase: true,
  ignoreWhitespace: true,

}

export const diff = (oldString: string, newString: string) => {
  const diff = diffWords(oldString, newString, options)
  let result = ''
  for (const part of diff) {
    if (part.added) {
      result += `<ins>${part.value}</ins>`
    } else if (part.removed) {
      result += `<del>${part.value}</del>`
    } else {
      result += part.value
    }
  }
  return result
}

export const diff2 = (oldString: string, newString: string) => {
  const oldStripped = stripPunctuation(oldString);
  const newStripped = stripPunctuation(newString);
  const diff = diffWords(oldStripped, newStripped, options)

  let result = ''
  for (const part of diff) {
    if (part.added) {
      result += `<ins>${part.value}</ins>`
    } else if (part.removed) {
      result += `<del>${part.value}</del>`
    } else {
      result += part.value
    }
  }
  return result === oldStripped ? oldString: result
}

const stripPunctuation = (text: string): string => {
  return text.replace(/[.?!,:()"';#”“’‘]+/gu, ' ').replace(/\s+/g,' ').trim();
};
