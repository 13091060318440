import { get } from 'svelte/store'

import { ISO_LENGTH } from '@/config/constants'

import { getIdFromIso, getIsoFromId } from '@/helpers/apiCall'

import { userStorredConfig } from '@/store'

export const shortCodes: Record<string, string> = {
  am: 'amh',
  ar: 'arb',
  az: 'aze',
  bd: 'ben',
  be: 'bel',
  bg: 'bul',
  bo: 'bod',
  ca: 'cat',
  cs: 'ces',
  cy: 'cym',
  da: 'dan',
  de: 'deu',
  dz: 'dzo',
  el: 'ell',
  en: 'eng',
  es: 'spa',
  et: 'est',
  eu: 'eus',
  fa: 'fas',
  fi: 'fin',
  fr: 'fra',
  ga: 'gle',
  gl: 'glg',
  gu: 'guj',
  he: 'heb',
  hi: 'hin',
  hr: 'hrv',
  ht: 'hat',
  hu: 'hun',
  hy: 'hye',
  id: 'ind',
  ig: 'ibo',
  is: 'isl',
  it: 'ita',
  ja: 'jpn',
  ka: 'kat',
  kk: 'kaz',
  km: 'khm',
  kn: 'kan',
  ko: 'kor',
  ku: 'kur',
  ky: 'kir',
  lo: 'lao',
  lt: 'lit',
  lv: 'lav',
  mk: 'mkd',
  ml: 'mal',
  mn: 'mon',
  mr: 'mar',
  my: 'mya',
  ne: 'nep',
  nl: 'nld',
  no: 'nor',
  om: 'orm',
  pa: 'pan',
  pl: 'pol',
  ps: 'pus',
  pt: 'por',
  ro: 'ron',
  ru: 'rus',
  sa: 'san',
  sd: 'snd',
  si: 'sin',
  sk: 'slk',
  sl: 'slv',
  sq: 'sqi',
  sr: 'srp',
  sv: 'swe',
  sw: 'swa',
  ta: 'tam',
  te: 'tel',
  tg: 'tgk',
  th: 'tha',
  ti: 'tir',
  tr: 'tur',
  tw: 'yue',
  uk: 'ukr',
  ur: 'urd',
  vi: 'vie',
  yo: 'yor',
  zh: 'cmn'
}

export function getBrowserLocales () {
  const browserLocales = (navigator.languages === undefined ? [navigator.language] : navigator.languages) || []
  return browserLocales.map(locale => locale.trim().split(/[-_]/)[0]).filter(locale => locale !== 'en')
}

export const getUserInterfaceLanguage = () => {
  const storedLocale = getIsoFromId(get(userStorredConfig).id_lang_interface, true)
  if (storedLocale) return storedLocale
  const browserLocales = getBrowserLocales()
  if (browserLocales.length === 0) {
    return 'eng'
  }
  const locale = browserLocales[0]
  return locale.length === ISO_LENGTH ? locale : shortCodes[locale]
}

export const setUserInterfaceLanguage = () => {
  userStorredConfig.setKey('id_lang_interface', getIdFromIso(getUserInterfaceLanguage()))
}
