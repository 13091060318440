import { isNumber } from 'chart.js/helpers'

import { ISO_LENGTH } from '@/config/constants'

export const calculateLocationGroup = (location: string, menuGroups: string[]) => {
  if (location.length === ISO_LENGTH + 1 || location.length <= 1 || menuGroups[2] === 'home') return 'home'
  if (location.includes('/lesson')) return 'lessons'
  if (['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].includes(menuGroups[2]) && isNumber(menuGroups[3])) return 'lessons' // todo - change url format for lesson page
  if (menuGroups[2] === 'words-explorer') return 'words-explorer'
  if (location.includes('/tests')) return 'tests'
  if (location.includes('/listen-')) return 'listen'
  if (location.includes('/task')) return 'exercise'
  if (location.includes('/reading')) return 'read'
  if (location.includes('/chat')) return 'chat'
  if (location.includes('/words-forms')) return 'words-forms'
  if (location.includes('/goals')) return 'goals'
  if (location.includes('/guides')) return 'guides'
  if (location.includes('/forum')) return 'forum'
  if (['c', 't'].includes(menuGroups[2])) return 'forum'
  if (location.includes('/search-users')) return 'search-users'
  if (location.includes('/missions')) return 'missions'
  if (location.includes('/notifications')) return 'notifications'
  if (location.includes('/profile')) return 'profile'
  if (location.includes('/settings')) return 'settings'
  if (location.includes('stats')) return 'stats'
  if (location.includes('/vocabulary')) return 'stats'
  return location.substring(1) // remove starting slash
}
