<script lang='ts'>
  import { MAX_NOTE_TITLE, MAX_NOTES_LENGTH } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { saveActivity } from '@/helpers/game'
  import { _ } from '@/helpers/i18n'
  import { truncate } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import Icon from '@/components/icons/Icon.svelte'
  import CheatingWarning from '@/components/tools/CheatingWarning.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import Kebab from '@/components/ui/Kebab.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import { Category, CurrentNote, QuillType, QuillWrapper } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  const dir: string = document.body.getAttribute('dir') || 'ltr'
  const options2 = { placeholder: $_('notes.noteContent') }

  const emptyNote: CurrentNote = {
    categoryId: 0,
    categoryTitle: '',
    content: '',
    created_at: '',
    excerpt: '',
    id: 0,
    title: ''
  }

  let quill: QuillType,
    categories: Category[] = [] as Category[],
    filteredCategories: Category[] = [] as Category[],
    notes: CurrentNote[] = [],
    filteredNotes: CurrentNote[] = [],
    loaded = false,
    noteId = 0,
    noteTitle = '',
    categoryId = 0,
    uncategorizedCategory: any = {},
    defaultCategory = 0,
    categoryTitle = '',
    searchCategories = '',
    searchNotes = '',
    currentNote: CurrentNote = emptyNote,
    screen: 'listCategories' | 'listCategoryNotes' | 'listAllNotes' | 'showNote' = 'listAllNotes',
    content2 = { html: '', text: '' },
    dialog: 'addCategory' | 'editCategory' | 'addNote' | 'editNote' | 'showNote' | '' = '',
    isSavedCategory = false,
    isEditedCategory = false,
    isSavedNote = false,
    isEditedNote = false

  $: categoryId = defaultCategory

  let language: number = $userStorredConfig.id_lang_learning

  async function loadNotes (language: number, categoryId: number) {
    if (!loaded) {
      loaded = true
      const data = await fetchData('main/notesLoad', { categoryId, language })
      categories = data.categories || []
      if (categories.length === 0) {
        const data2 = await fetchData('main/notesCategory', { action: 'add', language, title: 'Uncategorized' })
        categories.push({ id: data2.categories[0].id, title: data2.categories[0].title })
        categories = [...categories]
      }
      uncategorizedCategory = categories.find(cat => cat.title === 'Uncategorized')
      if (uncategorizedCategory) {
        defaultCategory = uncategorizedCategory.id
      }
      notes = data.notes || []
      filteredCategories = data.categories || []
      filteredNotes = data.notes || []
      import('@/helpers/svelteQuill').then((el: QuillWrapper) => {
        quill = el.quill
      })
    }
  }

  async function addNote () {
    if (noteTitle === '' || categoryId === 0) {
      notifier.error($_('notes.newNoteError'))
      return
    }
    if (noteId > 0) {
      if (isEditedNote) return

      isEditedNote = true
      const data = await fetchData('main/notesNote', {
        action: 'edit',
        categoryId,
        content: content2.html,
        language,
        noteId,
        title: noteTitle
      })
      notes = notes.map(el => el.id === noteId ? data : el)
      currentNote = data
      filteredNotes = [...notes]
      isEditedNote = false
      notifier.success($_('notes.noteEdited'))
    } else {
      if (isSavedNote) return

      isSavedNote = true
      const data = await fetchData('main/notesNote', {
        action: 'add',
        categoryId,
        content: content2.html,
        language,
        title: noteTitle
      })
      defaultCategory = uncategorizedCategory.id
      notes.unshift(data)
      notes = [...notes]
      filteredNotes = [...notes]
      isSavedNote = false
      notifier.success($_('notes.noteAdded'))
    }
    closeDialog()
  }

  function editNote (e: MouseEvent, note: CurrentNote): void {
    e.stopPropagation()
    currentNote = note
    noteId = note.id
    content2.html = note.content
    noteTitle = note.title
    dialog = 'editNote'
    defaultCategory = note.categoryId
  }

  async function deleteNote (e: MouseEvent, note: CurrentNote) {
    e.stopPropagation()
    if (confirm($_('notes.confirm'))) {
      await fetchData('main/notesNote', {
        action: 'delete', language,
        noteId: note.id
      })
      notes = notes?.filter(el => el.id !== note.id)
    }
  }

  async function saveCategory () {
    if (categoryTitle === '') {
      notifier.error($_('notes.newCategoryError'))
      return
    }
    if (categoryId > 0) {
      if (isEditedCategory) return

      isEditedCategory = true
      const data = await fetchData('main/notesCategory', { action: 'edit', id: categoryId, title: categoryTitle })
      if (data.exists?.length > 0) {
        notifier.error($_('notes.existsCategory'))
      } else {
        categories = categories.map(el => {
          if (el.id === categoryId) {
            return {
              categoryLanguage: language,
              id: categoryId,
              title: categoryTitle
            }
          } else {
            return el
          }
        })
        filteredCategories = [...categories]
        isEditedCategory = false
        notifier.success($_('notes.categoryEdited'))
      }
    } else {
      if (isSavedCategory) return

      isSavedCategory = true
      const data = await fetchData('main/notesCategory', { action: 'add', language, title: categoryTitle })
      if (data.exists?.length > 0) {
        notifier.error($_('notes.existsCategory'))
      } else {
        categories.unshift({ id: data.id, title: categoryTitle })
        categories = [...categories]
        filteredCategories = [...categories]
      }
      isSavedCategory = false
      notifier.success($_('notes.categoryAdded'))
    }
    closeDialog()
  }

  async function deleteCategory (e, cat: Category) {
    e.stopPropagation()
    if (confirm('Are you sure?')) {
      await fetchData('main/notesCategory', { action: 'delete', id: cat.id })
      notes = notes?.filter(el => el.categoryId !== cat.id)
      categories = categories.filter(el => el.id !== cat.id)
    }
  }

  function selectCategory (id: number): void {
    categoryId = id
    screen = 'listCategoryNotes'
  }

  function editCategory (e, cat: Category): void {
    e.stopPropagation()
    categoryId = cat.id
    categoryTitle = cat.title
    dialog = 'editCategory'
  }

  const textChange = (e: CustomEvent) => {
    content2 = e.detail
  }

  const onLangChange = (lang: number) => {
    loaded = false
    if (lang) loadNotes(lang, 0)
    language = lang
  }

  loadNotes(language, 0)

  const closeDialog = () => {
    dialog = ''
    noteId = 0
  }

  function filterCategories (category: any) {
    const regex = new RegExp(searchCategories, 'gi')
    return regex.test(category.title)
  }

  $: if (searchCategories === '') {
    categories = [...filteredCategories]
  } else {
    categories = filteredCategories.filter(filterCategories)
  }

  function filterNotes (note: any) {
    const regex = new RegExp(searchNotes, 'gi')
    return regex.test(note.title) || regex.test(note.content)
  }

  $: if (searchNotes === '') {
    notes = [...filteredNotes]
  } else {
    notes = filteredNotes.filter(filterNotes)
  }

</script>
<CheatingWarning />
<div class='notes-sidebar' {dir}>
  <div class='add-note _vertical'>
    <div class='notes-breadcrumb'>
      {#if screen === 'listCategories'}
        <button
          class='_icon'
          type='button'
          on:click={() => {screen='listAllNotes'}}
          on:keypress={() => {}}
        >
          <Icon icon='ArrowLeft' weight='regular' />
        </button>
      {:else if screen === 'listCategoryNotes'}
        <button
          class='_icon'
          type='button'
          on:click={() => {screen='listCategories'}}
          on:keypress={() => {}}
        >
          <Icon icon='ArrowLeft' weight='regular' />
        </button>
      {:else if screen === 'showNote'}
        <button
          class='_icon'
          type='button'
          on:click={() => {screen='listCategoryNotes'}}
          on:keypress={() => {}}
        >
          <Icon icon='ArrowLeft' weight='regular' />
        </button>
      {/if}
      {#if screen === 'listAllNotes'}
        {$_('notes.notes')}
      {:else}
        <FakeButton onClick={() => {screen='listAllNotes'}}>
          {$_('notes.notes')}
        </FakeButton>
      {/if}
      {#if screen === 'listCategories'}
        <span>/</span><strong>{$_('notes.categoriesList')}</strong>
      {:else if screen === 'listCategoryNotes'}
        <span>/</span>
        <FakeButton onClick={() => {screen='listCategories'}}>
          {$_('notes.categoriesList')}
        </FakeButton>
        <span>/</span>
        <strong>{truncate(categories.find(cat => cat.id === categoryId)?.title, MAX_NOTE_TITLE)}</strong>
      {:else if screen === 'showNote'}
        <span>/</span>
        <FakeButton onClick={() => {screen='listCategories'}}>
          {$_('notes.categoriesList')}
        </FakeButton>
        <span>/</span>
        <FakeButton onClick={() => {screen='listCategoryNotes'}}>
          {truncate(categories.find(cat => cat.id === categoryId)?.title, MAX_NOTE_TITLE)}
        </FakeButton>
        <span>/</span>
        <strong>{truncate(currentNote.title, MAX_NOTE_TITLE)}</strong>
      {/if}
    </div>
    <div class='languages-and-tabs'>
      {#key language}
        <LanguageSelect
          onChange={onLangChange}
          selectedLanguage={language || 0}
        />
      {/key}
      {#if screen === 'listCategories' || screen === 'listCategoryNotes' || screen === 'listAllNotes'}
        <button
          class='_transparent-blue'
          class:-active={screen === 'listAllNotes'}
          type='button'
          on:click={() => {screen='listAllNotes'; categoryId = 0}}
        >{$_('notes.allNotes')}</button>
        <button
          class='_transparent-blue'
          class:-active={screen === 'listCategories'}
          type='button'
          on:click={() => {screen='listCategories'}}
        >{$_('notes.categoriesList')}</button>
      {/if}
    </div>
    {#if screen === 'listCategories'}
      <div class='search-and-newnote'>
        <Icon icon='MagnifyingGlass' weight='regular' />
        <input class='search' placeholder='Search your categories' type='text' bind:value={searchCategories} name='searchCategories' />
        <button
          type='button'
          on:click={() => {
            dialog = 'addCategory'
            categoryTitle = ''
            categoryId = 0
          }}
        >{$_('notes.newCategory')}</button>
      </div>
      {#each categories as cat, index(cat.id)}
        {@const counter = notes?.filter(el => el.categoryId === cat.id).length}
        <FakeButton element='div' onClick={() => selectCategory(cat.id)}>
          <div class='category-row _gap24' class:empty={counter === 0}>
            <p class='title'>{cat.title}</p>
            <div>
              <small class='noteNumber'>({counter || 0})</small>
              {#if cat.title !== 'Uncategorized'}
                <FakeButton onClick={(e) => editCategory(e, cat)}>
                  <Icon icon='PencilSimple' />
                </FakeButton>
                <FakeButton onClick={(e) => deleteCategory(e, cat)}>
                  <Icon icon='Trash' />
                </FakeButton>
              {/if}
            </div>
          </div>
        </FakeButton>
      {:else}
        <EmptyState>
          <div>{$_('notes.emptyCategories')}</div>
        </EmptyState>
      {/each}
    {:else if screen === 'listCategoryNotes'}
      <div class='search-and-newnote'>
        <Icon icon='MagnifyingGlass' weight='regular' />
        <input class='search' placeholder='Search your notes' type='text' bind:value={searchNotes} name='searchNotes' />
        <button
          type='button'
          on:click={() => {
          currentNote = emptyNote
          noteTitle = ''
          dialog = 'addNote'
          saveActivity(['note-opened'])
        }}
        >{$_('notes.newNote')}
        </button>
      </div>
      <div class='notes-list'>
        {#each notes.filter(el => el.categoryId === categoryId) as note, index(note.id)}
          <div class='note-item _gap24'>
            <div
              class='note-content'
              role='button'
              tabindex={0}
              on:click={() => {
                currentNote = note;
                screen = 'showNote'
              }}
              on:keypress={() => {}}
            >
              <div class='title-and-content'>
                <h3>{note.title}</h3>
                {@html truncate(note.content, MAX_NOTES_LENGTH)}
              </div>
              <h6>{$_('notes.category') + ': ' + truncate(categories.find(cat => cat.id === note.categoryId)?.title, MAX_NOTE_TITLE)}</h6>
            </div>
            <div class='kebab-wrapper'>
              <Kebab>
                <FakeButton onClick={(e) => editNote(e, note)}>
                  <Icon icon='PencilSimple' /> {$_('notes.edit')}
                </FakeButton>
                <hr />
                <FakeButton onClick={(e) => deleteNote(e, note)}>
                  <Icon icon='Trash' /> {$_('notes.delete')}
                </FakeButton>
              </Kebab>
            </div>
          </div>
        {:else}
          <EmptyState>
            <div>{$_('notes.emptyNotes')}</div>
          </EmptyState>
        {/each}
      </div>
    {:else if screen === 'listAllNotes'}
      <div class='search-and-newnote'>
        <Icon icon='MagnifyingGlass' weight='regular' />
        <input class='search' placeholder='Search your notes' type='text' bind:value={searchNotes} name='searchNotes' />
        <button
          type='button'
          on:click={() => {
          currentNote = emptyNote
          noteTitle = ''
          dialog = 'addNote'
          defaultCategory = uncategorizedCategory.id
          saveActivity(['note-opened'])
        }}
        >{$_('notes.newNote')}
        </button>
      </div>
      <div class='notes-list'>
        {#each notes.filter(el => el.id) as note, index(note.id)}
          <div class='note-item _gap24'>
            <div
              class='note-content'
              role='button'
              tabindex={0}
              on:click={() => {
                currentNote = note;
                categoryId = note.categoryId
                screen = 'showNote'
              }}
              on:keypress={() => {}}
            >
              <div class='title-and-content'>
                <h3>{note.title}</h3>
                {@html truncate(note.content, MAX_NOTES_LENGTH)}
              </div>
              <h6>{$_('notes.category') + ': ' + truncate(categories.find(cat => cat.id === note.categoryId)?.title, MAX_NOTE_TITLE)}</h6>
            </div>
            <div class='kebab-wrapper'>
              <Kebab>
                <FakeButton onClick={(e) => editNote(e, note)}>
                  <Icon icon='PencilSimple' /> {$_('notes.edit')}
                </FakeButton>
                <hr />
                <FakeButton onClick={(e) => deleteNote(e, note)}>
                  <Icon icon='Trash' /> {$_('notes.delete')}
                </FakeButton>
              </Kebab>
            </div>
          </div>
        {:else}
          <EmptyState>
            <div>{$_('notes.emptyNotes')}</div>
          </EmptyState>
        {/each}
      </div>
    {:else if screen === 'showNote'}
      <div class='note-text _gap24'>
        <div class='kebab-wrapper'>
          <Kebab>
            <FakeButton onClick={(e) => editNote(e, currentNote)}>
              <Icon icon='PencilSimple' /> {$_('notes.edit')}
            </FakeButton>
            <FakeButton onClick={(e) => deleteNote(e, currentNote)}>
              <Icon icon='Trash' /> {$_('notes.delete')}
            </FakeButton>
          </Kebab>
        </div>
        <div>
          {@html currentNote.content}
        </div>
      </div>
    {/if}
  </div>

  {#if dialog}
    <Overlay onClick={closeDialog}>
      {#if dialog === 'addCategory' || dialog === 'editCategory'}
        <input type='hidden' bind:value={categoryId} name='categoryId' />
        <input
          class='textarea'
          maxlength='200'
          placeholder={$_('notes.writeNewCategory')}
          type='text'
          bind:value={categoryTitle}
          name='categoryName'
        />
        <div class='save-or-cancel'>
          <button disabled={isSavedCategory} type='button' on:click={saveCategory}>{$_('notes.saveCategory')}</button>
          <button type='button' on:click={closeDialog}>{$_('mix.cancel')}</button>
        </div>
      {:else if dialog === 'editNote' || dialog === 'addNote'}
        <div class='content-wrapper'>
          <input type='hidden' bind:value={noteId} name='noteId' />
          <p class='category-header'>{$_('notes.category')}:
            {#if screen === 'listCategoryNotes' && dialog === 'addNote'}
              {#each categories.filter(cat => cat.id === categoryId) as category}
                <p class='activeCatogory'>{category.title}</p>
              {/each}
            {:else}
              <select id='' bind:value={defaultCategory} name=''>
                {#each categories as cat, index(cat.id)}
                  <option value={cat.id}>{cat.title}</option>
                {/each}
              </select>
            {/if}
          </p>
          <input
            class='noteTitle'
            maxlength='200'
            placeholder={$_('notes.noteTitle')}
            type='text'
            bind:value={noteTitle}
            name='noteTitle'
          />
          <div class='quill-wrapper'>
            {#if quill}
              <div
                class='editor'
                on:text-change={textChange}
                use:quill={options2}
              >{@html currentNote.content}</div>
            {/if}
          </div>
        </div>
        <div class='save-or-cancel'>
          <button disabled={isSavedNote} type='button' on:click={addNote}>{$_('notes.saveNote')}</button>
          <button type='button' on:click={closeDialog}>{$_('mix.cancel')}</button>
        </div>
      {/if}
    </Overlay>
  {/if}
</div>

<style lang='scss'>
  $padding: 1.6rem;

  .notes-sidebar {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .languages-and-tabs, .search-and-newnote {
    display: flex;
    gap: 1.6rem;
    align-items: center;
  }

  ._transparent-blue {
    &.-active {
      font-weight: 900;
    }
  }

  .search-and-newnote {
    position: relative;

    > .search {
      width: 100%;
      padding-left: 4rem;
    }

    > :global(svg) {
      position: absolute;
      left: 1rem;
    }
  }

  .notes-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $padding;
  }

  .note-item {
    position: relative;
    padding: $padding 4.8rem $padding $padding; // override _gap24
    border-radius: $padding; // override _gap24

    &:hover {
      background: var(--Primary-Lighter);
    }
  }

  .note-content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    justify-content: space-between;
    overflow: hidden;
    height: 21.8rem;
    max-height: 21.8rem;
    line-height: 1.4;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .kebab-wrapper {
    position: absolute;
    top: $padding;
    right: $padding;
  }

  .add-note {
    > :global(.fake-button) {
      width: 100%;
    }

    > :global(.fake-button .category-row) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: $padding;
      border-radius: $padding;
    }

    > :global(.fake-button .category-row div) {
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      align-items: center
    }
  }

  .notes-breadcrumb {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }

  .note-text {
    min-height: 50vh;
    padding: $padding 4.8rem $padding $padding; // override _gap24
    border-radius: $padding;
  }

  .save-or-cancel {
    display: flex;
    gap: 0.8rem;

  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    > input::placeholder {
      font-size: 1.4rem;
    }
  }

  .category-header {
    display: flex;
    gap: 0.4rem;
    align-items: center;

    > .activeCatogory {
      padding: 0.8rem;
      border: 0.1rem solid var(--form-border);
      border-radius: 0.8rem;
    }
  }
</style>
