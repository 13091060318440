<script lang='ts'>
  import { languages } from '@/config/languages'

  import { _ } from '@/helpers/i18n'
  import { range, toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'

  type additionalLangType = { lang: number, level: string }
  type AnswerType = 'yes' | 'no' | number
  type Answers = Record<number, AnswerType>
  type additionalLangsType = Record<number, additionalLangType>
  type LanguageData = { id: number, name: string }

  interface AnswerSixType extends additionalLangType {
    data: LanguageData
  }

  export let onFinish = (answers: Answers) => { }
  let answerSix: AnswerSixType[]
  let isEverythingPopulated = false
  let additionalLanguagesCount = 2
  const additionalLanguages: additionalLangsType = {} as additionalLangsType
  let hasAdditinalLanguages = false
  $: if (additionalLanguages) {
    hasAdditinalLanguages = Object.values(additionalLanguages).some(item => item.lang !== undefined)
  }

  const answers: Answers = {
    // 1: 3, 2: 4, 3: 'yes', 4: 'yes', 5: 'yes'
  }

  let curentStep = Object.keys(answers).length + 1
  const questions: Record<number, string> = {
    1: $_('setup.qNativeLanguage'),
    2: $_('setup.qLearningLanguage'),
    3: $_('setup.qKnowLang'),
    4: $_('setup.qKnowToRead'),
    5: $_('setup.qInterface'),
    6: $_('setup.qOtherLangs')
  }
  const MAX_QUESTION_NUMBER = 6
  const questionKeys = Object.keys(questions).map(Number)
  let nativeLanguage = 0
  let learningLanguage = 0

  const goToNextStep = () => {
    if (answers[curentStep + 1] !== undefined) {
      curentStep = Object.keys(answers).length + 1
    } else {
      curentStep++
    }
  }

  const setNativeLangue = (idLanguage: number) => {
    nativeLanguage = idLanguage
    handleChange(idLanguage, 1)
  }

  const setLearningLanguage = (idLanguage: number) => {
    learningLanguage = idLanguage
    handleChange(idLanguage, 2)
  }

  const handleChange = (answer: AnswerType, stepOriginal: number): boolean => {
    const step = stepOriginal
    const value = answer as AnswerType

    if (step === 1 && nativeLanguage === learningLanguage) {
      notifier.error($_('setup.sameLanguage'))
      learningLanguage = 0
      return false
    }

    if (step === 2 && nativeLanguage === learningLanguage) {
      notifier.error($_('setup.sameLanguage'))
      learningLanguage = 0
      return false
    }

    if (step === 5) {
      if (value === 'no') {
        notifier.info($_('setup.pleaseInterface'))
      }
      if (value === learningLanguage) {
        notifier.error($_('setup.sameInterface'))
        return false
      } else {
        answers[step] = value
      }
      if (value !== 'no') {
        goToNextStep()
      } else {
        curentStep = step
      }

    } else if (step === 6) {
      if (value === 'yes') {
        notifier.info($_('setup.pleaseKnown'))
      }
      answers[step] = value
      if (value !== 'yes') {
        goToNextStep()
      } else {
        curentStep = step
      }
    } else {
      answers[step] = value
      if (step <= 6) {
        goToNextStep()
      }
    }
    isEverythingPopulated = Object.keys(answers).length > 5
    return true
  }

  const handleAddingLanguages = (val: number | string, i: number, type: 'lang' | 'level') => {
    if (additionalLanguages[i] === undefined) {
      additionalLanguages[i] = {} as additionalLangType
    }
    if (type === 'lang') {
      const addedLang = val
      // add language only if it's not same as learning or native, or already added
      if (answers[1] === addedLang || answers[2] === addedLang || Object.values(additionalLanguages).find((el: additionalLangType) => el.lang === addedLang)) {
        notifier.error($_('setup.sameLanguage'))
        val = 0
        return false
      }
    }
    if (type === 'lang' && typeof val === 'number') {
      additionalLanguages[i].lang = val
    }
    if (type === 'level' && typeof val === 'string') {
      additionalLanguages[i].level = val
    }
    return true
  }

  const languagesAdded = () => {
    answerSix = Object.values(additionalLanguages).map((el: additionalLangType) => ({
      ...el,
      data: languages.find(l => l.id === toInt(el.lang)) || {
        id: 0,
        name: ''
      }
    }))
    goToNextStep()
    isEverythingPopulated = true
  }
</script>
<div class='initial-setup-wrapper'>
  <div class='initial-setup'>
    <ProgressBar clickable={false} maxProgress={MAX_QUESTION_NUMBER} progress={curentStep> MAX_QUESTION_NUMBER? MAX_QUESTION_NUMBER: curentStep} showNumbers />
    <div class='steps-list'>

      <div class='step-block' class:-current={curentStep === 1}>
        <h2 class='initialSetupQuestion'>1. {questions[1]}</h2>
        <div class='hint'>{$_('setup.pleaseUseYourNativeLanguage')}</div>
        <LanguageSelect onChange={setNativeLangue} placeholder={$_('setup.chooseLanguage')} type='all' bind:selectedLanguage={nativeLanguage} />
      </div>

      <div class='step-block' class:-current={curentStep === 2}>
        <h2 class='initialSetupQuestion'>2. {questions[2]}</h2>
        <div class='hint'>{$_('setup.chooseFirst')}</div>
        <LanguageSelect onChange={setLearningLanguage} type='all' bind:selectedLanguage={learningLanguage} />
      </div>

      <div class='step-block' class:-current={curentStep === 3}>
        <h2 class='initialSetupQuestion'>3. {questions[3]}</h2>
        <div class='hint'>{$_('setup.doYouKnowLang')}</div>
        <div class='radio-group'>
          <label><input type='radio' value='yes' on:change={() => { handleChange('yes', 3) }} name='step3' />{$_('mix.yes')}
          </label>
          <label><input type='radio' value='no' on:change={() => { handleChange('no', 3) }} name='step3' />{$_('mix.no')}
          </label>
        </div>
      </div>

      <div class='step-block' class:-current={curentStep === 4}>
        <h2 class='initialSetupQuestion'>4. {questions[4]}</h2>
        <div class='hint'>{$_('setup.doYouKnowToRead')}</div>
        <div class='radio-group'>
          <label><input type='radio' value='yes' on:change={() => { handleChange('yes', 4) }} name='step4' />{$_('mix.yes')}
          </label>
          <label><input type='radio' value='no' on:change={() => { handleChange('no', 4) }} name='step4' />{$_('mix.no')}
          </label>
        </div>
      </div>

      <div class='step-block -optional' class:-current={curentStep === 5}>
        <h2 class='initialSetupQuestion'>5. {questions[5]}</h2>
        <div class='hint'>{$_('setup.useNativeInterface')}</div>
        <div class='radio-group'>
          <label><input type='radio' value='yes' on:change={(e) => { handleChange('yes', 5) }} name='step5' />{$_('mix.yes')}
          </label>
          <label><input type='radio' value='no' on:change={(e) => { handleChange('no', 5) }} name='step5' />{$_('mix.no')}
          </label>
          {#if answers[curentStep] === 'no' || (typeof answers[curentStep] === 'number' && answers[curentStep])}
            <p>{$_('setup.chooseNativeInterface')}</p>
            <LanguageSelect onChange={(e) => { handleChange(e, 5) }} selectedLanguage={toInt(answers[5])} type='all' />
          {/if}
        </div>
      </div>

      <div class='step-block -optional' class:-current={curentStep === 6}>
        <h2 class='initialSetupQuestion'>6. {questions[6]}</h2>
        <div class='hint' />
        <div class='radio-group'>
          <label><input type='radio' value='no' on:change={(e) => { handleChange('no', 6) }} name='radio' />{$_('mix.no')}
          </label>
          <label><input type='radio' value='yes' on:change={(e) => { handleChange('yes', 6) }} name='radio' />{$_('mix.yes')}
          </label>
        </div>
        {#if answers[curentStep] && answers[curentStep] !== 'no'}
          {#each range(additionalLanguagesCount, 1) as i, index}
            <div class='level-block'>
              <LanguageSelect
                onChange={(e) => { handleAddingLanguages(e, i, 'lang') }}
                selectedLanguage={toInt(additionalLanguages[i]?.lang)}
                type='all'
              />
              <select
                value={additionalLanguages[i]?.level || ''}
                on:change={(e) => { handleAddingLanguages(e.target?.value, i, 'level') }}
                name='level'
              >
                <option value=''>{$_('mix.level')}</option>
                <option value='A1'>A1</option>
                <option value='A2'>A2</option>
                <option value='B1'>B1</option>
                <option value='B2'>B2</option>
                <option value='C1'>C1</option>
                <option value='C2'>{$_('profile.c2orNative')}</option>
                <option value='?'>{$_('profile.iDontKnow')}</option>
              </select>
            </div>
          {/each}
          <button type='button' on:click={() => ++additionalLanguagesCount}>{$_('setup.addMore')}</button>
          {#if Object.values(languages).length > 0}
            <button disabled={!hasAdditinalLanguages} type='button' on:click={languagesAdded}>{$_('setup.stepFinished')}</button>
          {/if}
        {/if}
      </div>
      <div class='step-block -everythingSetInitial' class:-current={curentStep === 7}>
        {$_('setup.everythingSet')}
      </div>
    </div>

    <div class='recap'>
      {$_('mix.save')}
      {#each questionKeys as key}
        {#if answers[key]}
          <div class='single-anwser'>
            {key}. {questions[key]}
            {#if [1, 2].includes(toInt(key)) || (key === 5 && toInt(answers[key]) > 0)}
              <strong class='yourAnswer'>{languages.find(l => l.id === toInt(answers[key]))?.name || ''}</strong>
            {:else if key === 6 && answers[key] === 'yes'}
              <strong class='yourAnswer'>{$_('mix.yes')}</strong>
              <span
                class='editAnswer'
                role='button'
                tabindex='0'
                on:click={() => { curentStep = key }}
                on:keypress={() => {}}
              >{$_('mix.edit')}</span>
              {#each Object.values(answerSix || {}) as lang}
                {#if lang.data?.name}
                  <div class='additionalLanguage'><strong>{lang.data?.name || ''}</strong> - {lang?.level || ''}</div>
                {/if}
              {/each}
            {:else}
              <strong class='yourAnswer'>
                {#if answers[key] === 'yes'}
                  {$_('mix.yes')}
                {:else if answers[key] === 'no'}
                  {$_('mix.no')}
                {:else}
                  {answers[key]}
                {/if}
              </strong>
            {/if}
            {#if !(key === 6 && answers[key] === 'yes')}
              <span
                class='editAnswer'
                role='button'
                tabindex='0'
                on:click={() => { curentStep = key }}
                on:keypress={() => {}}
              >{$_('mix.edit')}</span>
            {/if}
          </div>
        {/if}
      {/each}
    </div>

    {#if !(curentStep === 6 && answers[6] === 'yes')}
      {#if isEverythingPopulated}
        <button
          class='primary'
          type='button'
          on:click={() => {
            onFinish(answers)
          }}
        >{$_('setup.continue')}</button>
      {:else if answers[curentStep] && answers[5] !== 'no'}
        <button
          class='primary'
          type='button'
          on:click={() => {
              ++curentStep
            }}
        >{$_('setup.goToNextStep')}</button>
      {/if}
    {/if}
  </div>
</div>

<style lang='scss'>
  .initial-setup-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    overflow: auto;
    background: var(--inverted-text-color);
  }

  .initial-setup {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    max-width: 102.4rem;
    min-height: 68.4rem;
    margin: 6rem auto;
    padding: 2rem;
    text-align: center;
    border-radius: 0.4rem;

    > .recap {
      width: fit-content;
      min-width: 51.2rem;
      margin: 0 auto;
      text-align: left;
      border: 0.1rem solid var(--Primary-Lighter);

      &:empty {
        display: none;
      }
    }

    > .primary {
      margin: 0 auto;
    }
  }

  .single-anwser {
    border-bottom: 0.1rem solid var(--Gray-Lighter);

    > .yourAnswer {
      margin: 0 1.2rem;
      font-size: 1.6rem;
    }

    > .editAnswer {
      text-decoration: underline;
      color: var(--Primary-Medium);
      cursor: pointer;
    }

    > .additionalLanguage {
      padding: 0.4rem 0 0 1.8rem;
    }
  }

  .level-block {
    display: flex;
    gap: 1rem;
    max-width: 40.8rem;
  }

  .step-block {
    display: none;

    > .initialSetupQuestion {
      font-size: 3.2rem;
    }

    &.-current {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      padding-top: 4.8rem;
    }

    &.-everythingSetInitial {
      margin-top: 2.4rem;
      padding: 1.2rem;
      font-size: 1.6rem;
      background-color: var(--success-background);
      border: 0.1rem solid var(--success-text);
      border-radius: 2rem;
    }
  }

  @media (max-width: 768px) {
    .initial-setup {
      margin-top: 0;

    }

    .step-block {
      > .initialSetupQuestion {
        font-weight: bold;
        font-size: 2.2rem;
      }

      &.-everythingSetInitial {
        margin-bottom: 1.2rem;
        font-size: 1.2rem;
      }
    }
  }
</style>
