<script lang='ts'>
  export let title = '',
    type = 'default',
    src = ''
  let alt = ''
  switch (type) {
    case 'error':
      break
    case 'success':
      src = '/images/happy.png'
      alt = 'success'
      break
    case 'default':
      break
  }
</script>
<div class='empty-state'>
  <h2>{title}</h2>
  {#if src !== ''}
    <img {alt} {src} />
  {/if}
  <div class='empty-state-content'>
    <slot />
  </div>
</div>

<style lang='scss'>
  .empty-state {
    padding: 9.6rem 4.8rem;
    text-align: center;
  }
</style>
