<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import { getRating } from '@/helpers/langHelper'

  import PieChart from "@/components/social/PieChart.svelte";
  import Breadcrumb from "@/components/ui/Breadcrumb.svelte";

  import { Sentence, SentenceWord, VocabularyWord } from '@/definitions/langoid'
  import { _ } from '@/libs/i18n'
  import {chatSelectedWord, toolsMenuStore} from '@/store'
  type StatLevel = 'L0' | 'L1' | 'L2' | 'L3' | 'unknown' | 'all'

  export let id = 0
  export let iso = ''

  let selected = 0
  let sentences: Sentence[] = []
  let my: Record<string, VocabularyWord> = {}
  let name: string
  let stats: any
  let uniqueStats: Record<StatLevel, number> = {L0: 0, L1: 0, L2: 0, L3: 0, all: 0, unknown: 0}
  let chartValues: any[] = []
  let uniqueChartValues: any[] = []
  const style = getComputedStyle(document.body)
  let wordsList: any[] = []
  let currentLevel: StatLevel = 'all'

  fetchData('learn/importRead', { id }).then((data) => {
    sentences = data.tagged as Sentence[]
    name = data.name
    my = data.my
    stats = calculateStats(data.tagged)
    uniqueStats = calculateUniqueStats(data.tagged)
    chartValues = getChartValues(stats)
    uniqueChartValues = getChartValues(uniqueStats)
  })

  const getChartValues = (stats: {
    L0: number,
    L1: number,
    L2: number,
    L3: number,
    unknown: number
  }) => {
    return [
      {
        backgroundColor: style.getPropertyValue('--seen'),
        label: $_('vocabularyPage.label_seen') + ` (${stats.L0})`,
        value: stats.L0
      },
      {
        backgroundColor: style.getPropertyValue('--starting'),
        label: $_('vocabularyPage.label_starting') + ` (${stats.L1})`,
        value: stats.L1
      },
      {
        backgroundColor: style.getPropertyValue('--good'),
        label: $_('vocabularyPage.label_good') + ` (${stats.L2})`,
        value: stats.L2
      },
      {
        backgroundColor: style.getPropertyValue('--know'),
        label: $_('vocabularyPage.label_know') + ` (${stats.L3})`,
        value: stats.L3
      },
      {
        backgroundColor: style.getPropertyValue('--Warning-Dark'),
        label: $_('unknown') + ` (${stats.unknown})`,
        value: stats.unknown
      }
    ]
  }

  const createClassName = (word: SentenceWord, index: number, selected: number, myVocab: VocabularyWord): string => {
    const isWord: boolean = word?.id > 0
    const learningStatus = myVocab?.flag
    const classList = [`index-${index}`, getRating(myVocab?.rating)]

    if (isWord) {
      classList.push('single-word')
      if (word.id_word === 0) classList.push('proper-noun')
      if (learningStatus) classList.push(learningStatus)
      if (index === selected) classList.push('selected')
    } else {
      classList.push('non-word')
    }

    return classList.join(' ')
  }

  const calculateStats = (sentences: Sentence[]) => {
    const stats = {L0: 0, L1: 0, L2: 0, L3: 0, unknown: 0}
    for (const sentence of sentences) {
      for (const word of sentence.words) {
        if (word.id === 0) {
          if(word.type!=='space' && word.type!=='int' && word.content.length > 1){
            stats.unknown++
          }
        } else {
          const level = getRating(my?.[word.id]?.rating)
          if (level === 'L0') stats.L0++
          else if (level === 'L1') stats.L1++
          else if (level === 'L2') stats.L2++
          else if (level === 'L3') stats.L3++
        }
      }
    }
    return stats
  }
  const calculateUniqueStats = (sentences: Sentence[]) => {
    const stats = getUniqueWordsList(sentences)
    return {
      L0: Object.keys(stats.L0).length,
      L1: Object.keys(stats.L1).length,
      L2: Object.keys(stats.L2).length,
      L3: Object.keys(stats.L3).length,
      unknown: Object.keys(stats.unknown).length
    }
  }

  const getUniqueWordsList = (sentences: Sentence[]) => {
    const stats: any = {L0: {}, L1: {}, L2: {}, L3: {}, unknown: {}}
    for (const sentence of sentences) {
      for (const word of sentence.words) {
        if (word.id === 0) {
          if(word.type!=='space' && word.type!=='int' && word.content.length > 1){
            stats.unknown[word.content] = word.content
          }
        } else {
          const level = getRating(my?.[word.id]?.rating)
          if(typeof stats[level] === 'undefined'){
            stats[level] = {}
          }
          stats[level][word.id] = word.original
        }
      }
    }
    return stats
  }

  function selectWord (word: any) {
    selected = word.id
    const chatWord= {
      content: word.content,
      id: word.id,
      word: word.content
    }
    // open tools
    toolsMenuStore.update(state => {
      state.toolsPage = 'wordInfo'
      state.addWordsType = 'word'
      state.showTools = true
      return state
    })
    chatSelectedWord.set(chatWord)
  }
  const labels = {
    L0: $_('vocabularyPage.label_seen'),
    L1: $_('vocabularyPage.label_starting'),
    L2: $_('vocabularyPage.label_good'),
    L3: $_('vocabularyPage.label_know'),
    all: $_('mix.all'),
    unknown: $_('unknown')
  }

  const showWords = (level: any) => {
    wordsList = Object.values(getUniqueWordsList(sentences)[level])
    wordsList = wordsList
    currentLevel = level
  }
</script>

<div class='_gap24'>
  <Breadcrumb breads={[
    {text:'',url:`/${iso}`},
    {text: 'Imports', url: `/${iso}/advanced/import`},
    { text: name}
    ]} />
  <h2>{name}</h2>
  {#each sentences as sentence}
    {#if sentence}
      <div class='sentence-wrapper'>
        {#each sentence.words as word, index}
          <span
            class={createClassName(word, index, selected, my?.[word.id])}
            role='button'
            tabindex={0}
            on:click={() => selectWord(word)}
            on:keypress={() => {}}
          >{word.content}</span>
        {/each}
      </div>
    {/if}
  {/each}
  {#if stats}
    <div class='word-stats'>
      <div class='stat-row'>
        <span class='title'>Number of words</span>
        <span class='value'>{stats.L0 + stats.L1 + stats.L2 + stats.L3 + stats.unknown}</span>
      </div>
    </div>

    <div>
      <PieChart values={chartValues} />
    </div>
    <hr/>
    <div class='word-stats'>
      <div class='stat-row'>
        <span class='title'>Number of distinct words</span>
        <span class='value'>{uniqueStats.L0 + uniqueStats.L1 + uniqueStats.L2 + uniqueStats.L3 + uniqueStats.unknown}</span>
      </div>
      {#each Object.keys(uniqueStats) as level}
        <div class='stat-row'
             role='button'
             tabindex='0'
             on:click={() => {showWords(level)}}
             on:keypress={() => {}}
        >
          <span class='label {level}'>{labels[level]}</span>
          <span class='value'>{uniqueStats[level]}</span>
        </div>
      {/each}

      {#if wordsList.length > 0}
        <h3>List of words on level {labels[currentLevel]}</h3>
        <div class='words-list'>
          {#each wordsList as word}
            <div class='word-item'>{word}</div>
          {/each}
        </div>
      {/if}
    </div>
    <div>
      <PieChart values={uniqueChartValues} />
    </div>
    {/if}
</div>
<style lang='scss'>
  .sentence-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.4rem;
    margin: 1.2rem 0;
  }

  .single-word {
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
    cursor: pointer;
  }
  .non-word {
    color: var(--Warning-Dark);
    background: transparent;
    border-radius: 0.4rem;

    &:empty {
      display: none;
    }
  }
  .stat-row {
    >.label {
      display: inline-block;
      min-width: 15rem;
      padding: 0.4rem 0.8rem;
      cursor: pointer;
    }
    > .unknown {
      color: var(--Base-White);
      background: var(--Warning-Dark);
    }
  }
</style>
