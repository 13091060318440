import { derived, get, Writable, writable } from 'svelte/store'

import { getIsoFromId } from '@/helpers/apiCall'
// import {logCommonLocalError} from "@/helpers/errorsHelper";
import { setupI18n } from '@/helpers/i18n'

import type { TranslationKey } from '@/definitions/translationKey'
import { userStorredConfig } from '@/store'

type Messages = Record<TranslationKey, string>

interface Params {
  values?: {
    [key: string]: string | number
  };
}

export const currentLanguage: Writable<string> = writable('')
export const currentMessages: Writable<Messages> = writable({} as Messages)
export const englishMessages: Writable<Messages> = writable({} as Messages)

let messages: Messages
let engMessages: Messages
let isDownloading = false

const languageToImportMap = (lang: string) => {
  if(lang==='afr')return import('@/i18n/'+'afr')
  if(lang==='aln')return import ('@/i18n/aln')
  if(lang==='amh')return import ('@/i18n/amh')
  if(lang==='arb')return import ('@/i18n/arb')
  if(lang==='azj')return import ('@/i18n/azj')
  if(lang==='bel')return import ('@/i18n/bel')
  if(lang==='ben')return import ('@/i18n/ben')
  if(lang==='bho')return import ('@/i18n/bho')
  if(lang==='bul')return import ('@/i18n/bul')
  if(lang==='cat')return import ('@/i18n/cat')
  if(lang==='ceb')return import ('@/i18n/ceb')
  if(lang==='ces')return import ('@/i18n/ces')
  if(lang==='cjy')return import ('@/i18n/cjy')
  if(lang==='ckb')return import ('@/i18n/ckb')
  if(lang==='cmn')return import ('@/i18n/cmn')
  if(lang==='dan')return import ('@/i18n/dan')
  if(lang==='deu')return import ('@/i18n/deu')
  if(lang==='ekk')return import ('@/i18n/ekk')
  if(lang==='ell')return import ('@/i18n/ell')
  if(lang==='eng')return import ('@/i18n/eng')
  if(lang==='fin')return import ('@/i18n/fin')
  if(lang==='fra')return import ('@/i18n/fra')
  if(lang==='glg')return import ('@/i18n/glg')
  if(lang==='guj')return import ('@/i18n/guj')
  if(lang==='hak')return import ('@/i18n/hak')
  if(lang==='hat')return import ('@/i18n/hat')
  if(lang==='hau')return import ('@/i18n/hau')
  if(lang==='heb')return import ('@/i18n/heb')
  if(lang==='hil')return import ('@/i18n/hil')
  if(lang==='hin')return import ('@/i18n/hin')
  if(lang==='hmn')return import ('@/i18n/hmn')
  if(lang==='hrv')return import ('@/i18n/hrv')
  if(lang==='hun')return import ('@/i18n/hun')
  if(lang==='hye')return import ('@/i18n/hye')
  if(lang==='ibo')return import ('@/i18n/ibo')
  if(lang==='ilo')return import ('@/i18n/ilo')
  if(lang==='ind')return import ('@/i18n/ind')
  if(lang==='ita')return import ('@/i18n/ita')
  if(lang==='jav')return import ('@/i18n/jav')
  if(lang==='jpn')return import ('@/i18n/jpn')
  if(lang==='kan')return import ('@/i18n/kan')
  if(lang==='kat')return import ('@/i18n/kat')
  if(lang==='kaz')return import ('@/i18n/kaz')
  if(lang==='khk')return import ('@/i18n/khk')
  if(lang==='khm')return import ('@/i18n/khm')
  if(lang==='kin')return import ('@/i18n/kin')
  if(lang==='kir')return import ('@/i18n/kir')
  if(lang==='kor')return import ('@/i18n/kor')
  if(lang==='lao')return import ('@/i18n/lao')
  if(lang==='lit')return import ('@/i18n/lit')
  if(lang==='lvs')return import ('@/i18n/lvs')
  if(lang==='mal')return import ('@/i18n/mal')
  if(lang==='mar')return import ('@/i18n/mar')
  if(lang==='mkd')return import ('@/i18n/mkd')
  if(lang==='mya')return import ('@/i18n/mya')
  if(lang==='nan')return import ('@/i18n/nan')
  if(lang==='nld')return import ('@/i18n/nld')
  if(lang==='nor')return import ('@/i18n/nor')
  if(lang==='npi')return import ('@/i18n/npi')
  if(lang==='nya')return import ('@/i18n/nya')
  if(lang==='ory')return import ('@/i18n/ory')
  if(lang==='pbt')return import ('@/i18n/pbt')
  if(lang==='pcm')return import ('@/i18n/pcm')
  if(lang==='pes')return import ('@/i18n/pes')
  if(lang==='plt')return import ('@/i18n/plt')
  if(lang==='pnb')return import ('@/i18n/pnb')
  if(lang==='pol')return import ('@/i18n/pol')
  if(lang==='por')return import ('@/i18n/por')
  if(lang==='ron')return import ('@/i18n/ron')
  if(lang==='rus')return import ('@/i18n/rus')
  if(lang==='sin')return import ('@/i18n/sin')
  if(lang==='slk')return import ('@/i18n/slk')
  if(lang==='slv')return import ('@/i18n/slv')
  if(lang==='sna')return import ('@/i18n/sna')
  if(lang==='snd')return import ('@/i18n/snd')
  if(lang==='som')return import ('@/i18n/som')
  if(lang==='spa')return import ('@/i18n/spa')
  if(lang==='srp')return import ('@/i18n/srp')
  if(lang==='srp-cyr')return import('@/i18n/srp-cyr')
  if(lang==='sun')return import ('@/i18n/sun')
  if(lang==='swe')return import ('@/i18n/swe')
  if(lang==='swh')return import ('@/i18n/swh')
  if(lang==='tam')return import ('@/i18n/tam')
  if(lang==='tat')return import ('@/i18n/tat')
  if(lang==='tel')return import ('@/i18n/tel')
  if(lang==='tgk')return import ('@/i18n/tgk')
  if(lang==='tgl')return import ('@/i18n/tgl')
  if(lang==='tha')return import ('@/i18n/tha')
  if(lang==='tuk')return import ('@/i18n/tuk')
  if(lang==='tur')return import ('@/i18n/tur')
  if(lang==='uig')return import ('@/i18n/uig')
  if(lang==='ukr')return import ('@/i18n/ukr')
  if(lang==='urd')return import ('@/i18n/urd')
  if(lang==='uzn')return import ('@/i18n/uzn')
  if(lang==='vie')return import ('@/i18n/vie')
  if(lang==='war')return import ('@/i18n/war')
  if(lang==='wuu')return import ('@/i18n/wuu')
  if(lang==='xho')return import ('@/i18n/xho')
  if(lang==='yor')return import ('@/i18n/yor')
  if(lang==='yue')return import ('@/i18n/yue')
  if(lang==='zsm')return import ('@/i18n/zsm')
  if(lang==='zul')return import ('@/i18n/zul')
  return import('@/i18n/eng')
};
const loadLanguage = async (lang: string) => {
  return (await languageToImportMap(lang)).translations
}

userStorredConfig.subscribe(async (config) => {
  const current = get(currentMessages)
  if (config) {
    const iso = getIsoFromId(config.id_lang_interface, true)
    currentLanguage.set(iso)
    if (Object.keys(current).length === 0) {
      messages = await loadLanguage(iso)
      currentMessages.set(messages as Messages)
    }
  }
})

export async function initI18n (lang: string = '', initiator = ''): Promise<void> {
  try {
    if (isDownloading) return
    if (lang === '') {
      lang = await setupI18n() || 'eng'
    }
    document.body.dir = ['arb'].includes(lang) ? 'rtl' : 'ltr'
    isDownloading = true
    messages = await loadLanguage(lang)
    if (lang === 'eng') {
      engMessages = messages
    } else {
      engMessages = await loadLanguage('eng')
    }
    englishMessages.set(engMessages)
    isDownloading = false
    await setLanguage(lang)
  } catch (error) {
    console.error('Error during initialization of i18n: ', error)
  }
}

export async function setLanguage (lang: string): Promise<void> {
  currentLanguage.set(lang)
  currentMessages.set(messages)
}

export const _ = derived(
  currentMessages,
  $currentMessages => (keyString: string, params: Params = {}): string => {
    const key = keyString as TranslationKey;
    let message = $currentMessages[key]
    if(!$currentMessages[key]){
      message = key
      // logMissingTranslation(key, get(currentLanguage))
    }

    if (typeof params.values === 'object') {
      return Object.entries(params.values).reduce(
        (msg, [param, value]) => msg.replace(`{${param}}`, value.toString()),
        message
      );
    }

    return message;
  },
  (key: string, params: Params = {} as Params): string => '',
)

/*
function logMissingTranslation (key: string, lang: string) {
  if (logCommonLocalError(`CONTENT: missing translation lang: ${lang}, key: ${key}`)) {
    console.error(
      `I18N: %cmissing translation for key: %c${key} %c language %c ${lang}`,
      'color:gray',
      'color:red;font-weight:bold;',
      'color:gray',
      'color:red;font-weight:bold;'
    )
  }
}
*/
