export function resizeTextarea (node: HTMLTextAreaElement) {
  const resize = () => {
    node.style.height = 'auto'
    node.style.height = node.scrollHeight + 'px'
  }

  // Attach event listeners
  node.addEventListener('input', resize)

  // Initial resize on component mount
  resize()

  // Cleanup on component unmount
  return {
    destroy () {
      node.removeEventListener('input', resize)
    }
  }
}
