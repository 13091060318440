// Locales our app supports
import { languages } from '@/config/languages'

const locales: Record<string, string> = {}

languages.forEach((lang) => {
  locales[lang.iso] = lang.original || lang.name
})
/* solution to support different inteface: taglish, serbian cyrilyc... */
locales['srp-cyr'] = 'Српски'
/* this is solution when we want to support english (or other interface) for language where is
hard to translate original interface, only thing we need to do is to make path 3 segments long */
// locales['ceb-with-eng'] = 'Cebuano with English interface'

// Locale to show when we don't support the requested locale
const fallbackLocale = 'eng'

export { fallbackLocale, locales }
