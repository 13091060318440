<script lang='ts'>
  import { afterUpdate, onDestroy, onMount } from 'svelte'

  import { playAudio } from '@/helpers/errorsHelper'
  import { keysCheck } from '@/helpers/mixHelpers'

  import Icon from '@/components/icons/Icon.svelte'

  import { playbackRate, playingAudio } from '@/store'

  export let src = '',
    forAutoPlay = false,
    onPlay = () => { },
    variant = 'normal',
    lazy = false

  let currentRate: number
  const unsubscribe = playbackRate.subscribe(value => {
    currentRate = value
  })

  let audioSrc = ''
  let audioPlayer: HTMLAudioElement
  let disabled = false
  let isPlaying = false
  let timePercent = 0
  let currentTime = 0
  let duration = 0
  let isPaused = false
  let loading = lazy

  const getSize = (variant: string) => {
    if (variant === 'mini') return 16
    if (variant === 'middle') return 24
    if (variant === 'big') return 40
    return 24
  }
  const size = getSize(variant)
  const silenceAudio = 'https://cdn.langoid.com/silence.mp3'
  afterUpdate(async () => {
    if (src === undefined) {
      return
    }
    if (audioSrc[0] === '/') {
      audioSrc = 'https://cdn.langoid.com' + audioSrc
    }

    const sameTrack = audioSrc === src
    audioSrc = lazy ? silenceAudio : src
    disabled = audioSrc.length === 0

    if (!sameTrack) {
      audioPlayer.load()
    }
  })

  onMount(() => {
    if (audioPlayer.duration === Infinity || Number.isNaN(audioPlayer.duration)) {
      audioPlayer.currentTime = 10000000
      audioPlayer.currentTime = 0
      setTimeout(() => {
        if (audioPlayer) {
          duration = audioPlayer.duration
        }
      }, 400)
    }
    if (forAutoPlay) {
      playThisAudio(audioPlayer)
    }
  })

  $:if (audioSrc && isPlaying && !audioPlayer.paused) {
    playThisAudio(audioPlayer)
  }

  function handleClick () {
    playThisAudio(audioPlayer)
  }

  let unsubscribe2: () => void

  async function playThisAudio (audio: HTMLAudioElement) {
    unsubscribe2 = playingAudio.subscribe(current => {
      if (current && current !== audioPlayer && !current.paused) {current.pause()}
      if (current !== audioPlayer) {
        isPlaying = false
        isPaused = false
      }
    })
    audio.playbackRate = currentRate
    // Hack to prevent audio from being stuck in loading state
    if (currentTime / duration > 0.98) {
      isPlaying = false
      currentTime = 0
    }
    if (isPlaying && !isPaused) {
      audio.pause()
      isPaused = true
      isPlaying = false
      playingAudio.set(null)
    } else {
      isPaused = false
      isPlaying = true
      setTimeout(() => {
        playAudio(audio, 'circle')
      }, 50)
      playingAudio.set(audioPlayer)
    }

    audio.removeEventListener('timeupdate', initProgressBar)
    audio.addEventListener('timeupdate', initProgressBar)
  }

  function onEnded () {
    onPlay()
    isPlaying = false
    isPaused = false
    playingAudio.set(null)
  }

  function initProgressBar (track: Event) {
    const el = track.target as HTMLAudioElement
    if (el) {
      timePercent = (el.currentTime / el.duration) * 100
    }
  }

  const loadOnHover = () => {
    if (lazy && audioSrc === silenceAudio) {
      audioSrc = src
      audioPlayer.src = audioSrc
      audioPlayer.load()
      audioPlayer.addEventListener('canplaythrough', () => {
        loading = false
      }, { once: true })
    }
  }

  onDestroy(() => {
    unsubscribe()
    if (unsubscribe2) unsubscribe2()
  })
</script>
<div
  class='audio-player -{variant}'
  class:-disabled={disabled}
  class:-playing={isPlaying}
  role='button'
  tabindex='0'
  on:mouseenter={loadOnHover}
  on:keydown={loadOnHover}
>
  {#key audioSrc}
    <audio
      bind:this={audioPlayer}
      class='audio'
      src={audioSrc}
      bind:playbackRate={currentRate}
      bind:currentTime
      bind:duration
      on:ended={onEnded}
    />
  {/key}
  <div class='controls-wrapper -{variant}' class:-loading={loading}>
    <div
      class='_filledicon'
      class:-big={variant === 'big'}
      class:-middle={variant === 'middle'}
      class:-mini={variant === 'mini'}
      class:-normal={variant === 'normal'}
      role='button'
      tabindex='0'
      on:click={handleClick}
      on:keypress={(e) => keysCheck(e, handleClick)}
    >
      {#if loading}
        <Icon icon='Play' {size} weight='thin' />
      {:else if isPaused}
        <Icon icon='Play' {size} weight='fill' />
      {:else if isPlaying}
        <Icon icon='Pause' {size} weight='fill' />
      {:else}
        <Icon icon='SpeakerHigh' {size} weight='fill' />
      {/if}
    </div>
    {#if variant === 'progress' && audioPlayer}
      <div class='time'>
        {currentTime.toFixed(2)}
      </div>
      <div
        class='progress-bar'
        role='button'
        tabindex='0'
        on:click={(event) => {
        audioPlayer.currentTime = (event.offsetX / event.currentTarget.offsetWidth) * audioPlayer.duration
      }}
        on:keypress={() => {}}
      >
        <div class='progress' style:width={`${timePercent}%`} />
      </div>
      <div class='time'>
        {duration.toFixed(2)}
      </div>
    {/if}
  </div>
</div>
<style lang='scss'>
  .audio-player {
    display: flex;
    gap: 0.8rem;

    &.-disabled {
      visibility: hidden;
    }

    &.-progress {
      flex-wrap: wrap;
      min-width: 24rem;
      padding: 0.8rem;
      background-color: inherit;
      border: 0.1rem solid var(--Gray-Medium);
      border-radius: 0.8rem;
    }
  }

  .controls-wrapper {
    > :global(._filledicon) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.-progress {
      display: flex;
      gap: 1.6rem;
      align-items: center;
      width: 100%;

      > .time {
        display: inline-block;
        min-width: 3.2rem;
        margin-right: 1rem;
        line-height: 1;
        text-align: right;
      }
    }
  }

  .progress-bar {
    position: relative;
    width: 100%;
    min-width: 5rem;
    height: 0.8rem;
    background-color: var(--Gray-Medium);
    border-radius: 0.4rem;
    cursor: pointer;

    > .progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: var(--Accent-Medium);
      border-radius: 0.4rem;
    }
  }

  @media(max-width: 768px) {
    .controls-wrapper {
      &.-big {
        > .control {
          width: 8rem;
          height: 8rem;
          padding: 1.6rem;
          border-radius: 2rem;

          > :global(svg) {
            width: 4.8rem;
            height: 4.8rem;
          }
        }
      }
    }
  }
</style>
