<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { classForRating, toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'
  import { formatWordProps } from '@/helpers/wordProps'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import ExampleSentences from '@/components/lesson/ExampleSentences.svelte'
  import FormsTable from '@/components/lesson/FormsTable.svelte'
  import AddWord from '@/components/tools/AddWord.svelte'
  import CheatingWarning from '@/components/tools/CheatingWarning.svelte'
  import PropsList from '@/components/tools/PropsList.svelte'
  import Accordion from '@/components/ui/Accordion.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { toolsMyLanguage, toolsTranslateToLanguage, userStorredConfig } from '@/store'

  export let toolsPage = '',
    showTools = true,
    word = '',
    type: 'word' | 'sentence' = 'word'

  let translateToLanguage: number
  let myLanguage: number
  const unsubToolsMyLanguage = toolsMyLanguage.subscribe(lang => {
    myLanguage = lang
  })

  const unsubToolsTranslateLanguage = toolsTranslateToLanguage.subscribe(lang => {
    translateToLanguage = lang
  })

  const myNativeLanguage = $userStorredConfig.id_lang_native

  const handleLangChange = (lang: number) => {
    if (lang === myLanguage) {
      notifier.error($_('tasksPage.sameLang'))
      return
    }
    toolsTranslateToLanguage.set(toInt(lang))
  }
  const handleMyLangChange = (lang: number) => {
    if (lang === translateToLanguage) {
      notifier.error($_('tasksPage.sameLang'))
      return false
    }
    toolsMyLanguage.set(toInt(lang))
    return true
  }

  const emptyInfo = {
    exampleSentences: {},
    msg: '',
    synonyms: {},
    translations: {},
    words: []
  }
  let info: any = emptyInfo
  let wifs: any = {}
  let templates: any = {}
  let wordProps: any = {}

  async function getWordInfo (): Promise<boolean | void> {
    if (!word) return false
    if (word) {
      const data = await fetchData('main/toolsWordInfo', { interfaceId: myLanguage, languageId: translateToLanguage, word })
      info = data
      info.type = 'word-info'
      info.msg = $_('chat.noResults')
      word = ''
      wifs = data.wifs
      templates = data.templates
      wordProps = data.props || {}
    }
  }

  const swapLanguages = () => {
    const origMy = myLanguage
    const origTrans = translateToLanguage
    toolsTranslateToLanguage.set(0)
    toolsMyLanguage.set(origTrans)
    toolsTranslateToLanguage.set(toInt(origMy))
    word = ''
    info = emptyInfo
  }

  const saveWord = (e: Event, wordId: number, addedWord: string) => {
    (e.target as HTMLElement).style.display = 'none'
    notifier.info($_('tools.addedToLearningLoop'))
    fetchData('learn/lessonAddToLearningLoop', { wordId })
    word = addedWord
    getWordInfo()
  }

  // if tools are open, close them on back button
  function handleBackButton () {
    showTools = false
  }

  onMount(() => {
    window.addEventListener('popstate', handleBackButton)
    if (word && toolsPage === 'wordInfo') {
      getWordInfo()
    }
  })

  onDestroy(() => {
    window.removeEventListener('popstate', handleBackButton)
    unsubToolsMyLanguage()
    unsubToolsTranslateLanguage()
  })
</script>
<CheatingWarning />
{#if toolsPage === 'addWord'}
  <AddWord toolsWord={word} {type} />
{:else if toolsPage === 'wordInfo'}
  <div class='wrapper-word-info'>
    <form class='tool-form' action='' on:submit|preventDefault={getWordInfo}>
      <div class='form-block'>
        <div class='_fakelabel'>{$_('tools.from')}</div>
        <LanguageSelect onChange={handleLangChange} selectedLanguage={translateToLanguage} />
      </div>
      <FakeButton class='swap' onClick={swapLanguages}>
        <Icon icon='Swap' weight='thin' />
      </FakeButton>
      <div class='form-block'>
        <div class='_fakelabel'>{$_('tools.to')}</div>
        <LanguageSelect onChange={handleMyLangChange} selectedLanguage={myLanguage} />
      </div>
      <div class='form-block -input'>
        <div class='_fakelabel'>{$_('tools.wordInfoDesc')}</div>
        <input placeholder='Word...' type='text' bind:value={word} />
      </div>
      <button class='_filledicon' type='submit'>
        <Icon icon='MagnifyingGlass' weight='regular' />
        <span class='search'>{$_('mix.search')}</span>
      </button>
    </form>
    {#each info.words as w}
      {#if typeof w === 'object'}
        <Accordion show>
          <div slot='header' role='button' tabindex='0'>
            <div class='main-info'>
              <div class='wordAudio'>
                <AudioPlayer src={w.audio} />
                <p class={`singleWord ${ w.rating === null ? '' : classForRating(w.rating)}`}>
                  {formatWordProps({ content: w.word, id_pos: w.pos }, getIsoFromId(translateToLanguage), wordProps?.[w.id])}
                </p>

                <div><small>{w.level}</small>
                  <small>{$_('pos.' + w.pos)}</small></div>
              </div>
              {#if w.rating === null && toInt(myNativeLanguage) !== toInt(translateToLanguage)}
                <button
                  class='addWord'
                  type='button'
                  on:click={e => saveWord(e, w.id, w.word)}
                >
                  {$_('chat.addToLearningLoop')}
                </button>
              {/if}
            </div>
          </div>
          <div slot='content' class='word-details'>
            <div class='word-translations'>
              {#if info?.translations?.[w.id_english_word]}
                {#each info.translations[w.id_english_word] as trans}
                <span
                  class={`wordTranslation ${ trans.rating === null ? '' : classForRating(trans.rating)}`}
                >{formatWordProps({
                  content: trans.word,
                  id_pos: trans.pos
                }, getIsoFromId(myLanguage), wordProps?.[trans.id])}</span>

                  {#if trans.rating === null && toInt(myNativeLanguage) !== toInt(myLanguage)}
                    <button
                      type='button'
                      on:click={e => saveWord(e, trans.id, trans.word)}
                    >
                      {$_('chat.addToLearningLoop')}
                    </button>
                  {/if}
                {/each}
              {/if}
            </div>
            <div class='word-synonyms'>
              {#if info?.synonyms?.[w.id_english_word].length > 1}
                <span class='title'>{$_('learnPage.synonyms')}:</span>
                {#each info.synonyms[w.id_english_word] as syn}
                  {#if syn.id !== w.id}
                    <span class='-pad'>{syn.word} </span>
                  {/if}
                {/each}
              {/if}
            </div>
            <PropsList wordProps={wordProps[w.id]} />
            <ExampleSentences sentences={info.exampleSentences?.[w.id]} showTitle />
            <FormsTable templates={templates?.[w.pos]} wifs={wifs?.[w.id]} />
          </div>
        </Accordion>
      {/if}
    {:else}
      {#if info.msg}
        <div class='message'>
          {info.msg}
        </div>
      {/if}
    {/each}
  </div>
{/if}

<style lang='scss'>
  .tool-form {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    align-items: end;
    max-width: 100%;
    font: var(--Regular-400);

    > :global(.swap) {
      margin-bottom: 0.4rem;
    }

    > button {
      height: 4rem;
      margin-top: 2rem;
    }
  }

  .form-block {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    &.-input {
      max-width: 21.8rem;
    }
  }

  ._filledicon > .search {
    display: none;
  }

  .wrapper-word-info {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  .word-details {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .main-info {
    > .wordAudio {
      display: flex;
      gap: 1.6rem;
      align-items: center;

      > .singleWord {
        padding: 0.4rem 0.8rem;
        font: var(--Semibold-600);
        border-radius: 0.8rem;
        box-shadow: var(--Shadow-Z);
      }
    }

    > .addWord {
      margin-top: 1.2rem;
      background: var(--Accent-Medium);
      border: 0.2rem solid var(--Accent-Medium);

      &:hover {
        background: var(--Primary-Medium);
        border: 0.2rem solid var(--Primary-Medium);
      }
    }
  }

  .word-translations > .wordTranslation {
    display: inline-block;
    padding: 0.4rem 0.8rem;
    font-size: 1.6rem;
    border-radius: 0.8rem;
  }

  .word-translations, .word-synonyms {
    > .title {
      font-weight: bold;
      font-size: 1.2rem;
      font-variant: small-caps;
    }
  }

  @media(max-width: 768px) {
    ._filledicon {
      display: flex;
      gap: 0.8rem;
      justify-content: center;
      width: 100%;

      > .search {
        display: block;
      }
    }

    .tool-form {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 0.4rem;
      align-items: center;

      > :global(.swap) {
        margin-bottom: 0;
      }
    }

    .form-block {
      flex-direction: row;
      gap: 0.8rem;
      align-items: center;
      width: 100%;

      &.-input {
        max-width: unset;
      }

      > input {
        width: 100%;
      }
    }
  }
</style>
