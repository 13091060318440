import { MS_PER_DAY, REFERENT_YEAR } from '@/config/constants'

const ZERO_PREFIX_NUMBER = 2

export const addZero = (inputNumber: number, length: number): string => {
  let input = inputNumber.toString()
  const len = ('' + input).length
  if (len < length) {
    for (let i = 0; i < length - len; i++) input = '0' + input
  }
  return input
}

export const dateString = (rel = '', offset = 0) => {
  let d = new Date()
  if (rel === 'day') {
    const today = new Date()
    const priorDate = new Date().setDate(today.getDate() + offset)
    d = new Date(priorDate)
  }
  return d.getFullYear() + '-' + addZero(d.getMonth() + 1, ZERO_PREFIX_NUMBER) + '-' + addZero(d.getDate(), ZERO_PREFIX_NUMBER)
}

export const formatDate = (d: Date) => d.getFullYear() + '-' +
  addZero(d.getMonth() + 1, ZERO_PREFIX_NUMBER) + '-' +
  addZero(d.getDate(), ZERO_PREFIX_NUMBER)
export const formatDateTime = (d: Date) => d.getFullYear() + '-' +
  addZero(d.getMonth() + 1, ZERO_PREFIX_NUMBER) + '-' +
  addZero(d.getDate(), ZERO_PREFIX_NUMBER) + ' ' +
  addZero(d.getHours(), ZERO_PREFIX_NUMBER) + ':' +
  addZero(d.getMinutes(), ZERO_PREFIX_NUMBER) + ':' +
  addZero(d.getSeconds(), ZERO_PREFIX_NUMBER)

/**
 * Format a date string to local date string
 * Use this everywhere where we are displaying time from server
 */
export const localDateString = (date: string) => {
  const local = toLocalTime(date)
  if (!local) return ''
  return formatDateTime(local)
}

export const calculateYearsOld = (birthday: string) => {
  const birthDate = new Date(birthday).getTime()

  if (birthDate) {
    const ageDifMs = Date.now() - birthDate
    const ageDate = new Date(ageDifMs)

    return Math.abs(ageDate.getUTCFullYear() - REFERENT_YEAR)
  }

  return ''
}

export const toDate = (dateString: string): {
  day: number,
  month: string,
  year: number
} => {
  const d = new Date(dateString)
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  return {
    day: d.getDay(),
    month: monthNames[d.getMonth()],
    year: d.getFullYear()
  }
}

export function timeAgo (dateStr: string, precise = true, ago: boolean = true) {
  return dateStr?.split(' ')?.[0] || '' // todo - fix this in i18n file to support plural forms
  /*
  if (!dateStr) return
  // const date = new Date(dateStr)
  const date = toLocalTime(dateStr) // changed to handle UTC time

  if (!date || isNaN(date.getTime())) {
    return
  }

  // @ts-ignore
  const seconds = Math.floor((new Date() - date) / MS_PER_SEC)

  let interval = seconds / secondsPerYear
  if (interval > 1) {
    const time = Math.floor(interval)
    const id = ago ? 'time.yearAgo' : 'time.year'
    return get(_)(id, { values: { n: time } })
  }

  interval = seconds / secondsPerMonth
  if (interval > 1) {
    const time = Math.floor(interval)
    const id = ago ? 'time.monthAgo' : 'time.month'
    return get(_)(id, { values: { n: time } })
  }

  interval = seconds / secondsPerDay
  if (interval > 1) {
    const time = Math.floor(interval)
    const id = ago ? 'time.dayAgo' : 'time.day'
    return get(_)(id, { values: { n: time } })
  }
  if (!precise) return get(_)('today')

  interval = seconds / SEC_PER_HOUR
  if (interval > 1) {
    const time = Math.floor(interval)
    const id = ago ? 'time.hourAgo' : 'time.hour'
    return get(_)(id, { values: { n: time } })
  }

  interval = seconds / SEC_PER_MIN
  if (interval > 1) {
    const time = Math.floor(interval)
    const id = ago ? 'time.minuteAgo' : 'time.minute'
    return get(_)(id, { values: { n: time } })
  }

  const time = Math.floor(seconds)
  const id = ago ? 'time.secondAgo' : 'time.second'
  return get(_)(id, { values: { n: time } })
  */
}

/*
 From database format YYYY-MM-DD HH:MM:SS to local time, this way we can display the time in the user's timezone
 If you need string isntead of date use localDateString function
 */
export const toLocalTime = (dbTime?: string) => {
  if (typeof dbTime !== 'string') {
    return dbTime
  }
  const utcDate = dbTime.replace(' ', 'T') + 'Z'
  return new Date(utcDate)
}

export const getLocalTime = (timezone: string) => {
  const tz = !timezone || timezone.startsWith('+') || timezone.startsWith('-') ? 'UTC' : timezone

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    hour12: false,
    minute: 'numeric',
    timeZone: tz
  }

  const formatter = new Intl.DateTimeFormat([], options)
  return formatter.format(new Date())
}

export function getDatesBetween (startDate: number, endDate: number) {
  const dates: Date[] = []
  for (let ms = startDate, last = endDate; ms < last; ms += MS_PER_DAY) {
    dates.push(new Date(ms))
  }
  return dates
}
