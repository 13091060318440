<script lang='ts'>
  import { DOUBLE_DECIMAL_NUMBER } from '@/config/constants'

  export let maxProgress = 0,
    progress = 0,
    onClickOnProgress = (clicked: string) => { },
    showNumbers = false,
    showPercents = false,
    clickable = true,
    variant: 'normal' | 'showDesktop' | 'showMobile' = 'normal'

  let percent = 0
  $:if (showPercents) {
    percent = Math.min(progress / maxProgress * 100, 100)
    progress = percent
  }

  const handleOnClick = (e: MouseEvent | KeyboardEvent): void => {
    if (clickable) {
      const targetElement = e.target as HTMLElement
      const clicked = targetElement.classList[1].split('-')[1]
      onClickOnProgress(clicked)
    }
  }

  function formatNumber (num: number) {
    const formatted = num.toFixed(DOUBLE_DECIMAL_NUMBER)
    if (formatted.endsWith('.00')) {
      return parseFloat(formatted)
    }
    return parseFloat(formatted).toFixed(DOUBLE_DECIMAL_NUMBER)
  }
</script>

<div
  class='progress-container'
  class:-clickable={clickable}
  class:-showDesktop={variant === 'showDesktop'}
  class:-showMobile={variant === 'showMobile'}
>
  <div class='progress-bar-cover'>
    {#each Array(maxProgress) as _, index}
      <div
        class='progress-bar-status lesson-{index}'
        class:-filled={progress > index}
        class:-lastFilled={progress-1 === index}
        role='button'
        tabindex={clickable ? 0 : -1}
        title={(index + 1).toString()}
        on:click={handleOnClick}
        on:keypress={handleOnClick}
      />
    {/each}
  </div>
  {#if showNumbers}
    <div class='numberTestSolving' class:-contrast={maxProgress/progress < 2}>
      {formatNumber(progress)}/{maxProgress}
    </div>
  {:else if showPercents}
    <div class='numberTestSolving' class:-contrast={maxProgress/progress < 2}>
      {percent.toFixed(DOUBLE_DECIMAL_NUMBER)}%
    </div>
  {/if}
</div>

<style lang='scss'>
  .progress-container {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-around;

    &.-showMobile {
      display: none;
    }

    &.-clickable {
      cursor: pointer;
    }

    > .numberTestSolving {
      font: var(--Regular-400);
      color: var(--text-primary-color);
    }
  }

  .progress-bar-cover {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
    background-color: var(--text-background);
    border-radius: 2.4rem;
  }

  .progress-bar-status {
    width: 100%;
    height: 1.6rem;

    &.-filled {
      background-color: var(--Accent-Medium);
    }

    &.-lastFilled {
      border-radius: 0 2.4rem 2.4rem 0;
    }
  }

  @media (max-width: 768px) {
    .progress-container {
      &.-showDesktop {
        display: none;
      }

      &.-showMobile {
        display: flex;
      }

      > .numberTestSolving {
        position: absolute;
        font: var(--Regular-200);
        pointer-events: none;
        
        &.-contrast {
          color: var(--Base-White);
        }
      }
    }
  }
</style>
