<script lang='ts'>
  import { onDestroy } from 'svelte'

  import { _ } from '@/helpers/i18n'

  import LanguagePageLink from '@/components/languagePage/LanguagePageLink.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { LanguageStatus } from '@/definitions/langoid'
  import { languagesStore } from '@/store'

  export let iso = ''

  let languageStatus = $languagesStore[iso]?.status as LanguageStatus
  const unsubscribe = languagesStore.subscribe(value => {
    languageStatus = value[iso]
  })
  onDestroy(() => {unsubscribe()})
</script>
{#if languageStatus}
  <Breadcrumb>
    <h2 class='heading-mobile'>Tests</h2>
    <div slot='pageHelperIcons'>
      <PageInfo kebabItem pageName='tests' />
    </div>
  </Breadcrumb>
  <div class='action-tasks _gap24'>
    <LanguagePageLink
      icon='Lightning'
      {languageStatus}
      priv='tests'
      title={$_('levelPage.testsWords')}
      url={`/${iso}/tests/words`}
    />
    <LanguagePageLink
      icon='Lightning'
      {languageStatus}
      title={$_('levelPage.testsSentences')}
      url={`/${iso}/tests/srs`}
    />
    <LanguagePageLink
      icon='Lightning'
      {languageStatus}
      title={$_('levelPage.testsAudioSentences')}
      url={`/${iso}/tests/sentences`}
    />

    <LanguagePageLink
      icon='Lightning'
      {languageStatus}
      priv='tests'
      title={$_('levelPage.myWords')}
      url={`/${iso}/tests/customWords`}
    />
    <LanguagePageLink
      icon='Lightning'
      {languageStatus}
      title={$_('levelPage.mySentences')}
      url={`/${iso}/tests/customSentences`}
    />
  </div>
{/if}
