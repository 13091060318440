import type { LanguageFeatures } from '@/definitions/langoid'

export const getRating = (rating: number): string => {
  let c: string

  if (rating >= 7) {
    c = 'L3'
  } else if (rating >= 5) {
    c = 'L2'
  } else if (rating >= 2) {
    c = 'L1'
  } else {
    c = 'L0'
  }

  return c
}

export const increaseRating = (
  { rating, num }: { num: number; rating: number },
  counts: Record<'l0' | 'l1' | 'l2' | 'l3', number>,
) => {
  const newCounts = { ...counts }
  const r = getRating(rating)

  switch (r) {
    case 'L0':
      newCounts.l0 += num
      break
    case 'L1':
      newCounts.l1 += num
      break
    case 'L2':
      newCounts.l2 += num
      break
    case 'L3':
      newCounts.l3 += num
      break
  }
  return newCounts
}

export const emptyCounts = { l0: 0, l1: 0, l2: 0, l3: 0 }

export const defaultFeatures: LanguageFeatures = {
  chat: 0,
  learnToRead: 0,
  listenSentences: 0,
  listenWords: 0,
  testsAudioSentences: 0,
  testsSentences: 0,
  testsWords: 0,
  vocabulary: 0,
  wordForms: 0,
}
