<script lang='ts'>
  import { onMount } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { setToolsLanguages } from '@/helpers/mixHelpers'

  import FakeButton from '@/components/ui/FakeButton.svelte'

  import { chatSelectedWord } from '@/store'

  export let iso = ''
  export let selectedGroup = 0
  export let selectedType = 0
  let groups: any[] = []
  let groupTypes: any[] = []
  let words: any[] = []
  let activeWord: any = {}
  const loadWordProperties = async (iso: string, group: number, type: number) => {
    const data = await fetchData('main/loadWordProperties', { group, iso, type })
    if (groups.length === 0) { // load groups only on first load
      groups = data.groups ?? []
    }
    groupTypes = data.groupTypes ?? []
    words = data.words || []
  }

  const selectGroupAndType = (group: number, type = 0) => {
    selectedGroup = group
    selectedType = type
    loadWordProperties(iso, selectedGroup, selectedType)
  }

  const wordInfo = (word: any) => {
    setToolsLanguages(word.id_language)
    chatSelectedWord.set(word)
    activeWord = word
  }

  onMount(() => {
    loadWordProperties(iso, selectedGroup, selectedType)
  })

</script>
<h1>{$_('tools.wordProperties')}</h1>
{iso}
<div class='groups-list'>
  {#each Object.values(groups) as group}
    <FakeButton
      class={`group-tab ${group.id === selectedGroup ? "-active" : ""}`}
      onClick={() => selectGroupAndType(group.id)}
    >{$_('wp.' + group.name)} <small>{group.id_pos ? $_('pos.' + group.id_pos) : ''}</small></FakeButton>
  {:else}
    <h2>{$_('mix.notAvailablePage')}</h2>
  {/each}
</div>
<div class='groups-types-list'>
  {#each Object.values(groupTypes) as type}
    <FakeButton
      class='group-type-tab {type.id===selectedType?"-active":""}'
      onClick={() => selectGroupAndType(selectedGroup, type.id)}
    >{type.id} {type.name}</FakeButton>
  {:else}
    {#if selectedGroup}
      No subgroups
    {/if}
  {/each}

</div>
<div class='words-list'>
  <!-- todo - maybe if there is results show dropdown to select view (thumbs, list...)  -->
  {#each Object.values(words) as word}
    <FakeButton class='word {activeWord.id===word.id?"-active":""}' onClick={() => wordInfo(word)}>
      {word.word}
    </FakeButton>
  {/each}
</div>
<style lang='scss'>
  .groups-list,
  .groups-types-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
  }

  :global(.group-tab),
  :global(.group-type-tab) {
    padding: 0.4rem;
    background: var(--Primary-Ligther);
    border-radius: 0.4rem;
  }

  :global(.group-tab.-active),
  :global(.group-type-tab.-active) {
    color: var(--Base-White);
    background: var(--Primary-Medium)
  }

  :global(.group-type-tab) {
    background: var(--Accent-Lighter);
  }

  :global(.group-type-tab.-active) {
    background: var(--Accent-Medium);
  }

  .words-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;

    > :global(.word) {
      padding: 0.4rem;
      background: var(--Gray-Light);
      border-radius: 0.4rem;

      &:hover {
        background: var(--Gray-Medium);
        cursor: pointer;
      }
    }

    > :global(.word.-active) {
      background: var(--Gray-Medium);
    }
  }
</style>
