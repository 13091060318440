import { get } from 'svelte/store'

import { fallbackLocale, locales } from '@/config/l10n'

import { getIsoFromId } from '@/helpers/apiCall'
import { getUserInterfaceLanguage } from '@/helpers/detectLanguage'

import { _ } from '@/libs/i18n'
import { userStorredConfig } from '@/store'

const forumLocale = () => {
  const segments = location.pathname.split('/')
  return (segments[2] === 'forum') ? segments[1] : ''
}

async function setupI18n () {
  const logged = get(userStorredConfig)?.id
  let userLocale
  if(!logged) {
    userLocale = forumLocale() ||getUserInterfaceLanguage()
  }else {
    userLocale = supported(getIsoFromId(get(userStorredConfig).id_lang_interface, true) ||
        getUserInterfaceLanguage())
  }
  const storredVariant = get(userStorredConfig)?.interface_variant
  const variant = storredVariant ? `-${storredVariant}` : ''
  userLocale = `${userLocale}${variant}`
  return userLocale
}

function supported (locale: string) {
  return Object.keys(locales).includes(locale) ? locale : fallbackLocale
}

// We expose the svelte-i18n _ store so that our app has a single API for i18n
export { _, setupI18n }
