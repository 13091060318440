import { getIdFromIso, getIsoFromId } from '@/helpers/apiCall'
import { fetchData } from '@/helpers/fetchHelpers'
import { defaultFeatures } from '@/helpers/langHelper'

import { LanguageStatus } from '@/definitions/langoid'
import { languagesStore } from '@/store'

export async function loadFeatures (iso: string) {
  let idLangNative = 0
  const learningLanguage = getIdFromIso(iso)
  let languageStatus: LanguageStatus = {
    features: defaultFeatures,
    maxLevel: 'A0',
    status: undefined
  }

  const data = await fetchData('learn/language', { learningLanguage })
  if (data.status) {
    idLangNative = data.user.id_lang_native
    languagesStore.update((languages) => {
      languageStatus = {
        features: data.features || defaultFeatures,
        maxLevel: data.maxLevel,
        native: getIsoFromId(idLangNative) === iso,
        status: data.status
      }
      languages[iso] = languageStatus
      return languages
    })
  }
}
