<script lang='ts'>
  import { onMount } from 'svelte'

  import CloseButton from '@/components/ui/CloseButton.svelte'

  export let onClick = () => { },
    variant: 'overlay' | 'normalDesktop' | 'rightContent' | 'overflow' = 'overlay',
    absoluteCloseButton = false,
    borderCloseButton = false
  let started = false

  onMount(() => {
    if (variant === 'normalDesktop') {
      return
    }
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClick()
      }
    }

    document.addEventListener('keyup', onKeyUp)
    document.body.style.overflow = 'hidden'

    return () => {
      document.removeEventListener('keyup', onKeyUp)
      document.body.style.overflow = 'auto'
    }
  })

  // to prevent chrome bug when click start out of overlay
  const handleStart = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      started = true
    }
  }

  const handleOnClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget && started) {
      onClick()
      started = false
    }
  }
</script>

<div
  class='overlay-wrapper'
  class:-normalDesktop={variant === 'normalDesktop'}
  class:-overflow={variant === 'overflow'}
  role='button'
  tabindex='0'
  on:click={handleOnClick}
  on:mousedown={handleStart}
  on:keypress={() => {}}
>
  <div
    class='content _gap24'
    class:-normalDesktop={variant === 'normalDesktop'}
    class:-rightContent={variant === 'rightContent'}
    role='button'
    tabindex='0'
    on:click|stopPropagation
    on:keypress={() => {}}
  >
    {#if absoluteCloseButton}
      <CloseButton closeVariant='absolute' {onClick} />
    {:else if borderCloseButton}
      <div class='header-wrapper'>
        <slot name='heading' />
        <CloseButton closeVariant='desktopBorder' {onClick} />
      </div>
    {/if}
    <slot />
  </div>
</div>

<style lang='scss'>
  .overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(99, 99, 99, 0.5);

    > .content {
      position: relative;
      overflow: auto;
      min-width: max(32rem, 40%);
      max-width: min(120rem, 90%);
      min-height: 17rem;
      max-height: 90vh;

      &.-normalDesktop {
        all: unset;
      }

      &.-rightContent {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 68.4rem;
        max-width: 100vw;
        height: 100vh;
        max-height: 100vh;
        background: var(--secondary-background);
        border-radius: 0;
        box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
      }
    }

    &.-overflow > .content {
      overflow: visible;
    }

    &.-normalDesktop {
      all: unset;
    }
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    .overlay-wrapper {
      > .content {
        &.-rightContent {
          width: 100%;
          height: 85%;
          border-radius: 2.4rem 2.4rem 0 0;
        }
      }
    }
  }
</style>
