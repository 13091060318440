import { get } from 'svelte/store'

import { EXECUTION_TIME_HACK } from '@/config/constants'

import { LevelName, ToolsState } from '@/definitions/langoid'
import { toolsMyLanguage, toolsTranslateToLanguage, userStorredConfig } from '@/store'

const MAX_RAND_NUMBER = 1e6

export const classForRating = (rating: number | null) => {
  if (rating === null) return ''
  if (rating >= 7) {
    return 'L3'
  }
  if (rating >= 5) {
    return 'L2'
  }
  if (rating >= 2) {
    return 'L1'
  }

  return 'L0'
}

export const truncate = (text: string | undefined, length = 50, softLimit = 10, useWordBoundary = true) => {
  if (typeof text !== 'string') return ''
  if (text.length <= length + softLimit) {
    return text
  } // avoiding shortening if just few characters are hidden
  const subString = text.slice(0, length - 1)
  return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString) + '...'
}

// slugify for url
export const slugify = (text = '') => text.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\u0100-\uFFFF\w-]/g, '-')
  .replace(/--+/g, '-').replace(/^-+/, '').replace(/-+$/, '')

export const truncateUrl = (slug: string, length: number) => {
  return truncate(stripTags(slug), length)
}

export function clickOutside (node: Node | null, eventType = 'click') {
  const handleClick = (event: Event) => {
    if (node && !node.contains(event.target as Node) && !event.defaultPrevented) {
      node.dispatchEvent(
        new CustomEvent('click_outside', { detail: node })
      )
    }
  }

  document.addEventListener(eventType, handleClick, true)

  return {
    destroy () {
      document.removeEventListener(eventType, handleClick, true)
    }
  }
}

export const mouseUpOutside = (node: Node | null, eventType = 'mouseup') => clickOutside(node, eventType)

export const range = (size: number, startAt = 0): number[] => [...Array(size + 1).keys()].map(i => i + startAt)

/**
 * We can't use hash links so this will be workaround
 * If we want to go to hash, we will add hash to link for example id='comment-52'
 * to scroll to this comment we should use goToPseudoHash('comment') in onLoad part (inside fetch, or onMount if there is no fetch)
 * If we add goToLink option in the interface, we can use it to scroll to the comment
 */
export function goToPseudoHash (hashName = 'hash') {
  const query = window.location.search
  if (query) {
    const params = new URLSearchParams(query)
    const hash = params.get(hashName)
    if (hash) {
      setTimeout(() => {
        const elem = document.getElementById(`${hashName}-${hash}`)
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' })
        }
      }, EXECUTION_TIME_HACK)
    }
  }
}

export function stripTags (html: string) {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

// Checking if passed parameter (URL slug) is string or not
export const isSlugString = (slug: string): boolean => {
  return isNaN(parseInt(slug)) // todo - this is not a good check, if comment above is correct
}

export const EU_TIMEZONES = [
  'Europe/Vienna',
  'Europe/Brussels',
  'Europe/Sofia',
  'Europe/Zagreb',
  'Asia/Famagusta',
  'Asia/Nicosia',
  'Europe/Prague',
  'Europe/Copenhagen',
  'Europe/Tallinn',
  'Europe/Helsinki',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Busingen',
  'Europe/Athens',
  'Europe/Budapest',
  'Europe/Dublin',
  'Europe/Rome',
  'Europe/Riga',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Malta',
  'Europe/Amsterdam',
  'Europe/Warsaw',
  'Atlantic/Azores',
  'Atlantic/Madeira',
  'Europe/Lisbon',
  'Europe/Bucharest',
  'Europe/Bratislava',
  'Europe/Ljubljana',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Madrid',
  'Europe/Stockholm'
]

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const isConsentRequired = () => EU_TIMEZONES.includes(getTimezone())

export const getUniqueObjectsByKey = <T extends object> (arr: Array<T>, key: keyof T) => {
  const uniqueKeys = new Set()
  return arr.filter((obj: T) => {
    const keyVal = obj[key]
    if (!uniqueKeys.has(keyVal)) {
      uniqueKeys.add(keyVal)
      return true
    }
    return false
  })
}

export const shuffle = <T> (array: T[]) => {
  const copy = [...array]
  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = copy[i]
    copy[i] = copy[j]
    copy[j] = temp
  }
  return copy
}

export const toInt = (n: string | number | undefined): number => {
  const num = typeof n === 'number' ? n : parseInt(n || '0', 10)
  return isNaN(num) ? 0 : num
}
export const toLevel = (level: string): LevelName => (level || 'A0') as LevelName

// function to wrap onClick handlers  to execute the same action for keypress, but only if key is Enter or Space
export const keysCheck = (e: KeyboardEvent, callback: () => void) => {
  if (e.key === 'Enter') {
    callback()
    e.preventDefault()
  }
}

export async function playNotificationSound () {
  const audio = new Audio('/media/sounds/notification.wav')
  // eslint-disable-next-line no-console
  console.log('Playing notification')
  return await audio.play()
}

export const isInputElement = (target: HTMLElement | null) => {
  if (target === null) return false
  if (target.tagName === 'TEXTAREA') return true
  if (target.tagName === 'INPUT') {
    const input = target as HTMLInputElement
    if (['text', 'email', 'password', 'number'].includes(input.type)) {
      return true
    }
  }
  return false
}

export const getHomeLink = (menu: string, learn: string) => {
  const language = menu || learn
  if (language) {
    return `/${language}/home/${Math.round(Math.random() * MAX_RAND_NUMBER)}`
  }
  return '/'
}

export const disableInputs = (parent: HTMLElement, selector: string) => {
  const els2 = Array.from(parent.querySelectorAll(selector))
  if (els2) {
    els2.forEach((inputElement) => {
      const item = inputElement as HTMLInputElement
      item.disabled = true
      item.blur()
    })
  }
}

export const disableElement = (parent: HTMLElement, selector: string) => {
  const submitButton: HTMLInputElement | null = parent.querySelector(selector)
  if (submitButton) {
    submitButton.disabled = true
    submitButton.blur()
  }
}

export const poses = [
  { id: 1, pos: 'noun' },
  { id: 2, pos: 'verb' },
  { id: 3, pos: 'adjective' },
  { id: 4, pos: 'pronoun' },
  { id: 5, pos: 'adverb' },
  { id: 6, pos: 'number' },
  { id: 7, pos: 'preposition' },
  { id: 8, pos: 'conjunction' },
  { id: 9, pos: 'particle' },
  { id: 10, pos: 'interjection' },
  { id: 11, pos: 'article' },
  { id: 12, pos: 'unknown' }
]

export const omit = <T extends object> (obj: T, keysToOmit: Array<keyof T>): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keysToOmit.includes(key as keyof T))
  ) as Partial<T>

export const pick = <T extends object> (obj: T, keysToPick: Array<keyof T>): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => keysToPick.includes(key as keyof T))
  ) as Partial<T>

export function toolsState (state: ToolsState, type: string, tabType: 'word' | 'sentence') {
  return {
    ...state,
    addWordsType: type === 'addWord' ? tabType : 'word',
    showNotes: type === 'addNotes',
    showTools: type !== 'addNotes',
    toolsPage: type,
  }
}

export const setToolsLanguages = (languageId: number) => {
  const my = get(userStorredConfig).id_lang_interface
  toolsMyLanguage.set(my)
  toolsTranslateToLanguage.set(toInt(languageId))
}

export const formatScriptLine = (content: string, html=true): string => {
  if(!content) return ''
  if(content.startsWith('#')) {
    return html? `<h4>${content.slice(1).trim()}</h4>` : content.slice(1).trim()
  }
  if(content.startsWith('*')) {
    return html? `<strong>${content.slice(1).trim()}</strong>` : content.slice(1).trim()
  }
  return content
}
