export const isoToLayout = (iso: string) => {
  /* eslint-disable sort-keys */
  const layouts: Record<string, string> = {
    bos: 'serbian',
    ceb: 'english',
    deu: 'deutchland',
    eng: 'english',
    ita: 'italian',
    jap: 'japanese',
    rus: 'russian',
    spa: 'spanish',
    srp: 'serbian',
    sqi: 'albanian'
  }
  return layouts[iso] || 'eng'
}
