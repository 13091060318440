<script lang='ts'>
  // todo: add placeholder setting option
  // todo: add label setting option
  // todo: add option for loading list dynamically from api
  // todo: add option for excluding specific languages (maybe we have it with allowed)
  import { onDestroy, onMount } from 'svelte'

  import { languages as availableLanguages } from '@/config/languages'

  import { clickOutside, toInt } from '@/helpers/mixHelpers'

  import Flag from '@/components/forum/Flag.svelte'
  import Icon from '@/components/icons/Icon.svelte'

  import { LanguagesList } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  export let selectedLanguage: number
  export let onChange: (id: number) => boolean | number = () => true // return false in parent component if you want to prevent change
  export let type: 'all' | 'my' = 'my' // by default show only user languages
  export let variant = 'default' // css variant
  export let allowed: number[] = []
  export let placeholder = ''
  export let label = ''

  const defaultPlaceholder = 'Select language'
  let unsubscribe: () => void

  let languages: LanguagesList[] = []
  if (allowed.length) {
    languages = availableLanguages.filter(el => allowed.includes(el.id))
  } else if (type === 'all') {
    languages = availableLanguages
  } else if (type === 'my') {
    unsubscribe = userStorredConfig.subscribe(config => {
      const myLanguages = config.languages || {}
      languages = availableLanguages.filter(el => myLanguages[el.id])
    })
  }

  let showDropdown = false
  let focusedIndex = -1
  let dropdownWrapper: HTMLDivElement
  let dropdownMenu: HTMLDivElement
  const randomString = Math.random().toString(36).substring(7)

  const selectLanguage = (lang?: LanguagesList) => {
    showDropdown = false
    if (lang) {
      if (onChange(lang.id)) {
        selectedLanguage = lang.id
      }
    } else {
      onChange(0)
      selectedLanguage = 0
    }
  }
  let typingSearch = ''
  let typingTimeout: any
  const handleKeyDown = (event: KeyboardEvent) => {
    if (!languages.length) return
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault()

      if (!showDropdown) {
        showDropdown = true
        // find index of selectedLanguage
        focusedIndex = languages.indexOf(languages.find(lang => toInt(lang.id) === toInt(selectedLanguage)) || languages[0])
      } else {
        if (event.key === 'ArrowUp') {
          focusedIndex = focusedIndex > 0 ? focusedIndex - 1 : languages.length - 1
        } else if (event.key === 'ArrowDown') {
          focusedIndex = focusedIndex < languages.length - 1 ? focusedIndex + 1 : 0
        }
      }
    } else if (event.key === 'Enter') {
      event.preventDefault()
      selectLanguage(languages[focusedIndex])
    } else if (/^[a-zA-Z]$/g.test(event.key)) {
      event.preventDefault()

      // Append the letter to the search string
      typingSearch += event.key

      // Clear any existing timeout
      clearTimeout(typingTimeout)

      const typingDelay = 500
      // Reset timeout
      typingTimeout = setTimeout(() => {
        // Clear the search string after delay of no typing
        typingSearch = ''
      }, typingDelay)

      const index = languages.findIndex(lang => lang.name.toLowerCase().startsWith(typingSearch))

      // Only change the index if a language is found
      if (index >= 0) {
        focusedIndex = index
        if (!showDropdown) {
          selectLanguage(languages[focusedIndex])
        }
      }
    } else if (event.key === 'Escape') {
      event.preventDefault()
      showDropdown = false
    }

    if (focusedIndex && dropdownMenu) {
      dropdownMenu.children[focusedIndex].scrollIntoView({ block: 'nearest', inline: 'nearest' })
    }
  }
  onMount(() => {
    dropdownWrapper.addEventListener('keydown', handleKeyDown)
  })

  onDestroy(() => {
    dropdownWrapper.removeEventListener('keydown', handleKeyDown)
    if (unsubscribe) unsubscribe()
  })
</script>
<div
  bind:this={dropdownWrapper}
  id={label || randomString}
  class='dropdown-wrapper -{variant}'
  role='button'
  tabindex={-1}
  on:click_outside={() => {showDropdown = false}}
  use:clickOutside
>
  <button
    class='opener _tertiary-btn _row'
    class:-open={showDropdown}
    type='button'
    on:click={() => {showDropdown = !showDropdown}}
  >
    {#key selectedLanguage}
      {#if selectedLanguage}
        {@const selectedObj = languages.find(lang => toInt(lang.id) === toInt(selectedLanguage)) }
        <Flag tabindex={-1} bind:id={selectedLanguage} />
        <span class='opener-label -{variant}'>{selectedObj?.name}</span>
        {#if $userStorredConfig.id_lang_native === selectedLanguage}
          <span class='native'><Icon icon='HouseLine' size={16} /></span>
        {/if}
      {:else}
        <span class='opener-label -{variant}'>{placeholder || defaultPlaceholder}</span>
      {/if}
    {/key}
  </button>

  {#if showDropdown}
    <div bind:this={dropdownMenu} class='dropdown-menu'>
      {#if placeholder}
        <div
          class='placeholder'
          role='button'
          tabindex='0'
          on:keypress={() => selectLanguage(undefined)}
          on:click={() => selectLanguage(undefined)}
        >{placeholder}</div>
      {/if}
      {#each languages as lang, index (lang.id)}
        {#key lang.id}
          <div
            class:-active={toInt(selectedLanguage) === lang.id}
            class:focused={index === focusedIndex}
            role='button'
            tabindex='0'
            on:click={() => selectLanguage(lang)}
            on:keypress={() => selectLanguage(lang)}
          >
            <Flag clickOnFlag={() => selectLanguage(lang)} iso={lang.iso} tabindex={-1} />
            <span>{lang.name}</span>
            {#if $userStorredConfig.id_lang_native === lang.id}
              <Icon icon='HouseLine' size={16} />
            {/if}
          </div>
        {/key}
      {/each}
    </div>
  {/if}
</div>

<style lang='scss'>
  .dropdown-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    > .opener {
      min-width: 16rem;
      font: var(--Regular-400);
      color: var(--text-primary-color);
      background-color: var(--form-background);
      border: 0.1rem solid var(--form-border);

      > .native {
        height: 1.6rem;
      }
    }

    &.-default > .opener {
      position: relative;
      z-index: 10;
      width: 100%;
      padding-right: 2.4rem;
      background: var(--form-background) no-repeat url(/media/naman/caret-down.svg) 95% center;
      border: 0.1rem solid var(--form-border);
      border-radius: 0.8rem;

      &.-open {
        background: var(--form-background) no-repeat url(/media/naman/caret-up.svg) 95% center;
        background-size: 1.6rem 1.6rem;
        border: 0.1rem solid var(--form-focus);
        box-shadow: 0px 0px 0px 3px var(--form-shadow);
      }
    }

    &.-menu > .opener {
      padding: 0.8rem 3.2rem 0.8rem 0.8rem;
      background: var(--form-background) no-repeat url(/media/naman/caret-down.svg) 90% center;
      background-size: 1.6rem 1.6rem;
      border: none;
      border-radius: 2.4rem;

      &.-open {
        background: var(--form-background) no-repeat url(/media/naman/caret-up.svg) 90% center;
        background-size: 1.6rem 1.6rem;
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 4.2rem;
    z-index: 30;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: inherit;
    min-width: 16rem;
    max-height: 50vh;
    background: var(--main-background);
    border-radius: 0.8rem;
    box-shadow: var(--medium-box-shadow);

    > div {
      position: relative;
      display: flex;
      gap: 0.8rem;
      align-items: center;
      min-width: 16rem;
      padding: 0.8rem;
      color: var(--text-primary-color);
      background: var(--form-background);
      cursor: pointer;

      &.-active {
        color: var(--Base-White);
        background-color: var(--Accent-Medium);
      }

      &.focused,
      &:hover {
        color: var(--Base-Black);
        background-color: var(--Accent-Lighter);
      }
    }
  }

  @media (max-width: 768px) {
    .dropdown-wrapper.-menu {
      > .opener {
        width: auto;
        min-width: auto;
        padding: 0.8rem 2.4rem 0.8rem 0.8rem;
        background-position-x: 3.4rem;

        &.-open {
          background-position-x: 3.4rem;
        }

        > .native {
          display: none;
        }
      }
    }
    .opener-label.-menu {
      display: none;
    }
  }
</style>
