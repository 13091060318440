export const removePostSyntax = (str: string): string => {
  // Ja sam ##1 Ana ##. Da## li si iz ##1 Engleske ##?
  if (!str) return ''
  return str.replace(/##\d\w*\s+([^#]+)\s+##/g, '$1')
    .replace(/#@/g, '')
    .replace(/###/g, '')
    .replace(/##/g, '')
    .replace(/%%%/g, '')
    .replace(/%%/g, '')
}
