<script lang='ts'>
  import { _ } from '@/libs/i18n'

  export let wordProps: any[] = []
</script>
<div class='word-props'>
  {#if wordProps}
    {#each wordProps || [] as prop}
      <div class='prop'>
        {$_('wp.' + prop.group_name)}
        {#if prop.type_name !== 'yes'}
          <strong>{$_('wp.' + prop.type_name)}</strong>
        {/if}
      </div>
    {/each}
  {/if}
</div>
<style lang='scss'>
  .word-props {
    padding: 2.4rem 0;

    &:empty {
      display: none;
    }
  }
</style>
