import { TESTS } from '@/config/constants'

import { getMenuGroups, getTitle } from '@/helpers/metaTags'
import { disableElement, disableInputs } from '@/helpers/mixHelpers'

import { Bread } from '@/definitions/langoid'

export const isSentenceQuestion = (type: number) => [TESTS.TRANSLATE_SENTENCE, TESTS.AUDIO_SENTENCE].includes(type)
export const isCustomTestType = (type: number) => [TESTS.CUSTOM_SENTENCE, TESTS.CUSTOM_WORD].includes(type)
export const isWordQuestion = (type: number) => [TESTS.TRANSLATE_WRITTEN, TESTS.TRANSLATE_AUDIO, TESTS.RADIO].includes(type)
export const isAudioQuestion = (type: number) => [TESTS.AUDIO_SENTENCE, TESTS.TRANSLATE_AUDIO].includes(type)

export const getBreads = (path: string, { iso, $_ }: { $_: Function, iso: string }): Bread[] => {
  const tmp = getMenuGroups(path)
  const title = getTitle(tmp.menuGroups, tmp.locationGroup)
  return [
    { mobileUrl: `/${iso}/tests`, text: '', url: `/${iso}` },
    { text: $_(title) }
  ]
}

export function disableForm (form: HTMLElement | null) {
  if (!form) return
  disableInputs(form, 'input[type="text"]')
  disableInputs(form, 'input[type="radio"]')
  disableElement(form, 'input[type="submit"]')
}

export function initFocus (el: HTMLInputElement) {
  el.focus()
}
