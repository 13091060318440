<script lang='ts'>
  import { onMount } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { classForRating, pick, toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import Flag from '@/components/forum/Flag.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import Pagination from '@/components/ui/Pagination.svelte'

  import { chatSelectedWord, toolsMenuStore, toolsMyLanguage, toolsTranslateToLanguage, userStorredConfig } from '@/store'

  interface Word {
    burried: 'no' | 'day' | 'week' | 'month' | 'forever';
    frequency: number;
    id: number;
    level: string;
    originalBurried?: string | null;
    originalRating?: number | null;
    pos: string;
    rating: number | null;
    selected?: boolean;
    status: 'new' | 'perfect' | 'know' | 'delay' | 'dontKnow' | '';
    translation: string;
    translationId: number;
    word: string;
  }

  let defaultBurriedPeriod: Word['burried'] = 'month'
  let perPage = 200
  let sum = 0
  let bulkAction: Word['status'] | '' = ''
  let words: Word[] = []
  let currentPage = 1

  let selectedWords = new Set<number>()
  let language = $userStorredConfig.id_lang_interface
  let level = ''
  let filterType = ''
  let lastSelectedIndex: number | null = null
  let activeRowIndex: number | null = null

  $: selectedWordsSize = selectedWords.size

  const changes: {
    burried?: string;
    id?: number;
    originalBurried?: string | null;
    originalRating?: number | null;
    rating?: number | null;
  }[] = []
  const changeDelay = 1500

  function updateStatus (id: number, newStatus: Word['status']) {
    const index = words.findIndex(w => w.id === id)
    if (index !== -1) {
      const originalRating = words[index].originalRating || words[index].rating
      if (newStatus === 'dontKnow' && (originalRating || originalRating === 0)) {
        return
      }
      if (newStatus === 'know' && originalRating && originalRating > 5) {
        return
      }
      words[index] = setRating(newStatus, words[index])
      changes.push(pick(words[index], ['burried', 'id', 'rating', 'originalBurried', 'originalRating']))
      debounceSendChanges()
    }
  }

  let debounceTimeout: any

  function debounceSendChanges () {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }
    debounceTimeout = setTimeout(sendChanges, changeDelay)
  }

  async function sendChanges () {
    if (changes.length === 0) return

    try {
      const response = await fetchData('main/updateWordStatuses', { changes })
      if (response.error) {
        notifier.error(response.error)
      } else {
        changes.length = 0 // Clear the changes array
      }
    } catch (error) {
      notifier.error('Error while updating the statuses')
    }
  }

  function toggleSelection (id: number, index: number, event: MouseEvent) {
    if (event.shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, index)
      const end = Math.max(lastSelectedIndex, index)

      selectedWords.clear()
      for (let i = start; i <= end; i++) {
        selectedWords.add(words[i].id)
        words[i].selected = true
      }
    } else {
      if (selectedWords.has(id)) {
        selectedWords.delete(id)
        words[index].selected = false
      } else {
        selectedWords.add(id)
        words[index].selected = true
      }
    }

    lastSelectedIndex = index
    selectedWords = new Set(selectedWords)
    bulkAction = ''
    setActiveRow(index)
  }

  function bulkUpdateStatus () {
    selectedWords.forEach((id) => {
      const index = words.findIndex(w => w.id === id)
      if (index !== -1) {
        words[index] = setRating(bulkAction, words[index])
        changes.push(pick(words[index], ['burried', 'id', 'rating', 'originalBurried', 'originalRating']))
      }
    })
    debounceSendChanges()

    selectedWords.clear()
    words.forEach(word => {
      word.selected = false
    })
  }

  async function loadBulkWords (e: CustomEvent | null = null) {
    if (e) {
      const { detail: page } = e
      currentPage = page
    } else {
      currentPage = 1
    }

    const data = await fetchData('main/loadBulkWords', {
      filterType,
      language,
      level,
      page: currentPage || 1,
      status: bulkAction
    })
    if (data.error) {
      notifier.error(data.error)
    } else {
      words = data.words
      selectedWords = new Set([])
      lastSelectedIndex = null
      sum = data.sum
      perPage = data.perPage
    }
    window.scrollTo(0, 0)
  }

  const langChange = (lang: number) => {
    language = lang
    loadBulkWords()
    return true
  }
  onMount(() => {
    loadBulkWords()
  })

  const getStatus = (rating: number | null, burried: string = 'no') => {
    if (burried !== 'no' && burried !== null) {return 'delay'}
    if (rating === null) return 'new'
    if (rating === 999) return 'perfect'
    if (rating > 0) return 'know'
    return 'dontKnow'
  }

  const setRating = (status: Word['status'], word: Word) => {
    word.originalRating = word.originalRating || word.rating
    word.originalBurried = word.originalBurried || word.burried
    if (status === 'delay') word.burried = defaultBurriedPeriod
    else {
      word.burried = 'no'
    }
    if (status === 'perfect') word.rating = 999
    if (status === 'know') word.rating = 6
    if (status === 'dontKnow') word.rating = 0
    return word
  }

  const selectWord = (word: Word, type = 'original') => {
    if (!word.word) {
      return
    }

    const chatWord = type === 'original' ?
      {
        content: word.word,
        id: word.id,
        word: word.word
      } :
      {
        content: word.translation,
        id: word.translationId,
        word: word.translation
      }
    toolsMenuStore.update(state => {
      state.toolsPage = 'wordInfo'
      state.addWordsType = 'word'
      state.showTools = true
      return state
    })

    const lang2 = type === 'translation' ? language : $userStorredConfig.id_lang_learning
    const lang1 = type === 'translation' ? $userStorredConfig.id_lang_learning : language

    toolsMyLanguage.set(lang1)
    toolsTranslateToLanguage.set(toInt(lang2))
    chatSelectedWord.set(chatWord)
  }

  function setActiveRow (index: number | null) {
    if (index !== null && (index < 0 || index >= words.length)) {
      return
    }

    activeRowIndex = index
  }

  function handleKeydown (event: KeyboardEvent) {
    if (activeRowIndex === null) return

    if (event.key >= '1' && event.key <= '4') {
      const statuses = ['perfect', 'know', 'dontKnow', 'delay']
      updateStatus(words[activeRowIndex].id, statuses[parseInt(event.key) - 1] as Word['status'])
    } else if (event.key === 'ArrowUp') {
      setActiveRow((activeRowIndex - 1 + words.length) % words.length)
    } else if (event.key === 'ArrowDown') {
      setActiveRow((activeRowIndex + 1) % words.length)
    } else if (event.key === 'ArrowLeft') {
      if (currentPage > 1) {
        currentPage--
        loadBulkWords({ detail: currentPage } as CustomEvent)
      }
    } else if (event.key === 'ArrowRight') {
      if (currentPage < Math.ceil(sum / perPage)) {
        currentPage++
        loadBulkWords({ detail: currentPage } as CustomEvent)
      }
    }
  }

  onMount(() => {
    window.addEventListener('keydown', handleKeydown)
    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  })
</script>
<h2>{$_('bulkWord.addWord')}</h2>
<details>
  <summary>
    <Icon icon='Info' weight='fill' />
    {$_('bulkWord.showHelp')}
  </summary>
  <table>
    <tr>
      <td>
        <Icon icon='GraduationCap' weight='fill' />
      </td>
      <td>{$_('bulkWord.knowThis')}</td>
    </tr>
    <tr>
      <td>
        <Icon icon='CheckCircle' weight='fill' />
      </td>
      <td>{$_('bulkWord.thinkKnow')}</td>
    </tr>
    <tr>
      <td>
        <Icon icon='XCircle' weight='fill' />
      </td>
      <td>{$_('bulkWord.notSure')}</td>
    </tr>
    <tr>
      <td>
        <Icon icon='Clock' weight='fill' />
      </td>
      <td>{$_('bulkWord.dontLearn')}</td>
    </tr>
  </table>
  <div>
    <p>{$_('bulkWord.info')} <kbd>{$_('bulkWord.shift')}</kbd> + <kbd>{$_('bulkWord.click')}</kbd> {$_('bulkWord.info1')}</p>
    <p>{$_('bulkWord.info2')} <kbd>1</kbd> {$_('bulkWord.info3')} <kbd>4</kbd> {$_('bulkWord.info4')}</p>
    <p>{$_('bulkWord.info')} <kbd>↑</kbd> {$_('bulkWord.info5')} <kbd>↓</kbd> {$_('bulkWord.info6')}</p>
    <p>{$_('bulkWord.info')} <kbd>←</kbd> {$_('bulkWord.info5')} <kbd>→</kbd> {$_('bulkWord.info7')}</p>
  </div>
  <p><strong>{$_('notes.note')}:</strong> {$_('bulkWord.cantChangeRaiting')}</p>
</details>

<div class='setup-page'>
  <LanguageSelect
    onChange={langChange}
    selectedLanguage={language}
  />
  <select bind:value={level} on:change={loadBulkWords}>
    <option value=''>{$_('mix.chooseLevel')}</option>
    <option value='A1'>A1</option>
    <option value='A2'>A2</option>
    <option value='B1'>B1</option>
    <option value='B2'>B2</option>
    <option value='C1'>C1</option>
    <option value='C2'>C2</option>
  </select>
  <select bind:value={filterType} on:change={loadBulkWords}>
    <option value=''>{$_('bulkWord.showAll')}</option>
    <option value='new'>{$_('bulkWord.unchecked')}</option>
    <option value='perfect'>{$_('mix.perfect')}</option>
    <option value='know'>{$_('mix.know')}</option>
    <option value='dontKnow'>{$_('mix.dontKnow')}</option>
    <option value='delay'>{$_('bulkWord.delay')}</option>
  </select>
  <select bind:value={defaultBurriedPeriod}>
    <option value='month'>{$_('bulkWord.hide')}</option>
    <option value='month'>{$_('period.month')}</option>
    <option value='week'>{$_('period.week')}</option>
    <option value='day'>{$_('period.day')}</option>
    <option value='forever'>{$_('plans.forever')}</option>
  </select>
</div>
{#if selectedWordsSize > 0}
  <div>
    <label for='bulk-actions'>{$_('bulkWord.bulkActions')} </label>
    <select
      id='bulk-actions'
      bind:value={bulkAction}
      on:change={bulkUpdateStatus}
    >
      <option disabled value=''>{$_('bulkWord.chooseAction')}</option>
      <option value='perfect'>{$_('bulkWord.perfect')}</option>
      <option value='know'>{$_('bulkWord.asKnown')}</option>
      <option value='dontKnow'>{$_('bulkWord.dontKnow')}</option>
      <option value='delay'>{$_('bulkWord.markAsDelay')}</option>
    </select>
  </div>
{/if}
<table>
  <thead>
  <tr>
    <th />
    <th>
      <Icon icon='GraduationCap' weight='fill' />
    </th>
    <th>
      <Icon icon='CheckCircle' weight='fill' />
    </th>
    <th>
      <Icon icon='XCircle' weight='fill' />
    </th>
    <th>
      <Icon icon='Clock' weight='fill' />
    </th>
    <th>
      {#key language}
        <Flag id={language} noClick />
      {/key}
    </th>
    <th>
      {#key $userStorredConfig.id_lang_learning}
        <Flag id={$userStorredConfig.id_lang_learning} noClick />
      {/key}
    </th>
    <th>{$_('mix.rating')}</th>
    <th>{$_('tools.pos')}</th>
  </tr>
  </thead>
  <tbody>
  {#each words as word, index}
    {@const status = getStatus(word.rating, word.burried)}
    {@const originalRating = word.rating}
    <tr
      class='word-row'
      class:active-row={index === activeRowIndex}
      class:selectedRow={word.selected}
    >
      <td class='firstCell' class:selected={word.selected}>
        <label class='checkbox-label'>
          <input type='checkbox' bind:checked={word.selected} on:click='{(e) => toggleSelection(word.id, index, e)}' />
        </label>
      </td>
      <td
        class='rating-cell'
        on:click={() => updateStatus(word.id, 'perfect')}
        style:color={status === 'perfect' ? 'var(--Gray-Dark)' : 'var(--Gray-Light)'}
      >
        <Icon icon='GraduationCap' weight={status === 'perfect' ? 'fill' : 'regular'} />
      </td>
      <td
        class='rating-cell'
        class:-disabled={originalRating && originalRating > 5}
        on:click={() => updateStatus(word.id, 'know')}
        style:color={status === 'know' ? 'var(--Gray-Dark)' : 'var(--Gray-Light)'}
      >
        <Icon icon='CheckCircle' weight={status === 'know' ? 'fill' : 'regular'} />
      </td>
      <td
        class='rating-cell'
        class:-disabled={originalRating && originalRating !== 0}
        on:click={() => updateStatus(word.id, 'dontKnow')}
        style:color={status === 'dontKnow' ? 'var(--Gray-Dark)' : 'var(--Gray-Light)'}
      >
        <Icon icon='XCircle' weight={status === 'dontKnow' ? 'fill' : 'regular'} />
      </td>
      <td
        class='rating-cell'
        on:click={() => updateStatus(word.id, 'delay')}
        style:color={status === 'delay' ? 'var(--Gray-Dark)' : 'var(--Gray-Light)'}
      >
        <Icon icon='Clock' weight={status === 'delay' ? 'fill' : 'regular'} />
      </td>
      <td on:click={() => selectWord(word, 'translation')}>{word.translation}</td>
      <td on:click={() => selectWord(word, 'original')}>{word.word}</td>
      <td
        class={classForRating(word.rating)}
        on:click={() => setActiveRow(index)}
      >{word.rating}</td>
      <td>{$_('pos.' + word.pos)}</td>
    </tr>
  {/each}
  </tbody>
</table>
{#key `${filterType}-${level}-${language}`}
  <Pagination count={Math.ceil(sum / perPage)} current={currentPage} on:change={(e) => loadBulkWords(e)} />
{/key}
<style lang='scss'>
  .setup-page {
    display: flex;
    gap: 1.6rem;
  }

  .active-row > td {
    background: var(--Gray-Light);
  }

  td, th {
    padding: 0.4rem 0.4rem;
    font-size: 1.6rem;
    text-align: center;
    vertical-align: middle;
    border-right: 0;
    border-left: 0;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 2.4rem;
  }

  .rating-cell {
    cursor: pointer;

    &.-disabled {
      color: var(--Gray-Light);
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  summary {
    display: inline-block;
    cursor: pointer;
  }

  details {
    line-height: 2;

    kbd {
      padding: 0.2rem 0.4rem;
      line-height: 1;
    }
  }
</style>
