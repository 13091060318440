import { get } from 'svelte/store'

import { MS_PER_DAY } from '@/config/constants'

import { API_PUBLIC_URL } from './apiCall'

import { DataError, FetchCatchError } from '@/definitions/langoid'
import { userStorredConfig } from '@/store'

type ErrorMessage = string | Error | null

const MAX_NUMBER_OF_ERRORS = 1000
const DAYS_FOR_SAVING_ERRORS = 7
const ERROR_DAYS_LIFETIME = 90
const shouldBeLogged = (message: ErrorMessage) => {
  if (!message || typeof message === 'object') return true

  // most of these errors doesn't have anything with our code
  return !(
    message.includes('AudioPlayError: NotSupportedError') ||
    message.includes('chrome://') ||
    message === 'Script error.' ||
    message.includes('ResizeObserver loop completed')
  )
}

/**
 * With this we should solve problem of errors that could happen locally that are common,
 * and it will be too much to log all of them, but also it will be bad to ignore them.
 * Examples of these types of errors are:
 *    - Listen words & sentences page - If audio not exists it's not logged
 *    - Missing translations - if there is some missing translation in the interface
 *
 * Logic is:
 * Once we log error to console, we check in localStorage if we logged that error before, if not we save it to localStorage,
 * while saving it to localStorage send to the server what error happened.
 * This way we will have just one error per one user.
 * We delete errors older than 3 monts, and if user has more than 1000 logged errors,
 * we write that in new key in localStorage and disable remote logging for that user.
 *
 * We return true if error is logged, and false if it's not.
 */
export function logCommonLocalError (message: string) {
  // keys we are using, to rename them if needed
  const storageKeys = {
    disabled: 'localErrorsDisabled',
    errors: 'localErrors',
    lastTimeDeleted: 'lastTimeDeleted'
  }
  const isDisabled = localStorage.getItem(storageKeys.disabled)
  if (isDisabled) {
    return false
  }
  const previousErrors = JSON.parse(localStorage.getItem(storageKeys.errors) || '{}') || {}
  if (Object.keys(previousErrors).length > MAX_NUMBER_OF_ERRORS) {
    localStorage.setItem(storageKeys.disabled, 'true')
    return false
  }
  if (!previousErrors[message]) {
    previousErrors[message] = Date.now()
    localStorage.setItem(storageKeys.errors, JSON.stringify(previousErrors))
    logOnServer(message)
    return true
  }
  // add logic to delete old errors once in 7 days, also use localStorage to save last time we deleted old errors
  const lastTimeDeleted = localStorage.getItem(storageKeys.lastTimeDeleted)
  if (!lastTimeDeleted || (Date.now() - parseInt(lastTimeDeleted, 10)) > DAYS_FOR_SAVING_ERRORS * MS_PER_DAY) {
    localStorage.setItem(storageKeys.lastTimeDeleted, Date.now().toString())
    const errorsToDelete = Object.keys(previousErrors).filter((key) => {
      const time = previousErrors[key]
      return (Date.now() - time) > ERROR_DAYS_LIFETIME * MS_PER_DAY
    })
    errorsToDelete.forEach((key) => {
      delete previousErrors[key]
    })
    localStorage.setItem(storageKeys.errors, JSON.stringify(previousErrors))
    if (errorsToDelete.length) {
      localStorage.removeItem(storageKeys.disabled)
    }
  }
  return false
}

export function logOnServer (error: FetchCatchError, url = '???', line = 0) {
  let message: string
  if (typeof error === 'string') {
    message = error
  } else {
    if (error && typeof error === 'object') {
      line = error.lineno || line
      url = error.filename || url
      message = error?.message || JSON.stringify(error)
    } else {
      message = error?.message || JSON.stringify(error)
    }
  }
  if (shouldBeLogged(message)) {
    // eslint-disable-next-line no-console
    console.log('LOG_ON_SERVER', message, url, line)
    const ls = get(userStorredConfig)
    let user = 0
    if (ls) {
      user = parseInt(ls.id, 10)
    }
    if (!(['string', 'undefined'].includes(typeof message))) message = JSON.stringify(message)
    const data = {
      details: {
        href: location.href,
        userAgent: navigator.userAgent
      },
      line,
      loc: (url || location.href).replace(location.origin, ''),
      message,
      user
    }
    fetch(`${API_PUBLIC_URL}/v1/direct/jsError`, {
      body: JSON.stringify(data),
      method: 'POST'
    }).then(() => {

    }).catch((error) => {
      console.error(error)
    })
  }
}

// Audio play with error checking
export const playAudio = (audio: any, loc = '') => {
  const promise = audio.play()
  if (typeof promise?.then === 'function') {
    promise.then((_: any) => {
      // Autoplay started!
    }).catch((error: Error) => {
      const errorContent = error.toString()
      if (loc === 'circle' && errorContent.includes('The fetching process for the media')) {
        // ignore
      } else {
        // We removed this from error log because we are getting to many errors from this
        console.error('AudioPlayError: ' + errorContent)
      }
    })
  }
}

export function handleRejections (err: DataError) {
  let filename: string = location.href
  let lineNumber: number = -1
  let reason: any = err.reason
  if (typeof err.reason === 'object') {
    reason = err.reason.message
    filename = err.reason.filename
    lineNumber = err.reason.lineNumber
  }
  logOnServer(reason, filename, lineNumber)
}
