import { UnknownRecord } from '@/definitions/langoid'

export const findDirtyInputs = (initial: UnknownRecord, current: UnknownRecord) => {
  return Object.keys(current).reduce((acc: UnknownRecord, key) => {
    if (typeof current[key] === 'object') {
      if (JSON.stringify(current[key]) !== JSON.stringify(initial[key])) {
        acc[key] = current[key]
      }
    } else {
      if (current[key] !== initial[key]) {
        acc[key] = current[key]
      }
    }
    return acc
  }, {})
}
