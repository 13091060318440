<script lang='ts'>
  import { onMount } from 'svelte'

  import { getIdFromIso } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import Flag from '@/components/forum/Flag.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  export let id: number
  export let iso = ''
  let rights: any[] = []
  let lefts: any[] = []
  const values: any[] = []
  let language: number
  let left: any

  const loadTranslations = async () => {
    const data = await fetchData('main/scriptsTranslateLoad', { id, language })
    rights = data.rights
    lefts = data.lefts
    rights.forEach((original, index) => {
      values[index] = lefts[index]?.content || ''
    })
    left = data.left
  }

  const saveTranslations = async () => {
    await fetchData('main/scriptsTranslateSave', { id, language, values })
  }

  onMount(() => {
    language = getIdFromIso(iso)
    loadTranslations()
  })
  const langChange = (lang: number) => {
    language = lang
    loadTranslations()
    return true
  }
</script>
<Breadcrumb
  breads={[
  {text:'',url:`/${iso}`},
  {text:'Language scripts',url:`/${iso}/advanced/language-scripts`},
  {text:'Translate script'}
  ]}
/>
{#if left?.id_language && language && left?.id_language === language}
  <p>{$_('script.translationLanguage')}</p>
  <LanguageSelect onChange={langChange} selectedLanguage={language} />
{:else}
  <div>
    {#if left?.id_language && language}
      from
      {#key left.id_language}
        <Flag id={left.id_language} />
      {/key}
      to
      {#key language}
        <Flag id={language} />
      {/key}
    {/if}
  </div>
  <form action='' method='post' on:submit|preventDefault={saveTranslations}>
    {#if rights}
      {#each rights as original, index}
        <div>{original.content}</div>
        <div><input type='text' bind:value={values[index]} /></div>
      {/each}
    {/if}
    <input type='submit' value='Save' />
  </form>
{/if}
