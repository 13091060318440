<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  const path = window.location.href
  let show = false
  if (path.includes('/tests')) {
    show = true
  }
</script>
{#if show}
  <div class='alert-box'>
    <h2>{$_('tools.noCheating')}</h2>
    {$_('tools.advice')}
  </div>
{/if}
<style lang='scss'>
  .alert-box {
    padding: 2rem;
    text-align: center;
    color: var(--inverted-text-color);
    background-color: var(--color-error);
  }
</style>
