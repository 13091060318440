import { allLanguages } from '@/config/allLanguages'

import { AllLanguagesItem } from '@/definitions/langoid'

export const findLangById = (id: number) => {
  return allLanguages.find((lang: AllLanguagesItem) => lang.id === id)?.name || id
}

export const findIsoByLangId = (id: number) => {
  return allLanguages.find((lang: AllLanguagesItem) => lang.id === id)?.iso
}
