import { get } from 'svelte/store'

import { LanguageFeatures } from '@/definitions/langoid'
import { userStorredConfig } from '@/store'

export const actionBlockAdded = (action: string, features: LanguageFeatures) => {
  if (action === 'lessons') return features.vocabulary > 0
  if (action === 'qualificationTest') return features.vocabulary > 0
  if (action === 'reading') return features.learnToRead > 0
  if (action === 'tests') return features.testsWords > 0
  if (action === 'testsAudioSentences') return features.testsAudioSentences > 0
  if (action === 'testsSentences') return features.testsSentences > 0
  if (action === 'vocabulary') return features.vocabulary > 0
  if (action === 'listen.words') return features.listenWords > 0
  if (action === 'word.forms') return features.wordForms > 0
  if (action === 'listenSentences') return features.listenSentences > 0
  if (action === 'chat') return features.chat > 0
  if (!(action in features)) return true

  return true
}
export const has = (type: string, val: string) => {
  const user = get(userStorredConfig)
  if (type === 'priv') {
    if (val === 'admin' && user.is_admin) return true
    if (val === 'premium' && user.is_premium) return true
  } //  else if (type === 'badge' || type === 'skill') {}
  return false
}

export const updatePoints = (points: number, level: number) => {
  userStorredConfig.setKeys({
    level,
    points
  })
  return ''
}

export const saveActivity = (action: any[]) => {
  // action is array: [0] - action, [1] rel, [2] ref [3] time
  if (typeof action[1] === 'undefined') action[1] = ''
  if (typeof action[2] === 'undefined') action[2] = ''
  action[3] = Date.now()
  const activity = JSON.parse(localStorage.getItem('activity') || '[]')
  activity.push(action)
  localStorage.setItem('activity', JSON.stringify(activity))
}
