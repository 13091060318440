export const formatActivities = (rawList: string) => {
  const list = JSON.parse(rawList)
  if (!list || list.length === 0) return []
  const groups: any = {}
  list.forEach((item: any) => {
    const [name, rel, ref, time] = item
    if (typeof groups[name] === 'undefined') {
      groups[name] = {
        refs: [],
        rels: [],
        times: []
      }
    }
    groups[name].rels.push(rel)
    groups[name].refs.push(ref)
    groups[name].times.push(time)
  })

  const groupsFormatted: any = {}
  for (const key in groups) {
    const group = groups[key]
    const rel: any = formatRels(group.rels)
    let amount = 0
    for (const relKey in rel) amount += rel[relKey]
    groupsFormatted[key] = {
      amount,
      ref: group.refs.filter((el: any) => el),
      rel,
      times: [group.times[0], group.times.pop()]
    }
  }

  return groupsFormatted
}

const formatRels = (rels: any[]) => {
  const tmp = rels.sort()
  const relsGrouped: any = {}
  tmp.forEach(item => {
    if (!relsGrouped[item]) relsGrouped[item] = 0
    ++relsGrouped[item]
  })
  return relsGrouped
}
