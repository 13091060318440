<script lang='ts'>
  import { link } from 'svelte-routing'

  import { MAX_BREADCRUMB_SLUG_LENGTH } from '@/config/constants'

  import { _ } from '@/helpers/i18n'
  import { isSlugString, truncateUrl } from '@/helpers/mixHelpers'

  import { Bread } from '@/definitions/langoid'

  export let breads: Bread[] = [],
    currentLocation: string[] = []
</script>
{#if breads.length}
  {#each breads as bread, index}
    {#if index > 1}
      <span> / </span>
    {/if}
    {#if index < breads.length - 1}
      <a href={bread.url} use:link> {bread.text.includes('.') ? $_(bread.text) : bread.text} </a>
    {:else}
      <span> {bread.text.includes('.') ? $_(bread.text) : bread.text} </span>
    {/if}
  {/each}
{:else}
  {#each currentLocation as segment, index}
    {#if index > 1 && isSlugString(currentLocation[index])}
      <span>{truncateUrl(segment, MAX_BREADCRUMB_SLUG_LENGTH)}{index < currentLocation.length - 1 ? ' / ' : ''}</span>
    {/if}
  {/each}
{/if}
