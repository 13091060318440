<script lang='ts'>
  import { _ } from '@/helpers/i18n'
  export let tabNames: Record<string, string>,
    activeTab: string,
    changeTab = (tabName: string) => {}

</script>

<div class='_tabs-wrapper -forum'>
  {#each ['topics', 'replies'] as name}
    <button
      class:_transparent-gray={activeTab !== name}
      tabindex='0'
      type='button'
      on:click={() => { changeTab(name) }}
      on:keypress={() => {}}
    >{$_(tabNames[name])}
    </button>
  {/each}
</div>

