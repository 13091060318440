<script lang='ts'>
  import { link } from 'svelte-routing'

  import { _ } from '@/helpers/i18n'

  import FooterNotLogged from '@/components/menu/FooterNotLogged.svelte'
  import MenuNotLogged from '@/components/menu/MenuNotLogged.svelte'
  import YoutubePlayer from '@/components/ui/YoutubePlayer.svelte'
</script>
<div class='home-wrapper'>
  <MenuNotLogged />
  <div class='cover-area -second'>
    <div class='inner-area -second'>
      <h2 class='headingText -thin'>{@html $_('home.learnSerbianOrEnglish')}</h2>
      <YoutubePlayer id='DUoeER79jlg' />
      <a class='primaryButton' href='/register' type='button' use:link>{$_('home.startLearning')}</a>
    </div>
  </div>

  <FooterNotLogged />
</div>

<style lang='scss'>
  :global(body) {
    position: relative;
    margin: 0 auto;
  }

  .home-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .inner-area {
    width: 100%;
    max-width: 90rem;
    margin: 5rem auto;

    > .headingText {
      font-size: 3.2rem;
      font-family: 'Noto Sans', sans-serif;

      &.-thin {
        font-weight: 200;
        line-height: 1.5;
        color: var(--Primary-Medium);
      }
    }

    &.-second {
      max-width: 68.4rem;
      text-align: center;

      :global(lite-youtube) {
        max-width: 100%;
        border: 0.1rem solid var(--Gray-Light);
        border-radius: 3rem;
        box-shadow: var(--medium-box-shadow);
      }
    }

    > .primaryButton {
      display: inline-block;
      width: unset;
      margin: 1.2rem auto;
      padding: 1rem 2rem 1rem 2rem;
      font-weight: bold;
      font-size: 2rem;
      color: var(--inverted-text-color);
      background: var(--Accent-Medium);
      border: 0;
      border-radius: 4rem;
    }
  }

  .cover-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin: auto;
    background: var(--main-background);
    border: 0;
  }

  @media (max-width: 1024px) {
    * {
      position: static !important;
    }
  }

  @media (max-width: 768px) {
    .inner-area {
      > .headingText {
        font-size: 2.4rem;

        &.-thin {
          line-height: 1.2;
        }
      }
    }
  }
</style>
