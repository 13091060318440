<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'

  import { RESULTS_PER_PAGE } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { clickOutside } from '@/helpers/mixHelpers'

  import NotificationRow from '@/components/menu/NotificationRow.svelte'

  import { Notification } from '@/definitions/langoid'
  import { MixedStoreData } from '@/definitions/stores'
  import { mixStore } from '@/store'

  export let toggleNotifications: () => void

  let notifications: Notification[] = []
  let moreNotifications = false
  let cursor = ''
  let showUnread = false

  loadNotifications()

  onMount(() => {
    document.body.style.overflow = 'hidden'
  })

  async function loadNotifications () {
    const data = await fetchData('social/notificationsLoad', { cursor, showUnread })
    data.forEach((e: Notification) => {
      e.params = JSON.parse(e.params)
    })

    if (data.length > RESULTS_PER_PAGE) {
      const last = data.pop()
      cursor = last.date
      moreNotifications = true
    } else {
      moreNotifications = false
    }

    notifications = [...notifications, ...data]
  }

  function showUnreadOrAll () {
    cursor = ''
    showUnread = !showUnread
    notifications = []
    loadNotifications()
  }

  let unreadCount = 0
  const unsubscribe = mixStore.subscribe((value: MixedStoreData) => {
    unreadCount = value.notificationsCount
  })

  const markAllRead = async () => {
    await fetchData('social/notificationsMarkAsRead', { id: 0 })
    mixStore.setKey('notificationsCount', 0)
    notifications.forEach((row: Notification) => {
      row.seen = 'yes'
    })
    notifications = notifications
  }

  onDestroy(() => {
    unsubscribe()
    document.body.style.overflow = 'auto'
  })
</script>

<div
  class='notifications-wrapper _gap24'
  on:click_outside={toggleNotifications}
  use:clickOutside
>
  <div class='header-buttons'>
    <button
      class='_maxwidth-btn'
      disabled={unreadCount === 0}
      type='button'
      on:click={markAllRead}
    >
      {unreadCount === 0 ? $_('menu.allNotifyOpen') : $_('menu.markEverythingAsRead')}
    </button>
    <button
      class='_maxwidth-btn'
      type='button'
      on:click={showUnreadOrAll}
    >
      {!showUnread ? $_('notifications.showUnread') : $_('notifications.showAll')}
    </button>
  </div>
  <div>
    {#each notifications as notification}
      {#if !showUnread || notification.seen === 'no'}
        <NotificationRow {...notification} />
      {/if}
    {:else}
      <div class='link'>{$_('mix.noNotifications')}</div>
    {/each}
  </div>
  {#if moreNotifications}
    <button class='_maxwidth-btn' type='button' on:click={loadNotifications}>{$_('mix.loadMore')}</button>
  {/if}
</div>

<style lang='scss'>
  .notifications-wrapper {
    position: absolute;
    top: 5rem;
    right: 1.6rem;
    z-index: 30;
    overflow-y: auto;
    width: 51.2rem;
    height: 80rem;
    box-shadow: var(--Shadow-Z);
  }

  .header-buttons {
    display: flex;
    gap: 0.8rem;
  }

  @media(max-width: 768px) {
    .notifications-wrapper {
      position: fixed;
      top: 6.4rem;
      left: 0;
      width: 100%;
      border-radius: 0;
      box-shadow: unset;
    }

    .header-buttons {
      display: flex;
      flex-direction: column;
    }
  }
</style>
