<script lang='ts'>
  import { link } from 'svelte-routing'

  import { posphorIcons } from '@/helpers/icons'

  import Icon from '@/components/icons/Icon.svelte'

  export let href: string = ''
  export let action: (() => void) | null = null
  export let select: (() => void) | null = null
  export let label: string
  export let selected = false
  export let icon: keyof typeof posphorIcons
  export let size = 24
</script>
{#if href}
  <a class='kebab-item' {href} rel='nofollow' use:link>
    <span class='image'><Icon {icon} {size} weight='regular' /></span>
    <span class='label'>{label}</span>
  </a>
{:else if action}
  <a
    class='kebab-item'
    href='#top'
    rel='nofollow'
    role='button'
    tabindex='0'
    on:click={action}
  >
    <span class='image'><Icon {icon} {size} weight='regular' /></span>
    <span class='label'>{label}</span>
  </a>
{:else if select}
  <div
    class='kebab-item'
    role='button'
    tabindex='0'
    on:click={select}
    on:keypress={() => {}}
  >
    <span class='image' class:-check={!selected}><Icon {icon} {size} weight='regular' /></span>
    <span class='label'>{label}</span>
  </div>
{/if}
<style lang='scss'>
  .kebab-item {
    > .image {
      display: inline-block;
      width: 2.4rem;
      min-width: 2rem;
      height: 2.4rem;

      &.-check {
        visibility: hidden;
      }
    }

    > .label {
      display: inline-block;
    }
  }
</style>
