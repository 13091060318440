/* eslint-disable */
/**
 * You can get props for specific component like this:
 *   console.log($$props) // add this line to any component and read what is logged in console, after that right click on the object in the console and choose "Copy Object" option
 */
export const props = {
  exampleSentences: {
    'sentences': [
      {
        'id': 30712,
        'audio': 'https://cdn.langoid.com/a/sentence-30712-2-202104250942.wav',
        'original_audio': 'https://cdn.langoid.com/a/sentence-30712-2-202104250942.wav',
        'content': 'Usahay kung daghan siya og adto-on nga mga lugar, usa ka bulan dili siya makita sa iyang pamilya.',
        'original': 'Usahay kung daghan siya og adto##-on nga mga lugar, usa ka bulan dili siya makita sa iyang pamilya.',
        'translation': 'Sometimes when he has more than one place to go, his family members do###n##\'t see him for a month.',
        'id_word': 5374,
        'translation_audio': 'https://cdn.langoid.com/audio/eng/sentences/3445.mp3'
      },
      {
        'id': 31340,
        'audio': 'https://cdn.langoid.com/a/sentence-31340-2-202104281107.wav',
        'original_audio': 'https://cdn.langoid.com/a/sentence-31340-2-202104281107.wav',
        'content': 'Wala ko kabalo kung ganahan ka o dili.',
        'original': 'Wala ko kabalo kung ganahan ka o dili.',
        'translation': 'I do###n##\'t know if you###\'d like it or not.',
        'id_word': 5374,
        'translation_audio': 'https://cdn.langoid.com/audio/eng/sentences/705.mp3'
      }
    ]
  },
  flagsList: { 'active': 2, 'languages': [78, 14, 9] },
  replyKebab: {
    'isReplyTranslation': true,
    'reply': {
      'id': 118,
      'id_user': 239,
      'id_topic': 113,
      'content': '<p>Hay Reen! Magkita na unta ta puhon :) Magpraktis ta og serbian</p>',
      'page': 1,
      'id_reply': 0,
      'id_language': 3,
      'reply_score': 0,
      'is_archived': 0,
      'is_hidden': 0,
      'id_task': 0,
      'revision_count': 2,
      'revision_details': '[{"id_user":239,"created_at":"2023-02-04 17:30:14","avatar":"https://lh3.googleusercontent.com/a-/AOh14Gjtl7YaX20hTGu4sXKW76Jmw1H1S0-B-7T4PCg8=s96-c","nick":"Hani"},{"id_user":239,"created_at":"2023-02-04 17:29:31","avatar":"https://lh3.googleusercontent.com/a-/AOh14Gjtl7YaX20hTGu4sXKW76Jmw1H1S0-B-7T4PCg8=s96-c","nick":"Hani"}]',
      'created_at': '2023-02-04 16:14:05',
      'updated_at': '2023-03-03 17:40:59',
      'userId': 239,
      'name': 'Hani',
      'nick': 'Hani',
      'points': 13951,
      'level': 17,
      'avatar': 'https://cdn.langoid.com/avatars/239.jpeg',
      'original_language': 2,
      'bookmarked': false,
      'translatedBy': {
        'id': 239,
        'nick': 'Hani',
        'avatar': 'https://cdn.langoid.com/avatars/239.jpeg',
        'level': 17
      }
    },
    'topic': {
      'id': 113,
      'id_user': 311,
      'id_category': 16,
      'content': 'Ako si Loreen. Akong mga higala tawagon kog Reen. Gikan kog Cebu. Usa sa mga nindot nga isla sa Pilipinas. Naminyo kog Serbian maong naa nako diri karun nagpuyo. Niabot ko dinhi Agusto sa tuig 2019. Mag upat na ko ka tuig dinhi.',
      'page': 1,
      'num_of_replies': 8,
      'num_of_users': 5,
      'topic_score': 20,
      'is_pinned': 0,
      'is_locked': 0,
      'is_archived': 0,
      'is_hidden': 0,
      'id_language': 3,
      'target_language': 0,
      'target_level': 'unknown',
      'id_original': 0,
      'type': 'normal',
      'read_level': 0,
      'write_level': 0,
      'admin_read_level': 0,
      'admin_write_level': 0,
      'id_task': 0,
      'revision_count': 0,
      'revision_details': '',
      'created_at': '2023-02-04 14:56:11',
      'updated_at': '2023-04-09 12:39:34',
      'userId': 311,
      'name': 'Loreen Besarabić',
      'nick': 'Loreen',
      'points': 164,
      'level': 4,
      'avatar': 'https://lh3.googleusercontent.com/a/AEdFTp6shBREy8rYfWHB47hs0wQJDuOVUiF41Zvhdq6IJ3g=s96-c',
      'original_language': 3,
      'languages': [
        1,
        2
      ],
      'bookmarked': false
    }, markDownvote: () => {}
  },
  sentence: {
    'currentSentence': {
      'id': 33651,
      'audio': 'https://cdn.langoid.com/a/sentence-33651-239-202306291014.wav',
      'order_in_lesson': 0,
      'tagged': [
        {
          'content': 'Unsay',
          'id_word': 5368,
          'id_wif': 182583,
          'order_of_word': 1,
          'isWord': true,
          'original': 'unsa',
          'forms': 1,
          'level': 'a1',
          'word_class': ' word-level-checker',
          'pos': 4,
          'id': 5368,
          'wif': 182583,
          'freq': 340
        },
        {
          'content': ' ',
          'id_word': 0,
          'id_wif': ' ',
          'order_of_word': 2,
          'isWord': false,
          'original': ' ',
          'forms': '',
          'level': 'space',
          'word_class': '',
          'pos': '-',
          'id': 0,
          'wif': ' ',
          'freq': 1000000
        },
        {
          'content': 'temperatura',
          'id_word': 7557,
          'id_wif': 173523,
          'order_of_word': 3,
          'isWord': true,
          'original': 'temperatura',
          'forms': 1,
          'level': 'a2',
          'word_class': ' word-level-checker',
          'pos': 1,
          'id': 7557,
          'wif': 173523,
          'freq': 16310
        },
        {
          'content': ' ',
          'id_word': 0,
          'id_wif': ' ',
          'order_of_word': 4,
          'isWord': false,
          'original': ' ',
          'forms': '',
          'level': 'space',
          'word_class': '',
          'pos': '-',
          'id': 0,
          'wif': ' ',
          'freq': 1000000
        },
        {
          'content': 'sa',
          'id_word': 18675,
          'id_wif': '',
          'order_of_word': 5,
          'isWord': true,
          'original': 'sa',
          'forms': 13,
          'level': 'a1',
          'word_class': ' word-level-checker',
          'pos': 7,
          'id': 18675,
          'wif': '',
          'freq': 170
        },
        {
          'content': ' ',
          'id_word': 0,
          'id_wif': ' ',
          'order_of_word': 6,
          'isWord': false,
          'original': ' ',
          'forms': '',
          'level': 'space',
          'word_class': '',
          'pos': '-',
          'id': 0,
          'wif': ' ',
          'freq': 1000000
        },
        {
          'content': 'gawas',
          'id_word': 6536,
          'id_wif': '',
          'order_of_word': 7,
          'isWord': true,
          'original': 'gawas',
          'forms': 11,
          'level': 'a1',
          'word_class': ' word-level-checker',
          'pos': 7,
          'id': 6536,
          'wif': '',
          'freq': 8120
        },
        {
          'content': '?',
          'id_word': 0,
          'id_wif': ' ',
          'order_of_word': 8,
          'isWord': false,
          'original': '?',
          'forms': '',
          'level': 'int',
          'word_class': '',
          'pos': '-',
          'id': 0,
          'wif': ' ',
          'freq': 1000000
        }
      ],
      'translation': {
        'id': 33651,
        'id2': 22286,
        'sentence': 'Unsay temperatura sa gawas?',
        'sentence2': 'What###\'s the temperature outside?'
      }
    },
    'iso': 'ceb',
    'lessonWords': {
      '5106': {
        'id': 5106,
        'content': 'ako',
        'learning-status': 'know L3',
        'main-form': 'ako',
        'translation': 'I',
        'pos': 'pronoun',
        'id_pos': 4,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5106-2-20210415092655.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 5106,
            'id_wif_template': 1150,
            'wif_template': 'pronoun_normal',
            'wif': ''
          },
          {
            'id_word': 5106,
            'id_wif_template': 1150,
            'wif_template': 'pronoun_normal',
            'wif': 'nako'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1150,
            'wif_template': 'pronoun_normal',
            'wif': 'rako'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1150,
            'wif_template': 'pronoun_normal',
            'wif': 'ko'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1158,
            'wif_template': 'pronoun_objective_case',
            'wif': 'kanako'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1160,
            'wif_template': 'pronoun_postpossessive_case',
            'wif': 'nako'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1159,
            'wif_template': 'pronoun_prepossessive_case',
            'wif': 'ako'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1161,
            'wif_template': 'pronoun_prepossessive_case_ng',
            'wif': 'akong'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1157,
            'wif_template': 'pronoun_subjective_case',
            'wif': 'ako'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1158,
            'wif_template': 'pronoun_objective_case',
            'wif': 'koy'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1158,
            'wif_template': 'pronoun_objective_case',
            'wif': 'kong'
          },
          {
            'id_word': 5106,
            'id_wif_template': 1159,
            'wif_template': 'pronoun_prepossessive_case',
            'wif': 'akoa'
          }
        ]
      },
      '5361': {
        'id': 5361,
        'content': 'pero',
        'learning-status': 'learning L3',
        'main-form': 'pero',
        'translation': 'but',
        'pos': 'conjuction',
        'id_pos': 8,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5361-2-20210415093024.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '5362': {
        'id': 5362,
        'content': 'kita',
        'learning-status': 'learning L3',
        'main-form': 'kita',
        'translation': 'we',
        'pos': 'pronoun',
        'id_pos': 4,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5362-2-20210415093033.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '5368': {
        'id': 5368,
        'content': 'unsa',
        'learning-status': 'know L2',
        'main-form': 'unsa',
        'translation': 'what',
        'pos': 'pronoun',
        'id_pos': 4,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5368-2-20210415093232.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '5374': {
        'id': 5374,
        'content': 'kung',
        'learning-status': 'learning L3',
        'main-form': 'kung',
        'translation': 'if',
        'pos': 'conjuction',
        'id_pos': 8,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5374-2-20210415093339.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '5388': {
        'id': 5388,
        'content': 'ko',
        'learning-status': 'learning L3',
        'main-form': 'ko',
        'translation': 'me',
        'pos': 'pronoun',
        'id_pos': 4,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5388-2-20210415184530.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '5405': {
        'id': 5405,
        'content': 'gusto',
        'learning-status': 'learning L3',
        'main-form': 'gusto',
        'translation': 'want',
        'pos': 'verb',
        'id_pos': 2,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5405-2-20210415185009.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 5405,
            'id_wif_template': 1133,
            'wif_template': 'glagol_active_abilitive_future',
            'wif': 'makagusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1132,
            'wif_template': 'glagol_active_abilitive_present_past',
            'wif': 'nakagusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1126,
            'wif_template': 'glagol_active_durative_future',
            'wif': 'maggusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1127,
            'wif_template': 'glagol_active_durative_future2',
            'wif': 'magagusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1124,
            'wif_template': 'glagol_active_durative_present_past',
            'wif': 'naggusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1125,
            'wif_template': 'glagol_active_durative_present_past2',
            'wif': 'nagagusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1136,
            'wif_template': 'glagol_active_plural_command',
            'wif': 'panggusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1135,
            'wif_template': 'glagol_active_plural_future',
            'wif': 'manggusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1134,
            'wif_template': 'glagol_active_plural_present_past',
            'wif': 'nanggusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1130,
            'wif_template': 'glagol_active_stative_command',
            'wif': 'kagusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1131,
            'wif_template': 'glagol_active_stative_command2',
            'wif': 'pagkagusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1129,
            'wif_template': 'glagol_active_stative_future',
            'wif': 'magusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1128,
            'wif_template': 'glagol_active_stative_present_past',
            'wif': 'nagusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1123,
            'wif_template': 'glagol_active_temporal_durative_command',
            'wif': 'paggusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1122,
            'wif_template': 'glagol_active_temporal_future',
            'wif': 'mogusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1121,
            'wif_template': 'glagol_active_temporal_past',
            'wif': 'nigusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1120,
            'wif_template': 'glagol_active_temporal_present',
            'wif': 'migusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1141,
            'wif_template': 'glagol_direct_passive_abilitive_future',
            'wif': 'magusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1140,
            'wif_template': 'glagol_direct_passive_abilitive_present_past',
            'wif': 'nagusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1139,
            'wif_template': 'glagol_direct_passive_temporal_durative_command',
            'wif': 'gustoha'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1138,
            'wif_template': 'glagol_direct_passive_temporal_durative_future',
            'wif': 'gustohon'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1137,
            'wif_template': 'glagol_direct_passive_temporal_durative_present_past',
            'wif': 'gigusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1144,
            'wif_template': 'glagol_indirect_passive_temporal_durative_command',
            'wif': 'gustohi'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1143,
            'wif_template': 'glagol_indirect_passive_temporal_durative_future',
            'wif': 'gustohan'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1142,
            'wif_template': 'glagol_indirect_passive_temporal_durative_present_past',
            'wif': 'gigustohan'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1147,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_command',
            'wif': 'igusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1146,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_future',
            'wif': 'igusto'
          },
          {
            'id_word': 5405,
            'id_wif_template': 1145,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_present_past',
            'wif': 'gigusto'
          }
        ]
      },
      '5926': {
        'id': 5926,
        'content': 'dagan',
        'learning-status': 'learning L3',
        'main-form': 'dagan',
        'translation': 'run',
        'pos': 'verb',
        'id_pos': 2,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5926-2-20210415190915.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '5931': {
        'id': 5931,
        'content': 'ganahan',
        'learning-status': 'learning L3',
        'main-form': 'ganahan',
        'translation': 'like',
        'pos': 'verb',
        'id_pos': 2,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-5931-2-20210415191007.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 5931,
            'id_wif_template': 1133,
            'wif_template': 'glagol_active_abilitive_future',
            'wif': 'makaganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1132,
            'wif_template': 'glagol_active_abilitive_present_past',
            'wif': 'nakaganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1126,
            'wif_template': 'glagol_active_durative_future',
            'wif': 'maganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1127,
            'wif_template': 'glagol_active_durative_future2',
            'wif': 'magaganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1124,
            'wif_template': 'glagol_active_durative_present_past',
            'wif': 'naganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1125,
            'wif_template': 'glagol_active_durative_present_past2',
            'wif': 'nagaganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1136,
            'wif_template': 'glagol_active_plural_command',
            'wif': 'pangganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1135,
            'wif_template': 'glagol_active_plural_future',
            'wif': 'mangganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1134,
            'wif_template': 'glagol_active_plural_present_past',
            'wif': 'nangganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1130,
            'wif_template': 'glagol_active_stative_command',
            'wif': 'kaganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1131,
            'wif_template': 'glagol_active_stative_command2',
            'wif': 'pagkaganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1129,
            'wif_template': 'glagol_active_stative_future',
            'wif': 'maganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1128,
            'wif_template': 'glagol_active_stative_present_past',
            'wif': 'naganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1123,
            'wif_template': 'glagol_active_temporal_durative_command',
            'wif': 'pagganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1122,
            'wif_template': 'glagol_active_temporal_future',
            'wif': 'moganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1121,
            'wif_template': 'glagol_active_temporal_past',
            'wif': 'niganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1120,
            'wif_template': 'glagol_active_temporal_present',
            'wif': 'miganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1141,
            'wif_template': 'glagol_direct_passive_abilitive_future',
            'wif': 'maganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1140,
            'wif_template': 'glagol_direct_passive_abilitive_present_past',
            'wif': 'naganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1139,
            'wif_template': 'glagol_direct_passive_temporal_durative_command',
            'wif': 'ganahana'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1138,
            'wif_template': 'glagol_direct_passive_temporal_durative_future',
            'wif': 'ganahanon'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1137,
            'wif_template': 'glagol_direct_passive_temporal_durative_present_past',
            'wif': 'giganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1144,
            'wif_template': 'glagol_indirect_passive_temporal_durative_command',
            'wif': 'ganahani'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1143,
            'wif_template': 'glagol_indirect_passive_temporal_durative_future',
            'wif': 'ganahanan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1142,
            'wif_template': 'glagol_indirect_passive_temporal_durative_present_past',
            'wif': 'giganahanan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1147,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_command',
            'wif': 'iganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1146,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_future',
            'wif': 'iganahan'
          },
          {
            'id_word': 5931,
            'id_wif_template': 1145,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_present_past',
            'wif': 'giganahan'
          }
        ]
      },
      '6071': {
        'id': 6071,
        'content': 'na',
        'learning-status': 'learning L3',
        'main-form': 'na',
        'translation': 'already',
        'pos': 'adverb',
        'id_pos': 5,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-6071-2-20210415192805.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 6071,
            'id_wif_template': 1151,
            'wif_template': 'adverb_normal',
            'wif': 'na'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nang'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          },
          {
            'id_word': 6071,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'nay'
          }
        ]
      },
      '6536': {
        'id': 6536,
        'content': 'gawas',
        'learning-status': 'learning L3',
        'main-form': 'gawas',
        'translation': 'outside',
        'pos': 'preposition',
        'id_pos': 7,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-6536-2-20210416174959.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '6580': {
        'id': 6580,
        'content': 'tugnaw',
        'learning-status': 'know L3',
        'main-form': 'tugnaw',
        'translation': 'cold',
        'pos': 'adjective',
        'id_pos': 3,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-6580-2-20210416175946.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 6580,
            'id_wif_template': 1149,
            'wif_template': 'adjective_normal',
            'wif': 'tugnaw'
          },
          {
            'id_word': 6580,
            'id_wif_template': 1153,
            'wif_template': 'adjective_particulizer',
            'wif': 'tugnawa'
          },
          {
            'id_word': 6580,
            'id_wif_template': 2319,
            'wif_template': 'adjective_future',
            'wif': 'tugnawon'
          },
          {
            'id_word': 6580,
            'id_wif_template': 2321,
            'wif_template': 'adjective_pluralized_present_past',
            'wif': 'gipangtugnaw'
          },
          {
            'id_word': 6580,
            'id_wif_template': 2320,
            'wif_template': 'adjective_singularized_present_past',
            'wif': 'gitugnaw'
          },
          {
            'id_word': 6580,
            'id_wif_template': 2318,
            'wif_template': 'adjective_superlative',
            'wif': 'pinakatugnaw'
          }
        ]
      },
      '6787': {
        'id': 6787,
        'content': 'uban',
        'learning-status': 'learning L3',
        'main-form': 'uban',
        'translation': 'with',
        'pos': 'preposition',
        'id_pos': 7,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-6787-2-20210415092901.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '7136': {
        'id': 7136,
        'content': 'init',
        'learning-status': 'learning L3',
        'main-form': 'init',
        'translation': 'hot',
        'pos': 'adjective',
        'id_pos': 3,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-7136-2-20210416173317.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 7136,
            'id_wif_template': 1149,
            'wif_template': 'adjective_normal',
            'wif': 'init'
          },
          {
            'id_word': 7136,
            'id_wif_template': 1153,
            'wif_template': 'adjective_particulizer',
            'wif': 'inita'
          },
          {
            'id_word': 7136,
            'id_wif_template': 2319,
            'wif_template': 'adjective_future',
            'wif': 'initon'
          },
          {
            'id_word': 7136,
            'id_wif_template': 2321,
            'wif_template': 'adjective_pluralized_present_past',
            'wif': 'gipanginit'
          },
          {
            'id_word': 7136,
            'id_wif_template': 2320,
            'wif_template': 'adjective_singularized_present_past',
            'wif': 'giinit'
          },
          {
            'id_word': 7136,
            'id_wif_template': 2318,
            'wif_template': 'adjective_superlative',
            'wif': 'pinakainit'
          }
        ]
      },
      '7557': {
        'id': 7557,
        'content': 'temperatura',
        'learning-status': 'learning L3',
        'main-form': 'temperatura',
        'translation': 'temperature',
        'pos': 'noun',
        'id_pos': 1,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-7557-2-20210424104557.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 7557,
            'id_wif_template': 1148,
            'wif_template': 'noun_normal',
            'wif': 'temperatura'
          },
          {
            'id_word': 7557,
            'id_wif_template': 1155,
            'wif_template': 'noun_particulizer',
            'wif': 'temperaturaha'
          },
          {
            'id_word': 7557,
            'id_wif_template': 1152,
            'wif_template': 'noun_singularized_plural',
            'wif': 'katemperaturahan'
          }
        ]
      },
      '8917': {
        'id': 8917,
        'content': 'nyebe',
        'learning-status': 'learning L2',
        'main-form': 'nyebe',
        'translation': 'snow',
        'pos': 'noun',
        'id_pos': 1,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-8917-2-20210424110731.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 8917,
            'id_wif_template': 1148,
            'wif_template': 'noun_normal',
            'wif': 'nyebe'
          },
          {
            'id_word': 8917,
            'id_wif_template': 1155,
            'wif_template': 'noun_particulizer',
            'wif': 'nyebeha'
          },
          {
            'id_word': 8917,
            'id_wif_template': 1152,
            'wif_template': 'noun_singularized_plural',
            'wif': 'kanyebehan'
          }
        ]
      },
      '9068': {
        'id': 9068,
        'content': 'medyo',
        'learning-status': 'learning L2',
        'main-form': 'medyo',
        'translation': 'somewhat',
        'pos': 'adverb',
        'id_pos': 5,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-9068-2-20210424112117.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 9068,
            'id_wif_template': 1151,
            'wif_template': 'adverb_normal',
            'wif': 'medyo'
          },
          {
            'id_word': 9068,
            'id_wif_template': 2317,
            'wif_template': 'adverb_linker',
            'wif': 'medyong'
          }
        ]
      },
      '16798': {
        'id': 16798,
        'content': 'og',
        'learning-status': 'learning L3',
        'main-form': 'og',
        'translation': 'linker',
        'pos': 'other',
        'id_pos': 12,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-16798-2-20210415185803.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '18640': {
        'id': 18640,
        'content': 'adto',
        'learning-status': 'learning L3',
        'main-form': 'adto',
        'translation': 'go',
        'pos': 'verb',
        'id_pos': 2,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-18640-2-20210415093248.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': [
          {
            'id_word': 18640,
            'id_wif_template': 1133,
            'wif_template': 'glagol_active_abilitive_future',
            'wif': 'makaadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1132,
            'wif_template': 'glagol_active_abilitive_present_past',
            'wif': 'nakaadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1126,
            'wif_template': 'glagol_active_durative_future',
            'wif': 'mag-adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1127,
            'wif_template': 'glagol_active_durative_future2',
            'wif': 'maga-adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1124,
            'wif_template': 'glagol_active_durative_present_past',
            'wif': 'nag-adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1125,
            'wif_template': 'glagol_active_durative_present_past2',
            'wif': 'naga-adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1136,
            'wif_template': 'glagol_active_plural_command',
            'wif': 'pangadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1135,
            'wif_template': 'glagol_active_plural_future',
            'wif': 'mangadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1134,
            'wif_template': 'glagol_active_plural_present_past',
            'wif': 'nangadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1130,
            'wif_template': 'glagol_active_stative_command',
            'wif': 'kaadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1131,
            'wif_template': 'glagol_active_stative_command2',
            'wif': 'pagkaadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1129,
            'wif_template': 'glagol_active_stative_future',
            'wif': 'maadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1128,
            'wif_template': 'glagol_active_stative_present_past',
            'wif': 'naadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1123,
            'wif_template': 'glagol_active_temporal_durative_command',
            'wif': 'pag-adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1122,
            'wif_template': 'glagol_active_temporal_future',
            'wif': 'mo-adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1121,
            'wif_template': 'glagol_active_temporal_past',
            'wif': 'ni-adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1120,
            'wif_template': 'glagol_active_temporal_present',
            'wif': 'mi-adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1141,
            'wif_template': 'glagol_direct_passive_abilitive_future',
            'wif': 'maadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1140,
            'wif_template': 'glagol_direct_passive_abilitive_present_past',
            'wif': 'naadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1139,
            'wif_template': 'glagol_direct_passive_temporal_durative_command',
            'wif': 'adto-a'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1138,
            'wif_template': 'glagol_direct_passive_temporal_durative_future',
            'wif': 'adto-on'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1137,
            'wif_template': 'glagol_direct_passive_temporal_durative_present_past',
            'wif': 'giadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1144,
            'wif_template': 'glagol_indirect_passive_temporal_durative_command',
            'wif': 'adto-i'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1143,
            'wif_template': 'glagol_indirect_passive_temporal_durative_future',
            'wif': 'adto-an'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1142,
            'wif_template': 'glagol_indirect_passive_temporal_durative_present_past',
            'wif': 'gaadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1147,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_command',
            'wif': 'adto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1146,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_future',
            'wif': 'iadto'
          },
          {
            'id_word': 18640,
            'id_wif_template': 1145,
            'wif_template': 'glagol_instrumental_passive_temporal_durative_present_past',
            'wif': 'adto'
          }
        ]
      },
      '18644': {
        'id': 18644,
        'content': 'dili',
        'learning-status': 'learning L3',
        'main-form': 'dili',
        'translation': 'not',
        'pos': 'other',
        'id_pos': 12,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-18644-2-20210415093129.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '18675': {
        'id': 18675,
        'content': 'sa',
        'learning-status': 'learning L3',
        'main-form': 'sa',
        'translation': 'on',
        'pos': 'preposition',
        'id_pos': 7,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-18675-2-20210415092926.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      },
      '18783': {
        'id': 18783,
        'content': 'mag-inusara',
        'learning-status': 'learning L3',
        'main-form': 'mag-inusara',
        'translation': 'alone',
        'pos': 'adverb',
        'id_pos': 5,
        'romanization': '',
        'romanization2': '',
        'audio': 'https://cdn.langoid.com/a/word-18783-2-20210422192148.wav',
        'wif_template': '',
        'mnemo': '',
        'note': '',
        'forms': []
      }
    },
    'selected': 0,
    'words': [
      {
        'content': 'Unsay',
        'id_word': 5368,
        'id_wif': 182583,
        'order_of_word': 1,
        'isWord': true,
        'original': 'unsa',
        'forms': 1,
        'level': 'a1',
        'word_class': ' word-level-checker',
        'pos': 4,
        'id': 5368,
        'wif': 182583,
        'freq': 340,
        'learningStatus': 'know L2'
      },
      {
        'content': ' ',
        'id_word': 0,
        'id_wif': ' ',
        'order_of_word': 2,
        'isWord': false,
        'original': ' ',
        'forms': '',
        'level': 'space',
        'word_class': '',
        'pos': '-',
        'id': 0,
        'wif': ' ',
        'freq': 1000000
      },
      {
        'content': 'temperatura',
        'id_word': 7557,
        'id_wif': 173523,
        'order_of_word': 3,
        'isWord': true,
        'original': 'temperatura',
        'forms': 1,
        'level': 'a2',
        'word_class': ' word-level-checker',
        'pos': 1,
        'id': 7557,
        'wif': 173523,
        'freq': 16310,
        'learningStatus': 'learning L3'
      },
      {
        'content': ' ',
        'id_word': 0,
        'id_wif': ' ',
        'order_of_word': 4,
        'isWord': false,
        'original': ' ',
        'forms': '',
        'level': 'space',
        'word_class': '',
        'pos': '-',
        'id': 0,
        'wif': ' ',
        'freq': 1000000
      },
      {
        'content': 'sa',
        'id_word': 18675,
        'id_wif': '',
        'order_of_word': 5,
        'isWord': true,
        'original': 'sa',
        'forms': 13,
        'level': 'a1',
        'word_class': ' word-level-checker',
        'pos': 7,
        'id': 18675,
        'wif': '',
        'freq': 170,
        'learningStatus': 'learning L3'
      },
      {
        'content': ' ',
        'id_word': 0,
        'id_wif': ' ',
        'order_of_word': 6,
        'isWord': false,
        'original': ' ',
        'forms': '',
        'level': 'space',
        'word_class': '',
        'pos': '-',
        'id': 0,
        'wif': ' ',
        'freq': 1000000
      },
      {
        'content': 'gawas',
        'id_word': 6536,
        'id_wif': '',
        'order_of_word': 7,
        'isWord': true,
        'original': 'gawas',
        'forms': 11,
        'level': 'a1',
        'word_class': ' word-level-checker',
        'pos': 7,
        'id': 6536,
        'wif': '',
        'freq': 8120,
        'learningStatus': 'learning L3'
      },
      {
        'content': '?',
        'id_word': 0,
        'id_wif': ' ',
        'order_of_word': 8,
        'isWord': false,
        'original': '?',
        'forms': '',
        'level': 'int',
        'word_class': '',
        'pos': '-',
        'id': 0,
        'wif': ' ',
        'freq': 1000000
      }
    ]
  },
  chatMessage: {
    corrected: {
      'correctionMode': 'test',
      'msg': {
        'id': 1795,
        'message': 'O, ganahan ko og pelikula.',
        'id_language': 3,
        'pos_tagged': '[{"sender":"","receiver":"","original":{"o":5367},"content":"O"},", ",{"sender":"","receiver":"","original":{"ganahan":5931},"content":"ganahan"}," ",{"sender":"","receiver":"","original":{"ko":5388,"ako":5106},"content":"ko"}," ",{"sender":"","receiver":"","original":{"og":16798},"content":"og"}," ",{"sender":"","receiver":0,"original":{"pelikula":7264},"content":"pelikula"},"."]',
        'created_at': '2021-09-19 19:14:55',
        'msg_read': 1,
        'msg_read_at': '2021-09-19 19:14:55',
        'sender': 1,
        'receiver': 2,
        'edited': 'yes',
        'reply_to': 0,
        'reply_excerpt': '',
        'reactions': '',
        'bookmarked': false,
        'correction': {
          'id_chat_message': 1795,
          'correction': 'O, ganahan ko sa pelikula.',
          'correction_note': ''
        },
        'posTagged': [
          {
            'sender': '',
            'receiver': '',
            'original': {
              'o': 5367
            },
            'content': 'O',
            'id': 5367
          },
          ', ',
          {
            'sender': '',
            'receiver': '',
            'original': {
              'ganahan': 5931
            },
            'content': 'ganahan',
            'id': 5931
          },
          ' ',
          {
            'sender': '',
            'receiver': '',
            'original': {
              'ko': 5388,
              'ako': 5106
            },
            'content': 'ko',
            'id': 5388
          },
          ' ',
          {
            'sender': '',
            'receiver': '',
            'original': {
              'og': 16798
            },
            'content': 'og',
            'id': 16798
          },
          ' ',
          {
            'sender': '',
            'receiver': 0,
            'original': {
              'pelikula': 7264
            },
            'content': 'pelikula',
            'id': 7264
          },
          '.'
        ]
      },
      'myId': 1,
      'prev': '2021-09-19',
      'readVocabularyFrom': 'vocabulary',
      'selectedUser': 2,
      'vocabulary': {
        '5101': {
          'id_word': 5101,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5102': {
          'id_word': 5102,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5106': {
          'id_word': 5106,
          'mnemo': '',
          'rating': 999,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '5107': {
          'id_word': 5107,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5357': {
          'id_word': 5357,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5361': {
          'id_word': 5361,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5367': {
          'id_word': 5367,
          'mnemo': '',
          'rating': 20,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5368': {
          'id_word': 5368,
          'mnemo': '',
          'rating': 6,
          'flag': 'know',
          'ratingClass': 'L2'
        },
        '5373': {
          'id_word': 5373,
          'mnemo': '',
          'rating': 13,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5374': {
          'id_word': 5374,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5388': {
          'id_word': 5388,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5403': {
          'id_word': 5403,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5409': {
          'id_word': 5409,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5410': {
          'id_word': 5410,
          'mnemo': '',
          'rating': 20,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5412': {
          'id_word': 5412,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5416': {
          'id_word': 5416,
          'mnemo': '',
          'rating': 10,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5722': {
          'id_word': 5722,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5728': {
          'id_word': 5728,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '5730': {
          'id_word': 5730,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5814': {
          'id_word': 5814,
          'mnemo': 'pwede ka tabang nako',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5816': {
          'id_word': 5816,
          'mnemo': '',
          'rating': 20,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5827': {
          'id_word': 5827,
          'mnemo': '',
          'rating': 13,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5926': {
          'id_word': 5926,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5931': {
          'id_word': 5931,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5962': {
          'id_word': 5962,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5987': {
          'id_word': 5987,
          'mnemo': 'si udad u grad',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6053': {
          'id_word': 6053,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6057': {
          'id_word': 6057,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '6071': {
          'id_word': 6071,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6087': {
          'id_word': 6087,
          'mnemo': '',
          'rating': 8,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '6109': {
          'id_word': 6109,
          'mnemo': '',
          'rating': 11,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6203': {
          'id_word': 6203,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '6249': {
          'id_word': 6249,
          'mnemo': 'Thank you',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6471': {
          'id_word': 6471,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6481': {
          'id_word': 6481,
          'mnemo': '',
          'rating': 999,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '6542': {
          'id_word': 6542,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6554': {
          'id_word': 6554,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6599': {
          'id_word': 6599,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6800': {
          'id_word': 6800,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6821': {
          'id_word': 6821,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6874': {
          'id_word': 6874,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '7264': {
          'id_word': 7264,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '7385': {
          'id_word': 7385,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '7421': {
          'id_word': 7421,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '7499': {
          'id_word': 7499,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '8155': {
          'id_word': 8155,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '8891': {
          'id_word': 8891,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9018': {
          'id_word': 9018,
          'mnemo': '',
          'rating': 7,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9072': {
          'id_word': 9072,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9134': {
          'id_word': 9134,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9145': {
          'id_word': 9145,
          'mnemo': '',
          'rating': 12,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9964': {
          'id_word': 9964,
          'mnemo': 'masa ligon pa je samouven',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '10437': {
          'id_word': 10437,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '16795': {
          'id_word': 16795,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '16796': {
          'id_word': 16796,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '16798': {
          'id_word': 16798,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '17349': {
          'id_word': 17349,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '17667': {
          'id_word': 17667,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '17850': {
          'id_word': 17850,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18643': {
          'id_word': 18643,
          'mnemo': '',
          'rating': 11,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18645': {
          'id_word': 18645,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18671': {
          'id_word': 18671,
          'mnemo': ' layo ang siyudad',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '18672': {
          'id_word': 18672,
          'mnemo': '',
          'rating': 10,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18690': {
          'id_word': 18690,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18861': {
          'id_word': 18861,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '19210': {
          'id_word': 19210,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '19271': {
          'id_word': 19271,
          'mnemo': '',
          'rating': 5,
          'flag': 'learning',
          'ratingClass': 'L2'
        },
        '26335': {
          'id_word': 26335,
          'mnemo': '',
          'rating': 999,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '50919': {
          'id_word': 50919,
          'mnemo': '',
          'rating': 2,
          'flag': 'know',
          'ratingClass': 'L1'
        }
      }
    },
    askForExplanation: {
      'correctionMode': 'test',
      'msg': {
        'id': 1837,
        'message': 'Ugma ka manghinlo sa garsonyera?',
        'id_language': 3,
        'pos_tagged': '[{"sender":"","receiver":"","original":{"ugma":7499},"content":"Ugma"}," ",{"sender":"","receiver":"","original":{"ka":50919,"ikaw":18674},"content":"ka"}," ",{"sender":"","receiver":"","original":{"hinlo":9072},"content":"manghinlo"}," ",{"sender":"","receiver":"","original":{"sa":18671,"andar":5806},"content":"sa"}," ",{"sender":0,"receiver":0,"original":[],"content":"garsonyera"},"?"]',
        'created_at': '2021-09-19 19:31:01',
        'msg_read': 1,
        'msg_read_at': '2021-09-19 19:31:02',
        'sender': 2,
        'receiver': 1,
        'edited': 'no',
        'reply_to': 0,
        'reply_excerpt': '',
        'reactions': '',
        'bookmarked': false,
        'explanation': {
          'id': 15,
          'id_chat_message': 1837,
          'question': [
            {
              'id': 7499,
              'word': 'Ugma',
              'checked': false
            },
            {
              'id': 50919,
              'word': 'ka',
              'checked': false
            },
            {
              'id': 9072,
              'word': 'manghinlo',
              'checked': false
            },
            {
              'id': 18671,
              'word': 'sa',
              'checked': false
            },
            {
              'word': 'garsonyera',
              'checked': true
            }
          ],
          'answer': '',
          'creator': 2,
          'student': 1,
          'created_at': '2021-12-06 07:56:45',
          'updated_at': '0000-00-00 00:00:00'
        },
        'posTagged': [
          {
            'sender': '',
            'receiver': '',
            'original': {
              'ugma': 7499
            },
            'content': 'Ugma',
            'id': 7499
          },
          ' ',
          {
            'sender': '',
            'receiver': '',
            'original': {
              'ka': 50919,
              'ikaw': 18674
            },
            'content': 'ka',
            'id': 50919
          },
          ' ',
          {
            'sender': '',
            'receiver': '',
            'original': {
              'hinlo': 9072
            },
            'content': 'manghinlo',
            'id': 9072
          },
          ' ',
          {
            'sender': '',
            'receiver': '',
            'original': {
              'sa': 18671,
              'andar': 5806
            },
            'content': 'sa',
            'id': 18671
          },
          ' ',
          {
            'sender': 0,
            'receiver': 0,
            'original': [],
            'content': 'garsonyera'
          },
          '?'
        ]
      },
      'myId': 1,
      'prev': '2021-09-19',
      'readVocabularyFrom': 'vocabulary',
      'selectedUser': 2,
      'vocabulary': {
        '5101': {
          'id_word': 5101,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5102': {
          'id_word': 5102,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5106': {
          'id_word': 5106,
          'mnemo': '',
          'rating': 999,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '5107': {
          'id_word': 5107,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5357': {
          'id_word': 5357,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5361': {
          'id_word': 5361,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5367': {
          'id_word': 5367,
          'mnemo': '',
          'rating': 20,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5368': {
          'id_word': 5368,
          'mnemo': '',
          'rating': 6,
          'flag': 'know',
          'ratingClass': 'L2'
        },
        '5373': {
          'id_word': 5373,
          'mnemo': '',
          'rating': 13,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5374': {
          'id_word': 5374,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5388': {
          'id_word': 5388,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5403': {
          'id_word': 5403,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5409': {
          'id_word': 5409,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5410': {
          'id_word': 5410,
          'mnemo': '',
          'rating': 20,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5412': {
          'id_word': 5412,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5416': {
          'id_word': 5416,
          'mnemo': '',
          'rating': 10,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5722': {
          'id_word': 5722,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5728': {
          'id_word': 5728,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '5730': {
          'id_word': 5730,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5814': {
          'id_word': 5814,
          'mnemo': 'pwede ka tabang nako',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5816': {
          'id_word': 5816,
          'mnemo': '',
          'rating': 20,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5827': {
          'id_word': 5827,
          'mnemo': '',
          'rating': 13,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5926': {
          'id_word': 5926,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5931': {
          'id_word': 5931,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5962': {
          'id_word': 5962,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5987': {
          'id_word': 5987,
          'mnemo': 'si udad u grad',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6053': {
          'id_word': 6053,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6057': {
          'id_word': 6057,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '6071': {
          'id_word': 6071,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6087': {
          'id_word': 6087,
          'mnemo': '',
          'rating': 8,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '6109': {
          'id_word': 6109,
          'mnemo': '',
          'rating': 11,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6203': {
          'id_word': 6203,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '6249': {
          'id_word': 6249,
          'mnemo': 'Thank you',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6471': {
          'id_word': 6471,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6481': {
          'id_word': 6481,
          'mnemo': '',
          'rating': 999,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '6542': {
          'id_word': 6542,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6554': {
          'id_word': 6554,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6599': {
          'id_word': 6599,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6800': {
          'id_word': 6800,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6821': {
          'id_word': 6821,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6874': {
          'id_word': 6874,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '7264': {
          'id_word': 7264,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '7385': {
          'id_word': 7385,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '7421': {
          'id_word': 7421,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '7499': {
          'id_word': 7499,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '8155': {
          'id_word': 8155,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '8891': {
          'id_word': 8891,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9018': {
          'id_word': 9018,
          'mnemo': '',
          'rating': 7,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9072': {
          'id_word': 9072,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9134': {
          'id_word': 9134,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9145': {
          'id_word': 9145,
          'mnemo': '',
          'rating': 12,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9964': {
          'id_word': 9964,
          'mnemo': 'masa ligon pa je samouven',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '10437': {
          'id_word': 10437,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '16795': {
          'id_word': 16795,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '16796': {
          'id_word': 16796,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '16798': {
          'id_word': 16798,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '17349': {
          'id_word': 17349,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '17667': {
          'id_word': 17667,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '17850': {
          'id_word': 17850,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18643': {
          'id_word': 18643,
          'mnemo': '',
          'rating': 11,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18645': {
          'id_word': 18645,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18671': {
          'id_word': 18671,
          'mnemo': ' layo ang siyudad',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '18672': {
          'id_word': 18672,
          'mnemo': '',
          'rating': 10,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18690': {
          'id_word': 18690,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18861': {
          'id_word': 18861,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '19210': {
          'id_word': 19210,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '19271': {
          'id_word': 19271,
          'mnemo': '',
          'rating': 5,
          'flag': 'learning',
          'ratingClass': 'L2'
        },
        '26335': {
          'id_word': 26335,
          'mnemo': '',
          'rating': 999,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '50919': {
          'id_word': 50919,
          'mnemo': '',
          'rating': 2,
          'flag': 'know',
          'ratingClass': 'L1'
        }
      }
    },
    withLink: {
      'correctionMode': 'test',
      'msg': {
        'id': 2405,
        'message': 'Mao nang mensahe sa link https://google.com . Duha ka numero 8 ug 9. ',
        'id_language': 3,
        'pos_tagged': '[{"original":{"mao":18767},"content":"Mao"}," ",{"original":{"kana":9134},"content":"nang"}," ",{"original":{"mensahe":6535},"content":"mensahe"}," ",{"original":{"sa":18671},"content":"sa"}," ",{"original":{"link":9585},"content":"link"}," ","https://google.com"," . ",{"original":{"duha":5402},"content":"Duha"}," ",{"original":{"ka":50919},"content":"ka"}," ",{"original":{"numero":5928},"content":"numero"}," ",8," ",{"original":{"ug":5102},"content":"ug"}," ",9,". "]',
        'created_at': '2024-01-05 22:56:21',
        'msg_read': 0,
        'msg_read_at': null,
        'sender': 1,
        'receiver': 2,
        'edited': 'no',
        'reply_to': 0,
        'reply_excerpt': '',
        'reactions': '',
        'bookmarked': false,
        'posTagged': [
          {
            'original': {
              'mao': 18767
            },
            'content': 'Mao',
            'id': 18767
          },
          ' ',
          {
            'original': {
              'kana': 9134
            },
            'content': 'nang',
            'id': 9134
          },
          ' ',
          {
            'original': {
              'mensahe': 6535
            },
            'content': 'mensahe',
            'id': 6535
          },
          ' ',
          {
            'original': {
              'sa': 18671
            },
            'content': 'sa',
            'id': 18671
          },
          ' ',
          {
            'original': {
              'link': 9585
            },
            'content': 'link',
            'id': 9585
          },
          ' ',
          'https://google.com',
          ' . ',
          {
            'original': {
              'duha': 5402
            },
            'content': 'Duha',
            'id': 5402
          },
          ' ',
          {
            'original': {
              'ka': 50919
            },
            'content': 'ka',
            'id': 50919
          },
          ' ',
          {
            'original': {
              'numero': 5928
            },
            'content': 'numero',
            'id': 5928
          },
          ' ',
          8,
          ' ',
          {
            'original': {
              'ug': 5102
            },
            'content': 'ug',
            'id': 5102
          },
          ' ',
          9,
          '. '
        ]
      },
      'myId': 1,
      'prev': '2023-09-20',
      'readVocabularyFrom': 'vocabulary',
      'selectedUser': 2,
      'vocabulary': {
        '5102': {
          'id_word': 5102,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5402': {
          'id_word': 5402,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '5928': {
          'id_word': 5928,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6535': {
          'id_word': 6535,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9134': {
          'id_word': 9134,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18671': {
          'id_word': 18671,
          'mnemo': ' layo ang siyudad',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '18767': {
          'id_word': 18767,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '50919': {
          'id_word': 50919,
          'mnemo': '',
          'rating': 2,
          'flag': 'know',
          'ratingClass': 'L1'
        }
      }
    },
    bookmark: {
      'correctionMode': 'test',
      'msg': {
        'id': 2392,
        'message': 'na ceb',
        'id_language': 3,
        'pos_tagged': '[{"original":{"kana":9134},"content":"na"}," ",{"original":[],"content":"ceb"}]',
        'created_at': '2023-09-20 22:01:37',
        'msg_read': 1,
        'msg_read_at': '2023-09-20 22:01:37',
        'sender': 2,
        'receiver': 1,
        'edited': 'no',
        'reply_to': 0,
        'reply_excerpt': '',
        'reactions': ',🤣,😁,🙏,✅',
        'bookmarked': true,
        'posTagged': [
          {
            'original': {
              'kana': 9134
            },
            'content': 'na',
            'id': 9134
          },
          ' ',
          {
            'original': [],
            'content': 'ceb'
          }
        ],
        'correction': {
          'messageId': 2392,
          'userId': 1,
          'studentId': 2,
          'correction': 'na ceb corrected',
          'correctionNote': 'Long explanation why this message was corrected.',
          'id': 348,
          'corrected': 'na ceb corrected',
          'message': 'na ceb',
          'correction_note': 'Long explanation why this message was corrected.'
        }
      },
      'myId': 1,
      'prev': '2023-09-20',
      'readVocabularyFrom': 'vocabulary',
      'selectedUser': 2,
      'vocabulary': {
        '5102': {
          'id_word': 5102,
          'mnemo': '',
          'rating': 8,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '5402': {
          'id_word': 5402,
          'mnemo': '',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '5928': {
          'id_word': 5928,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '6535': {
          'id_word': 6535,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '9134': {
          'id_word': 9134,
          'mnemo': '',
          'rating': 9,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '18671': {
          'id_word': 18671,
          'mnemo': ' layo ang siyudad',
          'rating': 11,
          'flag': 'know',
          'ratingClass': 'L3'
        },
        '18767': {
          'id_word': 18767,
          'mnemo': '',
          'rating': 14,
          'flag': 'learning',
          'ratingClass': 'L3'
        },
        '50919': {
          'id_word': 50919,
          'mnemo': '',
          'rating': 2,
          'flag': 'know',
          'ratingClass': 'L1'
        }
      }
    }
  }
}
