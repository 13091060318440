import * as _emojiLayouts from '@/helpers/layouts/_emoji'
import * as _internationalLayouts from '@/helpers/layouts/_international'
import * as albanianLayouts from '@/helpers/layouts/albanian'
import * as arabicLayouts from '@/helpers/layouts/arabic'
import * as armenianLayouts from '@/helpers/layouts/armenian'
import * as asmLayouts from '@/helpers/layouts/assamese'
import * as azeriLayouts from '@/helpers/layouts/azeri'
import * as balLayouts from '@/helpers/layouts/baluchi'
import * as bashkirLayouts from '@/helpers/layouts/bashkir'
import * as belLayouts from '@/helpers/layouts/belarusian'
import * as belgianLayouts from '@/helpers/layouts/belgian'
import * as bengaliLayouts from '@/helpers/layouts/bengali'
import * as bosnianLayouts from '@/helpers/layouts/bosnian'
import * as brazilianLayouts from '@/helpers/layouts/brazilian'
import * as bulgarianLayouts from '@/helpers/layouts/bulgarian'
import * as burmeseLayouts from '@/helpers/layouts/burmese'
import * as canadianLayouts from '@/helpers/layouts/canadian'
import * as catalanLayouts from '@/helpers/layouts/catalan'
import * as chineseLayouts from '@/helpers/layouts/chinese'
import * as czechLayouts from '@/helpers/layouts/czech'
import * as danishLayouts from '@/helpers/layouts/danish'
import * as deuLayouts from '@/helpers/layouts/deutchland'
import * as devanagariLayouts from '@/helpers/layouts/devanagari'
import * as divehiLayouts from '@/helpers/layouts/divehi'
import * as dutchLayouts from '@/helpers/layouts/dutch'
import * as engLayouts from '@/helpers/layouts/english'
import * as estonianLayouts from '@/helpers/layouts/estonian'
import * as ethiopicLayouts from '@/helpers/layouts/ethiopic'
import * as faeroeseLayouts from '@/helpers/layouts/faeroese'
import * as farsiLayouts from '@/helpers/layouts/farsi'
import * as finnishLayouts from '@/helpers/layouts/finnish'
import * as frenchLayouts from '@/helpers/layouts/french'
import * as gaelicLayouts from '@/helpers/layouts/gaelic'
import * as georgianLayouts from '@/helpers/layouts/georgian'
import * as gilakiLayouts from '@/helpers/layouts/gilaki'
import * as greekLayouts from '@/helpers/layouts/greek'
import * as greenlandicLayouts from '@/helpers/layouts/greenlandic'
import * as gujaratiLayouts from '@/helpers/layouts/gujarati'
import * as hausaLayouts from '@/helpers/layouts/hausa'
import * as hebrewLayouts from '@/helpers/layouts/hebrew'
import * as hindiLayouts from '@/helpers/layouts/hindi'
import * as hungarianLayouts from '@/helpers/layouts/hungarian'
import * as hungarianansiLayouts from '@/helpers/layouts/hungarianansi'
import * as icelandicLayouts from '@/helpers/layouts/icelandic'
import * as igboLayouts from '@/helpers/layouts/igbo'
import * as inuktitutLayouts from '@/helpers/layouts/inuktitut'
import * as irishLayouts from '@/helpers/layouts/irish'
import * as itaLayouts from '@/helpers/layouts/italian'
import * as japLayouts from '@/helpers/layouts/japanese'
import * as kannadaLayouts from '@/helpers/layouts/kannada'
import * as kazakhLayouts from '@/helpers/layouts/kazakh'
import * as khmerLayouts from '@/helpers/layouts/khmer'
import * as koreanLayouts from '@/helpers/layouts/korean'
import * as kurdishLayouts from '@/helpers/layouts/kurdish'
import * as kyrgyzLayouts from '@/helpers/layouts/kyrgyz'
import * as laoLayouts from '@/helpers/layouts/lao'
import * as latvianLayouts from '@/helpers/layouts/latvian'
import * as lithuanianLayouts from '@/helpers/layouts/lithuanian'
import * as luxembourgishLayouts from '@/helpers/layouts/luxembourgish'
import * as macedonianLayouts from '@/helpers/layouts/macedonian'
import * as malayalamLayouts from '@/helpers/layouts/malayalam'
import * as malteseLayouts from '@/helpers/layouts/maltese'
import * as maoriLayouts from '@/helpers/layouts/maori'
import * as marathiLayouts from '@/helpers/layouts/marathi'
import * as mongolianLayouts from '@/helpers/layouts/mongolian'
import * as nepaliLayouts from '@/helpers/layouts/nepali'
import * as nigerianLayouts from '@/helpers/layouts/nigerian'
import * as nkoLayouts from '@/helpers/layouts/nko'
import * as norwegianLayouts from '@/helpers/layouts/norwegian'
import * as odiaLayouts from '@/helpers/layouts/odia'
import * as oriyaLayouts from '@/helpers/layouts/oriya'
import * as pashtoLayouts from '@/helpers/layouts/pashto'
import * as persianLayouts from '@/helpers/layouts/persian'
import * as polishLayouts from '@/helpers/layouts/polish'
import * as portugueseLayouts from '@/helpers/layouts/portuguese'
import * as punjabiLayouts from '@/helpers/layouts/punjabi'
import * as romanianLayouts from '@/helpers/layouts/romanian'
import * as rusLayouts from '@/helpers/layouts/russian'
import * as russianOldLayouts from '@/helpers/layouts/russianOld'
import * as samiLayouts from '@/helpers/layouts/sami'
import * as srpLayouts from '@/helpers/layouts/serbian'
import * as setswanaLayouts from '@/helpers/layouts/setswana'
import * as sindhiLayouts from '@/helpers/layouts/sindhi'
import * as sinhalaLayouts from '@/helpers/layouts/sinhala'
import * as slovakLayouts from '@/helpers/layouts/slovak'
import * as slovenianLayouts from '@/helpers/layouts/slovenian'
import * as spaLayouts from '@/helpers/layouts/spanish'
import * as swedishLayouts from '@/helpers/layouts/swedish'
import * as syriacLayouts from '@/helpers/layouts/syriac'
import * as tajikLayouts from '@/helpers/layouts/tajik'
import * as tamilLayouts from '@/helpers/layouts/tamil'
import * as tatarLayouts from '@/helpers/layouts/tatar'
import * as teluguLayouts from '@/helpers/layouts/telugu'
import * as thaiLayouts from '@/helpers/layouts/thai'
import * as tibetanLayouts from '@/helpers/layouts/tibetan'
import * as turkishLayouts from '@/helpers/layouts/turkish'
import * as turkmenLayouts from '@/helpers/layouts/turkmen'
import * as ukrainianLayouts from '@/helpers/layouts/ukrainian'
import * as urduLayouts from '@/helpers/layouts/urdu'
import * as uyghurLayouts from '@/helpers/layouts/uyghur'
import * as uzbekLayouts from '@/helpers/layouts/uzbek'
import * as vietnameseLayouts from '@/helpers/layouts/vietnamese'
import * as wolofLayouts from '@/helpers/layouts/wolof'
import * as yakutLayouts from '@/helpers/layouts/yakut'
import * as yiddishLayouts from '@/helpers/layouts/yiddish'
import * as yorubaLayouts from '@/helpers/layouts/yoruba'

import { KeyboardLayout, KeyboardLayoutsObject } from '@/definitions/langoid'

export type LayoutNames =
  '_emoji'
  | '_international'
  | 'albanian'
  | 'arabic'
  | 'armenian'
  | 'assamese'
  | 'azeri'
  | 'baluchi'
  | 'bashkir'
  | 'belarussian'
  | 'belgian'
  | 'bengali'
  | 'bosnian'
  | 'brazilian'
  | 'bulgarian'
  | 'burmese'
  | 'canadian'
  | 'catalan'
  | 'chinese'
  | 'czech'
  | 'danish'
  | 'deutchland'
  | 'devanagari'
  | 'divehi'
  | 'dutch'
  | 'english'
  | 'estonian'
  | 'ethiopic'
  | 'faeroese'
  | 'farsi'
  | 'finnish'
  | 'french'
  | 'gaelic'
  | 'georgian'
  | 'gilaki'
  | 'greek'
  | 'greenlandic'
  | 'gujarati'
  | 'hausa'
  | 'hebrew'
  | 'hindi'
  | 'hungarian'
  | 'hungarianansi'
  | 'icelandic'
  | 'igbo'
  | 'inuktitut'
  | 'irish'
  | 'italian'
  | 'japanese'
  | 'kannada'
  | 'kazakh'
  | 'khmer'
  | 'korean'
  | 'kurdish'
  | 'kyrgyz'
  | 'lao'
  | 'latvian'
  | 'lithuanian'
  | 'luxembourgish'
  | 'macedonian'
  | 'malayalam'
  | 'maltese'
  | 'maori'
  | 'marathi'
  | 'mongolian'
  | 'nepali'
  | 'nigerian'
  | 'nko'
  | 'norwegian'
  | 'odia'
  | 'oriya'
  | 'pashto'
  | 'persian'
  | 'polish'
  | 'portuguese'
  | 'punjabi'
  | 'romanian'
  | 'russian'
  | 'russianOld'
  | 'sami'
  | 'setswana'
  | 'sindhi'
  | 'sinhala'
  | 'slovak'
  | 'slovenian'
  | 'spanish'
  | 'serbian'
  | 'swedish'
  | 'syriac'
  | 'tajik'
  | 'tamil'
  | 'tatar'
  | 'telugu'
  | 'thai'
  | 'tibetan'
  | 'turkish'
  | 'turkmen'
  | 'ukrainian'
  | 'urdu'
  | 'uyghur'
  | 'uzbek'
  | 'vietnamese'
  | 'wolof'
  | 'yakut'
  | 'yiddish'
  | 'yoruba'

export const allLayouts: Record<LayoutNames, {
  layout: KeyboardLayout,
  layouts: KeyboardLayoutsObject
}> = {
  _emoji: _emojiLayouts,
  _international: _internationalLayouts,
  albanian: albanianLayouts,
  arabic: arabicLayouts,
  armenian: armenianLayouts,
  assamese: asmLayouts,
  azeri: azeriLayouts,
  baluchi: balLayouts,
  bashkir: bashkirLayouts,
  belarussian: belLayouts,
  belgian: belgianLayouts,
  bengali: bengaliLayouts,
  bosnian: bosnianLayouts,
  brazilian: brazilianLayouts,
  bulgarian: bulgarianLayouts,
  burmese: burmeseLayouts,
  canadian: canadianLayouts,
  catalan: catalanLayouts,
  chinese: chineseLayouts,
  czech: czechLayouts,
  danish: danishLayouts,
  deutchland: deuLayouts,
  devanagari: devanagariLayouts,
  divehi: divehiLayouts,
  dutch: dutchLayouts,
  english: engLayouts,
  estonian: estonianLayouts,
  ethiopic: ethiopicLayouts,
  faeroese: faeroeseLayouts,
  farsi: farsiLayouts,
  finnish: finnishLayouts,
  french: frenchLayouts,
  gaelic: gaelicLayouts,
  georgian: georgianLayouts,
  gilaki: gilakiLayouts,
  greek: greekLayouts,
  greenlandic: greenlandicLayouts,
  gujarati: gujaratiLayouts,
  hausa: hausaLayouts,
  hebrew: hebrewLayouts,
  hindi: hindiLayouts,
  hungarian: hungarianLayouts,
  hungarianansi: hungarianansiLayouts,
  icelandic: icelandicLayouts,
  igbo: igboLayouts,
  inuktitut: inuktitutLayouts,
  irish: irishLayouts,
  italian: itaLayouts,
  japanese: japLayouts,
  kannada: kannadaLayouts,
  kazakh: kazakhLayouts,
  khmer: khmerLayouts,
  korean: koreanLayouts,
  kurdish: kurdishLayouts,
  kyrgyz: kyrgyzLayouts,
  lao: laoLayouts,
  latvian: latvianLayouts,
  lithuanian: lithuanianLayouts,
  luxembourgish: luxembourgishLayouts,
  macedonian: macedonianLayouts,
  malayalam: malayalamLayouts,
  maltese: malteseLayouts,
  maori: maoriLayouts,
  marathi: marathiLayouts,
  mongolian: mongolianLayouts,
  nepali: nepaliLayouts,
  nigerian: nigerianLayouts,
  nko: nkoLayouts,
  norwegian: norwegianLayouts,
  odia: odiaLayouts,
  oriya: oriyaLayouts,
  pashto: pashtoLayouts,
  persian: persianLayouts,
  polish: polishLayouts,
  portuguese: portugueseLayouts,
  punjabi: punjabiLayouts,
  romanian: romanianLayouts,
  russian: rusLayouts,
  russianOld: russianOldLayouts,
  sami: samiLayouts,
  serbian: srpLayouts,
  setswana: setswanaLayouts,
  sindhi: sindhiLayouts,
  sinhala: sinhalaLayouts,
  slovak: slovakLayouts,
  slovenian: slovenianLayouts,
  spanish: spaLayouts,
  swedish: swedishLayouts,
  syriac: syriacLayouts,
  tajik: tajikLayouts,
  tamil: tamilLayouts,
  tatar: tatarLayouts,
  telugu: teluguLayouts,
  thai: thaiLayouts,
  tibetan: tibetanLayouts,
  turkish: turkishLayouts,
  turkmen: turkmenLayouts,
  ukrainian: ukrainianLayouts,
  urdu: urduLayouts,
  uyghur: uyghurLayouts,
  uzbek: uzbekLayouts,
  vietnamese: vietnameseLayouts,
  wolof: wolofLayouts,
  yakut: yakutLayouts,
  yiddish: yiddishLayouts,
  yoruba: yorubaLayouts
}
