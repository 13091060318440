<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { link, useLocation } from 'svelte-routing'

  import { EXECUTION_TIME_HACK } from '@/config/constants'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'
  import { getHomeLink } from '@/helpers/mixHelpers'
  import { calculateLocationGroup } from '@/helpers/urlHelpers'

  import Icon from '@/components/icons/Icon.svelte'

  import { menuLanguage, userStorredConfig } from '@/store'

  let langLearning: string
  let path = window.location.pathname

  function active (path: string) {
    if (path.includes('/' + langLearning)) return 'home'
    if (path.includes('/missions')) return 'missions'
    if (path.includes('/profile')) return 'profile'
    if (path.includes('/social')) return 'social'
    return ''
  }

  const unsubscribe2 = userStorredConfig.subscribe(user => {
    const tmp = getIsoFromId(user.id_lang_learning, true)
    langLearning = tmp || ''
  })

  const locationStore = useLocation()

  let menuGroups: string[] = []
  let locationGroup = ''
  let show = true
  const bottomNavigation = (currentLocation: string) => {

    menuGroups = currentLocation.split('/')
    locationGroup = calculateLocationGroup(currentLocation, menuGroups)
    const numOfSegments = menuGroups.length - 1

    if (
      (locationGroup === 'chat' && numOfSegments > 2)
      || (locationGroup === 'lessons' && numOfSegments > 3)
      || locationGroup === 'home'
      || (locationGroup === 'exercise' && numOfSegments > 2)
      || (locationGroup === 'tests' && numOfSegments > 2)
      || locationGroup === 'listen'
      || locationGroup === 'words-explorer'
      || locationGroup === 'forum'
    ) {
      show = false
    } else {
      show = true
    }
    // console.log('mmmmmmmmmmmmm', locationGroup, numOfSegments, menuGroups)
  }
  const unsubscribe = locationStore.subscribe(routerData => {
    bottomNavigation(routerData.pathname)
    setTimeout(() => {
      bottomNavigation(routerData.pathname)
    }, EXECUTION_TIME_HACK * 2)
  })

  setTimeout(() => {
    bottomNavigation(location.pathname)
  }, EXECUTION_TIME_HACK)

  onDestroy(() => {
    unsubscribe()
    unsubscribe2()
  })
</script>

<div class='bottom-menu-holder' class:-hide={!show}>
  <div
    class='bottom-menu'
    role='button'
    tabindex='0'
    on:click={() => {path = location.pathname}}
    on:keydown={(e) => { if (e.key === 'Enter') path = location.pathname}}
  >
    <a class='link' class:-active={active(path) === 'home'} href={getHomeLink($menuLanguage, langLearning)} use:link>
      <Icon icon='Student' />
      {$_('menu.learning')}</a>
    <a class='link' class:-active={active(path) === 'missions'} href='/missions' use:link>
      <Icon icon='ListChecks' />
      {$_('menu.missions')}

      <!-- {#if $userStorredConfig.missions.length < 10}&nbsp;!!!{/if} -->
    </a>
    <a class='link' class:-active={active(path) === 'social'} href='/social' use:link>
      <Icon icon='UsersThree' />
      {$_('menu.forum')}</a>
  </div>
</div>
<div class='bottom-menu-background' class:-hide={!show}>
  <br />
</div>

<style lang='scss'>
  @media (min-width: 1024px) {
    .bottom-menu, .bottom-menu-holder, .bottom-menu-background {
      display: none !important;
    }
  }

  /* used to reserve space behind when bottom menu is shown */
  .bottom-menu-background {
    width: 100vw;
    height: 6rem;

    &.-hide {
      display: none;
    }
  }

  .bottom-menu-holder {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 90;
    height: 4.8rem;
    background: var(--main-background);
    box-shadow: var(--medium-box-shadow);

    &.-hide {
      display: none;
    }
  }

  .bottom-menu {
    display: flex;
    align-items: center;
    width: 100vw;
    max-width: 76.8rem;
    margin: auto;

    > .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      height: 4.8rem;
      padding: 0.6rem 0.2rem 0.2rem 0.2rem;
      font-size: 1rem;
      white-space: nowrap;
      text-align: center;
      color: var(--text-primary-color);
      border: none;

      &:hover,
      &:active,
      &:focus,
      &.-active {
        color: var(--Primary-Medium);
      }
    }
  }

  @media (max-width: 414px) {
    .bottom-menu > .link {
      font-size: 0.8rem;
    }
  }
</style>
