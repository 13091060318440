<script lang='ts'>
  // about:config -> media.navigator.permission.disabled -> true - for testing in localhost
  import { onMount } from 'svelte'
  import { link } from 'svelte-routing'

  import { API_URL, getCookie } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'

  export let src: string = '',
    url = '',
    params: any = {},
    onAfterSuccess = (data: any) => { },
    onStartRecording = () => { },
    changeState = (state: any) => { },
    successMessage: any = {},
    className = '',
    reload = false // to recreate component after recording is finished

  let blob: Blob
  let mediaRecorder: MediaRecorder
  let uploaded = false
  let recordingState = ''
  let uploadInProgress = false
  let audioSrc = ''

  const RELOADING_DELAY_TIME = 2000
  const RECORDING_DELAY_TIME = 200

  onMount(() => {
    audioRecorderInit()
  })

  const audioRecorderInit = () => {
    if (navigator.mediaDevices) {
      const constraints = { audio: true }
      let chunks: any = []

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          mediaRecorder = new MediaRecorder(stream)

          mediaRecorder.onstop = () => {
            blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
            chunks = []
            audioSrc = URL.createObjectURL(blob)
          }

          mediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data)
          }
        }).catch((error) => {
        console.error('The following error occurred: ' + error)
      })
    }
  }

  const fileUpload = (reqData: any) => {
    for (const i in params) {
      reqData.append(i, params[i])
    }
    reqData.append('src', src)
    reqData.append('file_upload', true)
    fetch(API_URL + url, {
      body: reqData,
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + getCookie('jwt')
      },
      method: 'POST'
    }).then((response) => {
      return response.json()
    }).then((data) => {
      if (data.error) {
        notifier.warning(data.error)
      } else {
        uploaded = true
        recordingState = ''
        onAfterSuccess(data)
        uploadInProgress = false
        notifier.success($_('testsPage.audioUploaded'))
        if (reload) {
          uploadInProgress = true
          setTimeout(() => {
            uploaded = false
            uploadInProgress = false
          }, RELOADING_DELAY_TIME)
        }
      }
    }).catch((error) => {
      console.error('file upload error', error)
    })
  }

  const handleClickOnStart = () => {
    onStartRecording()
    recordingState = 'inProgress'
    changeState('inProgress')
    setTimeout(() => {
      mediaRecorder?.start()
    }, RECORDING_DELAY_TIME)
  }

  const handleClickOnStop = () => {
    mediaRecorder?.stop()
    recordingState = 'stopped'
    changeState('stopped')
  }

  const handleClickOnDelete = () => {
    recordingState = ''
    changeState(false)
    uploaded = false
  }

  const handleClickOnUpload = () => {
    uploadInProgress = true
    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const fd = new FormData()
      if (typeof e.target?.result === 'string') {
        fd.append('audio', e.target.result)
        fileUpload(fd)
      }
    }

    if (blob) reader.readAsDataURL(blob)
  }
</script>
<div class='recorder-wrapper' class:-chat={className === 'chat'}>
  {#if !uploaded}
    <div class='audio-recorder -{className} -{recordingState}'>
      {#if recordingState === 'stopped'}
        <div class='audio-block _row -{className}'>
          <AudioPlayer src={audioSrc} variant='progress' />
          <button class='_warning-btn' type='button' on:click={handleClickOnDelete}>
            <Icon icon='Trash' />{$_('mix.delete')}</button>
          <button disabled={uploadInProgress} type='button' on:click={handleClickOnUpload}>{$_('mix.upload')}</button>
        </div>
      {:else}
        <div class='recording-control -{className} _row'>
          {#if recordingState === 'inProgress'}
            <button class='_primary-btn -inProgress' type='button' on:click={handleClickOnStop}>
              <slot name='stop' />
              <Icon icon='Stop' />
            </button>
          {:else}
            <button class='_primary-btn' type='button' on:click={handleClickOnStart}>
              <Icon icon='Microphone' weight='fill' />
              <slot name='start' />
            </button>
          {/if}
        </div>
      {/if}
    </div>
  {:else}

    {#if successMessage.type === 'uploadedTask'}
      <EmptyState title={$_('tasksPage.modalHeadline')} type='success'>
        <p>{$_('tasksPage.modalProfile')}&nbsp;
          <a href={successMessage.profileLink} use:link>{$_('tasksPage.modalLinkProfile')}</a>
        </p>
      </EmptyState>
    {:else}
      <EmptyState title={$_('recording.uploaded')} type='default' />
    {/if}
  {/if}
</div>
<style lang='scss'>
  .recorder-wrapper,
  .recorder-wrapper.-chat {
    > :global(.empty-state) {
      display: none;
    }
  }

  .audio-recorder {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
  }

  .recording-control {
    &.-chat > button {
      padding: 0.8rem;
      border: none;
    }

  }

  .audio-block {
    background: var(--main-background);

    &.-chat {
      position: absolute;
      top: -6rem;
      right: 0;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .audio-block {
      display: flex;
      flex-wrap: wrap;
      background: var(--main-background);

      > .player {
        margin: auto;
      }
    }
  }
</style>
