/* eslint-disable sort-keys */
export const TESTS = {
  UNKNOWN: 0,
  RADIO: 1,
  TRANSLATE_AUDIO: 3,
  TRANSLATE_WRITTEN: 4,
  AUDIO_SENTENCE: 6,
  TRANSLATE_SENTENCE: 7,
  CUSTOM_WORD: 8,
  CUSTOM_SENTENCE: 9
}

export const RATING = {
  COMPLETE_BLOCKOUT: 0,
  WRONG_BUT_REMEMBER_CORRECT: 1,
  WRONG_BUT_LOOKED_EASY: 2,
  CORRECT_BUT_VERY_HARD: 3,
  CORRECT_AFTER_THINKING: 4,
  CORRECT_EASY_AND_FAST: 5
}

export const MS_PER_SEC = 1000
export const SEC_PER_MIN = 60
export const SEC_PER_HOUR = 3600
export const MINUTES_PER_HOUR = 60
export const HOURS_PER_DAY = 24
export const DAYS_PER_MONTH = 30
export const DAYS_PER_YEAR = 365
export const REFERENT_YEAR = 1970

export const SEC_PER_DAY = SEC_PER_HOUR * HOURS_PER_DAY
export const MS_PER_DAY = SEC_PER_DAY * MS_PER_SEC
export const SEC_PER_MONTH = SEC_PER_DAY * DAYS_PER_MONTH
export const SEC_PER_YEAR = SEC_PER_DAY * DAYS_PER_YEAR

export const TWO_DIGIT_YEAR = 2

export const EXECUTION_TIME_HACK = 100
export const WORD_RATING_MARK_AS_KNOWN = 999
export const HTTP_STATUS_OK = 200
export const HTTP_STATUS_UNAUTHORIZED = 401
export const RESULTS_PER_PAGE = 10
export const PER_PAGE_RESULTS = 11
export const POS_WITH_TWO_ELEMENTS = 2
export const ISO_LENGTH = 3
export const MAX_BREADCRUMB_SLUG_LENGTH = 20
export const MAX_LINK_LENGTH = 50
export const MAX_TOPIC_IN_LIST_LENGTH = 200
export const MAX_NOTE_TITLE = 20
export const MAX_NOTES_LENGTH = 150
export const UI_VISIBLE_LANGUAGES = 2
export const MIN_NUMBER_OF_USER_LANGUAGES = 2
export const DOUBLE_DECIMAL_NUMBER = 2
export const MIN_REQUIRED_TEXT_LENGTH = 4

export const JWT_COOKIE_EXPIRATION_DAYS = 7

/* export const PORT = {
  443: 443,
  5555: 5555
} */
export const ALLOW_ARCH_DEL_ROLE = 4
export const ALLOW_ROLE_FOR_PREMIUM = 5
export const ALLOW_ARCH_DEL_EDIT_ROLE = 6
export const ALLOW_WRITE_LOCKED_POSTS = 8
export const ALLOW_ALL_ADMIN_LEVEL = 10

export const BATCH_OF_AUDIO_WORDS = {
  10: 10,
  20: 20,
  50: 50,
  100: 100,
  500: 500
}

export const AUDIO_PLAYER_SPEED = {
  '0.5x': 0.5,
  '0.8x': 0.8,
  '1x': 1,
  '1.25x': 1.25,
  '1.5x': 1.5,
  '1.75x': 1.75
}

export const PAUSE_BETWEEN_AUDIO_WORDS = {
  1: '0s',
  500: '0.5s',
  1000: '1s',
  1500: '1.5s',
  2000: '2s',
  3000: '3s',
  5000: '5s',
  10000: '10s',
  15000: '15s'
}

export const LANGUAGE = {
  'ENGLISH': 1,
  'SERBIAN': 2,
  'CEBUANO': 3,
  'ARABIC': 13,
  'HEBREW': 70,
}
export const RTL_LANGUAGES = [LANGUAGE.ARABIC, LANGUAGE.HEBREW]
