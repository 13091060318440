<script lang='ts'>
  import { _ } from '@/helpers/i18n'
</script>
<div class='_gap24'>
  <div class='plans-table'>

    <div class='premium-wrapper -basic'>
      <div class='feature-wrapper'>
        <h3 class='plansTitle'>{$_('plans.basic')}</h3>
        <div class='feature'>{$_('plans.free')}</div>
        <div class='feature'>{$_('plans.stats')}  {$_('plans.lastMonth')}</div>
        <div class='feature'>{$_('plans.testsLimitPerDay')} 1,3,5</div>
      </div>
    </div>

    <div class='premium-wrapper -advanced'>
      <div class='feature-wrapper'>
        <h3 class='plansTitle'>{$_('plans.advanced')}</h3>
        <div class='feature'>{$_('plans.planPrice', {
          values: {
            monthlyPrice: '$9',
            monthsSaved: 2,
            yearlyPrice: '$90'
          }
        })}
        </div>
        <div class='feature'>{$_('plans.stats')} {$_('plans.forever')}</div>
        <div class='feature'>{$_('plans.testsLimitPerDay')} 5,20,30</div>
        <!-- <div class='feature'>{$_('plans.guidedActivities')}</div> -->
        <div class='feature'>{$_('plans.interfaceOptions')}</div>
      </div>

      <a class='_primary-btn _cta-btn' href='#todo'>{$_('plans.buy')}</a>
    </div>

    <div class='premium-wrapper -expert'>
      <div class='feature-wrapper'>
        <h3 class='plansTitle'>{$_('plans.expert')}</h3>
        <div class='feature'>{$_('plans.planPrice', {
          values: {
            monthlyPrice: '$19',
            monthsSaved: 4,
            yearlyPrice: '$152'
          }
        })}</div>
        <div class='feature'>{$_('plans.stats')} {$_('plans.forever')}</div>
        <div class='feature'>{$_('plans.testsLimitPerDay')} {$_('plans.unlimited')}</div>
        <!-- <div class='feature'>{$_('plans.guidedActivities')}</div> -->
        <div class='feature'>{$_('plans.goalsSetup')}</div>
        <div class='feature'>{$_('plans.interfaceOptions')}</div>
        <div class='feature'>{$_('plans.qualificationTests')}</div>
        <div class='feature'>{$_('plans.vocabularyChecker')}</div>
      </div>
      <a class='_primary-btn _cta-btn' href='#todo'>{$_('plans.buy')}</a>
    </div>
  </div>
  <p class='endagered-message'>{$_('plans.freeForEndangered')}</p>
</div>
<style lang='scss'>
  .plans-table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  .premium-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.2rem;
    border: 1px solid var(--Gray-Medium);
    border-radius: 1rem;
  }

  .feature-wrapper {
    margin: 0.8rem 0;

    > .feature {
      padding: 0.8rem 3.2rem;
      background: url(/images/small/have.svg) no-repeat 1rem 50%;
    }
  }

  .endagered-message {
    font-style: italic;
    text-align: center;
  }
</style>
