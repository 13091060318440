/* eslint-disable sort-keys */
import type {LanguagesList} from '@/definitions/langoid'

export const languages: LanguagesList[] = [
  {id: 1, iso: 'eng', name: 'English', status: 2, original: 'English'},
  {id: 2, iso: 'srp', name: 'Serbian', status: 2, original: 'Srpski'},
  {id: 3, iso: 'ceb', name: 'Cebuano', status: 2},
  {id: 4, iso: 'tgl', name: 'Tagalog', status: 2},
  {id: 5, iso: 'spa', name: 'Spanish', status: 2},
  {id: 6, iso: 'cmn', name: 'Chinese', status: 2},
  {id: 7, iso: 'jpn', name: 'Japanese', status: 1},
  {id: 8, iso: 'kor', name: 'Korean', status: 1},
  {id: 9, iso: 'deu', name: 'German', status: 1},
  {id: 10, iso: 'fra', name: 'French', status: 1},
  {id: 11, iso: 'ita', name: 'Italian', status: 1},
  {id: 12, iso: 'rus', name: 'Russian', status: 2},
  {id: 13, iso: 'arb', name: 'Arabic', status: 2, dir: 'rtl'},
  {id: 14, iso: 'ben', name: 'Bengali', status: 1},
  {id: 15, iso: 'hin', name: 'Hindi', status: 1},
  {id: 16, iso: 'ell', name: 'Greek', status: 1},
  {id: 17, iso: 'por', name: 'Portuguese', status: 1},
  {id: 18, iso: 'vie', name: 'Vietnamese', status: 0},
  {id: 19, iso: 'tur', name: 'Turkish', status: 0},
  {id: 20, iso: 'ind', name: 'Indonesian', status: 2},
  {id: 21, iso: 'pol', name: 'Polish', status: 0},
  {id: 24, iso: 'bul', name: 'Bulgarian', status: 2},
  {id: 27, iso: 'ron', name: 'Romanian', status: 0},
  {id: 41, iso: 'ukr', name: 'Ukrainian', status: 2},
  {id: 44, iso: 'yor', name: 'Yoruba', status: 0},
  {id: 49, iso: 'nld', name: 'Dutch', status: 0},
  {id: 55, iso: 'ces', name: 'Czech', status: 2},
  {id: 70, iso: 'heb', name: 'Hebrew', status: 2, dir: 'rtl'},
  {id: 71, iso: 'slk', name: 'Slovak', status: 2},
  {id: 78, iso: 'hye', name: 'Armenian', status: 2},
  {id: 84, iso: 'bel', name: 'Belarusian', status: 2 },
  {id: 85, iso: 'slv', name: 'Slovenian', status: 2},
  {id: 86, iso: 'mkd', name: 'Macedonian', status: 2},
  {id: 23, iso: 'hrv', name: 'Croatian', status: 1},
  {id: 25, iso: 'hun', name: 'Hungarian', status: 1},
  {id: 26, iso: 'fin', name: 'Finish', status: 1},
  {id: 28, iso: 'jav', name: 'Javanese', status: 1},
  {id: 29, iso: 'tel', name: 'Telugu', status: 1},
  {id: 30, iso: 'mar', name: 'Marathi', status: 1},
  {id: 31, iso: 'tam', name: 'Tamil', status: 1},
  {id: 32, iso: 'pnb', name: 'Panjabi', status: 1},
  {id: 33, iso: 'guj', name: 'Gujarati', status: 1},
  {id: 34, iso: 'pes', name: 'Persian', status: 1},
  {id: 35, iso: 'bho', name: 'Bhojpuri', status: 1},
  {id: 36, iso: 'kan', name: 'Kannada', status: 1},
  {id: 37, iso: 'sun', name: 'Sundanese', status: 1},
  {id: 38, iso: 'mal', name: 'Malayalam', status: 1},
  {id: 39, iso: 'ory', name: 'Oriya', status: 1},
  {id: 40, iso: 'mya', name: 'Burmese', status: 1},
  {id: 43, iso: 'amh', name: 'Amharic', status: 1},
  {id: 47, iso: 'tha', name: 'Thai', status: 1},
  {id: 48, iso: 'uzn', name: 'Uzbek', status: 1},
  {id: 50, iso: 'sin', name: 'Sinhala', status: 1},
  {id: 51, iso: 'swh', name: 'Swahili', status: 1},
  {id: 52, iso: 'khm', name: 'Khmer', status: 1},
  {id: 53, iso: 'npi', name: 'Nepali', status: 1},
  {id: 54, iso: 'zul', name: 'Zulu', status: 1},
  {id: 56, iso: 'urd', name: 'Urdu', status: 1},
  {id: 58, iso: 'swe', name: 'Swedish', status: 1},
  {id: 59, iso: 'xho', name: 'Xhosa', status: 1},
  {id: 61, iso: 'plt', name: 'Malagasy', status: 1},
  {id: 62, iso: 'hat', name: 'Haitian', status: 1},
  {id: 63, iso: 'afr', name: 'Afrikaans', status: 1},
  {id: 64, iso: 'kin', name: 'Kinyarwanda', status: 1},
  {id: 65, iso: 'som', name: 'Somali', status: 1},
  {id: 66, iso: 'azj', name: 'Azerbaijani', status: 1},
  {id: 67, iso: 'pbt', name: 'Pashto', status: 1},
  {id: 68, iso: 'dan', name: 'Danish', status: 1},
  {id: 69, iso: 'kaz', name: 'Kazakh', status: 1},
  {id: 73, iso: 'nor', name: 'Norwegian', status: 1},
  {id: 75, iso: 'kat', name: 'Georgian', status: 1},
  {id: 76, iso: 'tuk', name: 'Turkmen', status: 1},
  {id: 77, iso: 'tgk', name: 'Tajik', status: 1},
  {id: 79, iso: 'lao', name: 'Lao', status: 1},
  {id: 80, iso: 'lit', name: 'Lithuanian', status: 1},
  {id: 81, iso: 'kir', name: 'Kirghiz', status: 1},
  {id: 82, iso: 'khk', name: 'Mongolian', status: 1},
  {id: 90, iso: 'hau', name: 'Hausa', status: 1},
  {id: 91, iso: 'snd', name: 'Sindhi', status: 1},
  {id: 92, iso: 'ibo', name: 'Igbo', status: 1},
  {id: 95, iso: 'uig', name: 'Uighur', status: 1},
  {id: 98, iso: 'tat', name: 'Tatar', status: 1},
  {id: 100, iso: 'wuu', name: 'Wu Chinese', status: 1},
  {id: 101, iso: 'yue', name: 'Yue Chinese', status: 1},
  {id: 102, iso: 'cjy', name: 'Jinyu Chinese', status: 1},
  {id: 104, iso: 'hak', name: 'Hakka Chinese', status: 1},
  {id: 105, iso: 'nan', name: 'Min Nan Chinese', status: 1},
  {id: 130, iso: 'cat', name: 'Catalan', status: 1},
  {id: 131, iso: 'ckb', name: 'Kurdish', status: 1},
  {id: 137, iso: 'glg', name: 'Galician', status: 1},
  {id: 139, iso: 'aln', name: 'Albanian', status: 1},
  {id: 170, iso: 'hil', name: 'Hiligaynon', status: 1},
  {id: 171, iso: 'ilo', name: 'Iloko', status: 1},
  {id: 172, iso: 'war', name: 'Waray', status: 1},
  {id: 1799, iso: 'ekk', name: 'Estonian', status: 1},
  {id: 3796, iso: 'lvs', name: 'Latvian', status: 1},
  {id: 4909, iso: 'nya', name: 'Nyanja', status: 1},
  {id: 5165, iso: 'pcm', name: 'Nigerian Pidgin', status: 1},
  {id: 5948, iso: 'sna', name: 'Shona', status: 1},
  {id: 12410, iso: 'hmn', name: 'Hmong', status: 1},
  {id: 17850, iso: 'zsm', name: 'Malay', status: 1},
].sort((a, b) => a.name.localeCompare(b.name)) as LanguagesList[]
