import { toast } from '@zerodevx/svelte-toast'
import { SvelteToastOptions } from '@zerodevx/svelte-toast/stores'

const dimensions: SvelteToastOptions['theme'] = {
  '--toastMinHeight': '4.8rem',
  '--toastWidth': '25.6rem'
}

const themes = {
  error: {
    '--toastBackground': '#fB4878',
    '--toastBarBackground': '#f52F5A', ...dimensions
  },
  info: {
    '--toastBackground': '#98bBff',
    '--toastBarBackground': '#789Bdf', ...dimensions
  },
  success: {
    '--toastBackground': '#48BB78',
    '--toastBarBackground': '#2F855A', ...dimensions
  },
  warning: {
    '--toastBackground': '#e8d444',
    '--toastBarBackground': '#d8c424', ...dimensions
  }
}
const defaultOptions: SvelteToastOptions = {
  dismissable: true,
  duration: 4000,
  initial: 1,
  next: 0,
  pausable: true,
  reversed: false
}
export const notifier = {
  error: (message: string, opts: SvelteToastOptions = {}) => {
    toast.push(message, {
      ...defaultOptions,
      theme: themes.error, ...opts
    })
  },
  info: (message: string, opts: SvelteToastOptions = {}) => {
    toast.push(message, {
      ...defaultOptions,
      theme: themes.info, ...opts
    })
  },
  success: (message: string, opts: SvelteToastOptions = {}) => {
    toast.push(message, {
      ...defaultOptions,
      theme: themes.success, ...opts
    })
  },
  warning: (message: string, opts: SvelteToastOptions = {}) => {
    toast.push(message, {
      ...defaultOptions,
      theme: themes.warning, ...opts
    })
  }
}
