// noinspection NonAsciiCharacters
/* eslint-disable sort-keys */

import { LessonWord } from '@/definitions/langoid'

export const lat2cyr: Record<string, string> = {
  a: 'а',
  b: 'б',
  c: 'ц',
  d: 'д',
  e: 'е',
  f: 'ф',
  g: 'г',
  h: 'х',
  i: 'и',
  j: 'ј',
  k: 'к',
  l: 'л',
  m: 'м',
  n: 'н',
  o: 'о',
  p: 'п',
  q: '',
  r: 'р',
  s: 'с',
  t: 'т',
  u: 'у',
  v: 'в',
  z: 'з',
  A: 'А',
  B: 'Б',
  C: 'Ц',
  D: 'Д',
  E: 'Е',
  F: 'Ф',
  G: 'Г',
  H: 'Х',
  I: 'И',
  J: 'Ј',
  K: 'К',
  L: 'Л',
  M: 'М',
  N: 'Н',
  O: 'О',
  P: 'П',
  Q: '',
  R: 'Р',
  S: 'С',
  T: 'Т',
  U: 'У',
  V: 'В',
  Z: 'З',
  č: 'ч',
  ć: 'ћ',
  đ: 'ђ',
  ž: 'ж',
  š: 'ш',
  Č: 'Ч',
  Ć: 'Ћ',
  Đ: 'Ђ',
  Ž: 'Ж',
  Š: 'Ш',
  lj: 'љ',
  nj: 'њ',
  dž: 'џ',
  Lj: 'Љ',
  Nj: 'Њ',
  Dž: 'Џ'

}

export const cyr2lat = {
  а: 'a',
  б: 'b',
  ц: 'c',
  д: 'd',
  е: 'e',
  ф: 'f',
  г: 'g',
  х: 'h',
  и: 'i',
  ј: 'j',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  в: 'v',
  з: 'z',
  А: 'A',
  Б: 'B',
  Ц: 'C',
  Д: 'D',
  Е: 'E',
  Ф: 'F',
  Г: 'G',
  Х: 'H',
  И: 'I',
  Ј: 'J',
  К: 'K',
  Л: 'L',
  М: 'M',
  Н: 'N',
  О: 'O',
  П: 'P',
  Р: 'R',
  С: 'S',
  Т: 'T',
  У: 'U',
  В: 'V',
  З: 'Z',
  ч: 'č',
  ћ: 'ć',
  ж: 'ž',
  ш: 'š',
  љ: 'lj',
  њ: 'nj',
  ђ: 'đ',
  џ: 'dz',
  Ч: 'Č',
  Ћ: 'Ć',
  Ж: 'Ž',
  Ш: 'Š',
  Љ: 'Lj',
  Њ: 'Nj',
  Ђ: 'Đ',
  Џ: 'Dž'
}

export const romanize = (word: string, lang: string, script: string = 'lat') => {
  if (!word) return ''
  if (lang === 'srp') {
    const fun: Record<string, string> = script === 'cyr' ? lat2cyr : cyr2lat
    let ret = ''
    let i = 0
    while (i < word.length) {
      let letter = word[i]
      if (i < word.length - 1) {
        const combination = word[i] + word[i + 1]
        if (fun[combination]) {
          letter = combination
          i++
        }
      }

      ret += fun[letter] || word[i]
      i++
    }
    return ret
  }

  return word
}

export function romanization (word: LessonWord, iso: string) {
  if (!word) return ''
  if (iso === 'srp') {
    return romanize(word.content, 'srp', 'cyr')
  }
  if (word.romanization) {
    return word.romanization
  }
  return ''
}
