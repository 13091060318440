<script lang='ts'>
  import { links } from 'svelte-routing'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'

  import { userStorredConfig } from '@/store'

  export let pageName = '',
    kebabItem = false // If we want to have Report icon with text (example: for mobile view inside kebab select list) we have to pass this paramether from parent component

  let iso: string = 'eng'
  userStorredConfig.subscribe((value) => {
    iso = getIsoFromId(value.id_lang_learning)
  })

</script>

<div class='info-icon' use:links>
  {#if pageName === 'lessonPage'}
    <a href='/{iso}/t/lessons/519' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'levelPage'}
    <a href='/{iso}/t/lessons/519' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'listenSetup'}
    <a href='/{iso}/t/listen/520' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'listenPage'}
    <a href='/{iso}/t/listen/520' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'missions'}
    <a href='/{iso}/t/missions/521' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'goals'}
    <a href='/{iso}/t/goals/522' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'reading'}
    <a href='/{iso}/t/reading-tutorial/523' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'tests'}
    <a href='/{iso}/t/tests/524' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'exercises'}
    <a href='/{iso}/t/exercises/525' rel='noopener noreferrer' target='_blank'>
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'searchUsers'}
    <a href='/' rel='noopener noreferrer' target='_blank'> <!-- todo: Add new topic for this page -->
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'stats'}
    <a href='/' rel='noopener noreferrer' target='_blank'> <!-- todo: Add new topic for this page -->
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'chat'}
    <a href='/' rel='noopener noreferrer' target='_blank'> <!-- todo: Add new topic for this page -->
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'rangList'}
    <a href='/' rel='noopener noreferrer' target='_blank'> <!-- todo: Add new topic for this page -->
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else if pageName === 'vocabulary'}
    <a href='/' rel='noopener noreferrer' target='_blank'> <!-- todo: Add new topic for this page -->
      <span class='_icon -noBorder'><Icon icon='Question' weight='regular' /></span>
      <span class='_mobileOnly'>{kebabItem ? $_('activity.pageInfo') : ''}</span>
    </a>
  {:else}
    Missing: {pageName}
  {/if}
</div>

<style lang='scss'>
  @media(max-width: 768px) {
    .info-icon {
      width: 100%;

      > a {
        display: flex;
        align-items: center;
        width: 100%;
        color: var(--text-primary-color);
      }
    }
  }
</style>
