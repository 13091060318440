<script lang='ts'>
  export let role = 'button',
    element = 'span',
    tabindex = 0,
    title = '',
    onClick = (e: MouseEvent) => { },
    onKeyPress = (e: KeyboardEvent) => { }
</script>
<svelte:element
  this={element}
  class='fake-button'
  {role}
  {tabindex}
  {title}
  on:click={onClick}
  on:keypress={onKeyPress}
  style:cursor='pointer'
  {...$$restProps}
>
  <slot />
</svelte:element>
<style>
  .fake-button {
    display: flex;
    gap: 0.4rem;
    align-content: center;
    width: max-content;
    height: fit-content;

    &:hover {
      color: var(--text-secondary-color);
    }
  }
</style>
