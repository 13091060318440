<script lang='ts'>
  import { onMount } from 'svelte'

  import { AUDIO_PLAYER_SPEED } from '@/config/constants'

  import { _ } from '@/helpers/i18n'
  import { clickOutside } from '@/helpers/mixHelpers'

  import CloseButton from '@/components/ui/CloseButton.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  import { playbackRate } from '@/store'

  let selectedSpeed: number = 1
  let hideOptions = true

  const setValue = (e: Event) => {
    const target = e.target as HTMLInputElement
    playbackRate.set(Number(target.value))
    hideOptions = true
  }

  const showOptionsMenu = () => {
    hideOptions = !hideOptions
  }

  onMount(() => {
    selectedSpeed = $playbackRate
  })
</script>
<FakeButton onClick={showOptionsMenu}>
  <span class='_icon' class:-active={!hideOptions}>{selectedSpeed}x</span>
</FakeButton>
<div class='overlay-window' class:-show={!hideOptions} />
<div class='option-menu' class:-hide={hideOptions} on:click_outside={() => {hideOptions = true}} use:clickOutside>
  <div class='option-header'>
    <h5>{$_('listensetSpeed')}</h5>
    <CloseButton onClick={() => {hideOptions = true}} />
  </div>
  <div class='custom-radios-square'>
    {#each Object.entries(AUDIO_PLAYER_SPEED) as [text, speed]}
      <label>
        <input type='radio' value={+speed} bind:group={selectedSpeed} on:change={setValue} name='speed' />
        <span>{text}</span>
      </label>
    {/each}
  </div>
</div>

<style lang='scss'>
  ._icon {
    width: fit-content;

    &.-active {
      background-color: var(--text-background);
    }
  }

  .overlay-window {
    display: none;
  }

  .option-menu {
    position: absolute;
    top: 6rem;
    right: 12rem;
    z-index: 20;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 37.4rem;
    padding: 1.6rem;
    background-color: var(--main-background);
    border: 0.1rem solid var(--Gray-Light);
    border-radius: 1.2rem;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03);
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08);

    &.-hide {
      display: none;
    }
  }

  .option-header {
    display: flex;
    justify-content: space-between;
    font: var(--Medium-300);

    > :global(.close-button svg) {
      width: 1.6rem;
      height: 1.6rem;
      color: var(--text-primary-color);
    }
  }

  @media (max-width: 768px) {
    .overlay-window {
      &.-show {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 90;
        display: flex;
        width: 100vw;
        height: 100vh;
        background: rgba(99, 99, 99, 0.5);
      }
    }

    .option-menu {
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 90;
      width: 31rem;
      transform: translate(-50%, -50%);
    }

    .custom-radios-square > label {
      > span {
        padding: 0.4rem 0.8rem;
      }
    }
  }
</style>
