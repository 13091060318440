<script lang='ts'>
  import { ListenWordsSelectData } from '@/definitions/langoid'

  export let returnData: ListenWordsSelectData, type: string[]
</script>
<div class='listen-words-sentences _vertical-small'>
  {#each returnData.legend as { name, className, count, translatedName }}
    {@const id = `id_${className}`}
    <div class='each-option'>
      <label class='check-button -{className.toLowerCase()}' for={id}>
        <input {id} type='checkbox' value={name} bind:group={type} name='type[{className}]' />
        <span class='checkbox -{className.toLowerCase()}' />
      </label>
      <label class='level -{className.toLowerCase()}' for={id}>{translatedName}<span class='wordsNumber'>{count}</span></label>
    </div>

  {/each}
</div>
<style lang='scss'>
  .each-option {
    display: flex;
    gap: 3.6rem;

    > label {
      position: relative;
      font: var(--Medium-400);
      background: transparent;

      > .wordsNumber {
        margin-left: 0.4rem;
        padding: 0.2rem 0.4rem;
        font: var(--Semibold-200);
        border-radius: 0.8rem;
      }

      &.-l0 {
        color: var(--Yellow-Medium);

        > .wordsNumber {
          background-color: var(--Warning-Lighter);
        }
      }

      &.-l1 {
        color: var(--Rose-Medium);

        > .wordsNumber {
          background-color: var(--Error-Lighter);
        }
      }

      &.-l2 {
        color: var(--Cyan-Medium);

        > .wordsNumber {
          background-color: var(--Primary-Ligther);
        }
      }

      &.-l3 {
        color: var(--Green-Medium);

        > .wordsNumber {
          background-color: var(--Success-Lighter);
        }
      }
    }
  }

  @media (max-width: 768px) {
    .listen-words-sentences {
      grid-template-columns: repeat(2, 6rem) auto;
    }
  }
</style>
