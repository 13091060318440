<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import { link } from 'svelte-routing'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { countChatMessages } from '@/helpers/chatHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { loadFeatures } from '@/helpers/loadFeatures'
  import { clickOutside, getHomeLink } from '@/helpers/mixHelpers'

  import Notifications from '@/components/menu/Notifications.svelte'
  import ProfileMenu from '@/components/menu/ProfileMenu.svelte'
  import InitialSetup from '@/components/missions/InitialSetup.svelte'
  import Avatar from '@/components/social/Avatar.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'

  import Icon from '../icons/Icon.svelte'
  import LearningLanguageSwitcher from './LearningLanguageSwitcher.svelte'

  import { UserData } from '@/definitions/langoid'
  import { MixedStoreData } from '@/definitions/stores'
  import { initI18n } from '@/libs/i18n'
  import { goalsStore, menuLanguage, mixStore, userStorredConfig } from '@/store'

  export const url = ''

  const body = document.querySelector('body')
  const dir: string | null = document.body.getAttribute('dir') || 'ltr'
  const TIME_UNTIL_URL_CHANGE = 1000

  let userData: UserData = {} as UserData,
    notificationsCount = 0,
    messagesCount = 0,
    megamenu: HTMLElement,
    showInitialSetup = false,
    showNotification = false,
    domInterval: any = 0, // not to call server again if we are logged out
    langLearning: string = '',
    initialDisplay = 'none' // for CLS score

  const unsubscribe = userStorredConfig.subscribe(async user => {
    if (!user.id) {
      reloadMenu()
    } else {
      const tmp = getIsoFromId(user.id_lang_learning, true)
      langLearning = tmp || ''
      menuLanguage.set(langLearning)
      const langInterface = getIsoFromId(user.id_lang_interface, true)
      await initI18n(langInterface,'menu')
      await loadFeatures(langLearning)
    }
  })

  mixStore.subscribe((mixStoreData: MixedStoreData) => {
    messagesCount = countChatMessages(mixStoreData.messagesCounts)
    notificationsCount = mixStoreData.notificationsCount
  })

  const reloadInterval = 6e4 // 1 minute
  const lsReloadKey = 'lastReload'

  // ensure  if 2 tabs are open, just one will do refresh
  const tryReloadMenu = () => {
    const lastReload = Number(localStorage.getItem(lsReloadKey))
    const now = Date.now()

    // if page is not visible, do not attempt to reload
    if (document.visibilityState !== 'visible') {
      return
    }

    if (!lastReload || now - lastReload >= reloadInterval) {
      reloadMenu()
      localStorage.setItem(lsReloadKey, now.toString())
    }
  }

  if (!showInitialSetup) {
    tryReloadMenu()
    domInterval = setInterval(tryReloadMenu, reloadInterval)
  }

  async function reloadMenu (): Promise<void> {
    try {
      const data = await fetchData('main/menu', {})
      if (!data.id) {
        clearInterval(domInterval as any)
        if (!location.href.includes('login')) {
          setTimeout(() => {
            location.href = '/login'
          }, TIME_UNTIL_URL_CHANGE)
        }
        return
      }
      if (data.languages.length === 0) { // if user has no languages, redirect to language selection
        showInitialSetup = true
        if (body) {
          body.style.overflow = 'hidden'
        }

        return
      }
      userData = data
      langLearning = getIsoFromId(userData.id_lang_learning)
      const currentUserData = get(userStorredConfig)
      if (JSON.stringify(currentUserData) !== JSON.stringify(data)) {
        userStorredConfig.set(userData)
        goalsStore.set(data.goals)
      }
      mixStore.setKeys({
        messagesCounts: data.messagesCounts,
        notificationsCount: data.notificationsCount
      })
    } catch (e) {
      console.error('Skip menu reload', e)
    }
  }

  const mainMenu = (dir: string): void => {
    if (!megamenu) return
    if (dir === 'open') {
      megamenu.classList.remove('-closed')
      megamenu.classList.add('-opened')
    } else {
      initialDisplay = 'block'
      megamenu.classList.add('-closed')
      megamenu.classList.remove('-opened')
    }
  }

  const handleMainMenuClick = (e: MouseEvent | KeyboardEvent): void => {
    const target = e.target as HTMLLinkElement
    if (target.tagName === 'A') {
      mainMenu('close')
    }
  }

  const saveInitialSetupAnswers = async (answers: any): Promise<void> => {
    await fetchData('main/saveInitialSetup', { answers })
  }

  const finishInitialSetup = async (answers: any): Promise<void> => {
    await saveInitialSetupAnswers({
      interfaceLanguage: answers[5] === 'yes' ? answers[1] : answers[5],
      knowTargetLanguage: answers[3],
      knowToRead: answers[4],
      native: answers[1],
      otherLanguages: answers[6] === 'no' ? [] : Object.values(answers[6]).map((language: any) => ({
        id: language.lang,
        level: language.level
      })),
      target: answers[2]
    })
    showInitialSetup = false
    if (body) body.style.overflow = 'auto'
    reloadMenu()
  }

  let lastClick = Date.now()
  const toggleNotifications = () => {
    if (Date.now() - lastClick < 500) {
      return
    }
    lastClick = Date.now()
    showNotification = !showNotification
  }

  onDestroy(() => {unsubscribe()})
</script>
{#if showInitialSetup}
  <InitialSetup onFinish={finishInitialSetup} />
{:else}
  <header class='main-header' {dir}>
    <div class='header-wrapper'>
      <div class='logo-wrapper'>
        <a class='main-logo' href={getHomeLink($menuLanguage, langLearning)} use:link>
          <span class='desktopLogo'><img alt='Langoid' src='/images/logo-main.svg' width='100' /></span>
          <span class='mobileLogo'><img alt='Langoid' src='/images/icons/go-small.svg' width='40' /></span>
        </a>
        <div class='logo-separator' />
        <div class='navItem -flag'>
          {#key langLearning}
            <LearningLanguageSwitcher bind:currentIso={langLearning} />
          {/key}
        </div>
      </div>
      {#if $userStorredConfig.id}
        <div class='header-right'>
          <a class='navItem _mobileOnly' href='/{langLearning}/chat' title={$_('menu.messages')} use:link>
            <Icon icon='ChatsCircle' size={28} weight='regular' />
            <span class='counterIcon' class:-hasNotification={messagesCount > 0}>9</span>
          </a>
          <div
            class='navItem'
            role='button'
            tabindex='0'
            title={$_('menu.notifications')}
            on:click={toggleNotifications}
            on:keypress={() => {}}
          >
            <Icon icon='Bell' weight='regular' />
            <span class='counterIcon' class:-hasNotifications={notificationsCount > 0}>{notificationsCount > 0 ? notificationsCount : ''}</span>
          </div>
          {#if showNotification}
            <Notifications {toggleNotifications} />
          {/if}
          <div class='points-block' title={`${$_('menu.points')}: ${$userStorredConfig.points} ${$_('menu.level')}: ${$userStorredConfig.level}`}>
            <span class='iconHolder'><Icon icon='ShieldStar' /></span>
            <span class='pointsText'>{$userStorredConfig.points}</span>
          </div>
          <div
            class='navItem -open '
            role='button'
            tabindex='0'
            title={$_('menu.menu')}
            on:click={() => { mainMenu('open') }}
            on:keypress={() => { mainMenu('open') }}
          >
            <Avatar avatarVariant='menuAvatar' img={$userStorredConfig.avatar} userName={$userStorredConfig.name} />
          </div>
        </div>
      {/if}
    </div>
  </header>
  <div
    bind:this={megamenu}
    class='main-menu -closed'
    role='button'
    tabindex='0'
    on:click={handleMainMenuClick}
    on:keypress={handleMainMenuClick}
    on:click_outside={() => mainMenu('close')}
    style:display={initialDisplay}
    use:clickOutside
  >

    <CloseButton closeVariant='mainManu' onClick='{() => { mainMenu("close") }}' />
    <ProfileMenu />
  </div>
{/if}
<style lang='scss'>
  .main-header {
    background: var(--main-background);
    box-shadow: var(--Shadow-Z);
  }

  .logo-separator {
    width: 0.2rem;
    height: 3.2rem;
    background: var(--Gray-Light);
    border-radius: 0.2rem;
  }

  .logo-wrapper {
    display: flex;
    gap: 2.4rem;
    align-items: center;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    width: 120rem;
    max-width: 100vw;
    margin: 0 auto;
    padding: 1.6rem;
  }

  .header-right {
    position: relative;
    display: flex;
    gap: 2.4rem;
    align-items: center;

    > .navItem {
      position: relative;
      color: var(--text-color);

      &.-open {
        height: 4rem;
        cursor: pointer;
      }

      > .counterIcon {
        position: absolute;
        bottom: 0rem;
        left: -0.3rem;
        display: flex;
        align-content: center;
        justify-content: center;
        min-width: 1.4rem;
        height: 1.4rem;
        padding: 0 0.2rem;
        font-size: 1rem;
        color: var(--inverted-text-color);
        background: var(--color-error);
        border-radius: 50%;

        &:empty {
          display: none;
        }
      }
    }
  }

  .main-header[dir='rtl'] {

    .navItem.-open {
      margin-right: 2.4rem;
      cursor: pointer;
    }
  }

  .points-block {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    padding: 0.8rem 1.2rem 0.8rem 0.8rem;
    line-height: 1;
    color: var(--accent-text);
    background-color: var(--accent-background);
    border-radius: 2.4rem;

    > .pointsText {
      font: var(--Semibold-400);
      text-align: center;
      color: var(--accent-text);
    }
  }

  .main-logo {
    position: relative;
    line-height: 1;

    > .mobileLogo {
      display: none;
    }
  }

  .main-menu {
    position: fixed;
    top: 0;
    right: -30rem;
    bottom: 0;
    z-index: 80;
    overflow: auto;
    width: 30rem;
    font-size: 1.4rem;
    background: var(--Primary-Medium);
  }

  :global(.-opened) {
    animation: 0.2s cubic-bezier(0.25, 1, 0.3, 1) transitionIn2 both;
  }

  :global(.-closed),
  .-closed {
    animation: 0.2s cubic-bezier(0.25, 1, 0.3, 1) transitionOut2 both;
  }

  @keyframes transitionIn2 {
    from {
      right: -31rem;
    }

    to {
      right: 0;
    }
  }

  @keyframes transitionOut2 {
    from {
      right: 0;
    }

    to {
      right: -31rem;
    }
  }

  @media (max-width: 768px) {
    .header-wrapper {
      width: 100%;
      height: 6.4rem;
    }

    .header-right {
      gap: 0.8rem;

      > .navItem {
        &.-open {
          margin-right: 0;
          padding-top: 0;
        }
      }
    }

    .main-logo {
      > .desktopLogo {
        display: none;
      }

      > .mobileLogo {
        display: block;
        margin-top: 0.4rem;
        margin-right: 0.4rem;
      }
    }

    .logo-separator {
      display: none;
    }

  }

  @media (max-width: 414px) {
    .points-block > .iconHolder {
      display: none;
    }

    .logo-wrapper {
      gap: 0.8rem;
    }
  }

</style>
