<script lang='ts'>
  import { link } from 'svelte-routing'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'

  import LangoidSocialLinks from '@/components/LangoidSocialLinks.svelte'

  import { userStorredConfig } from '@/store'

  const iso = getIsoFromId($userStorredConfig.id_lang_interface)

</script>
<footer>
  <div class='footer-container'>
    <div class='main-links'>
      <div class='links-section'>
        <a href={`/${iso}/forum`} use:link>{$_('forum.forum')}</a>
      </div>
      <div class='links-section'>
        <LangoidSocialLinks />
      </div>
    </div>
    <div class='footer-links'>
      <a href='/static/about-us.html'>About us</a>
      <a href='/static/contact-us.html'>Contact us</a>
      <a href='/static/privacy-policy.html'>{$_('home.privacyPolicy')}</a>
      <a href='/static/terms.html'>Terms</a>
      <a href='/static/cookie-policy.html'>Cookie policy</a>
    </div>
    <small>{$_('home.copyright', {
      values: { year: (new Date()).getFullYear() }
    })}</small>
  </div>
</footer>

<style lang='scss'>
  footer {
    padding: 2rem 0 1rem 0;
  }

  .footer-container {
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;

    > small {
      display: block;
      margin: 2.8rem 0;
      padding-top: 2rem;
      text-align: center;
    }
  }

  .main-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .links-section {
    display: flex;
    flex-direction: column;

    > a {
      margin: 1rem 0;
    }

    &:last-child {
      display: flex;
      flex-direction: row;
      gap: 0.4rem;
      align-items: center;
    }
  }

  .footer-links {
    display: flex;
    gap: 1.2rem;
    padding: 1.2rem 0;
    border: 0.1rem solid var(--Gray-Light);
    border-right: 0;
    border-left: 0;

    > a {
      font-size: 1.2rem;
      color: var(--Gray-Dark);
    }
  }

  @media (max-width: 768px) {
    .main-links {
      display: grid;
      grid-template-columns: repeat(2, 50%);
    }
  }
</style>
