<script lang='ts'>

  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  export let headers: string[] = []
  export let rows: Record<string, unknown>[] = []
  export let rowHeaders = false
  export let sort = true
  export let caption = ''

  const originalRows: any[] = rows.map(d => Object.values(d))

  let sortBy = -1
  let sortedRows = [...originalRows]
  const sortStatus: any = []
  type SortStatus = 'none' | 'ascending' | 'descending'
  let sortDirection: SortStatus = 'ascending'

  function updateSortStatus (header: any) {
    headers.forEach((d: any) => {
      sortStatus[d] = 'none'
    })
    sortDirection = (sortDirection === 'ascending') ? 'descending' : 'ascending'
    sortStatus[header] = sortDirection
    sortBy = headers.indexOf(header)
  }

  $: if (sortBy !== -1) {
    if (sortDirection === 'ascending') {
      sortedRows = [...originalRows].sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : ((b[sortBy] > a[sortBy]) ? -1 : 0))
    } else {
      sortedRows = [...originalRows].sort((a, b) => (a[sortBy] > b[sortBy]) ? -1 : ((b[sortBy] > a[sortBy]) ? 1 : 0))
    }
  } else {
    sortedRows = [...originalRows]
  }
</script>

<div class='responsive-table'>
  <table>
    {#if caption}
      <caption>{caption}</caption>
    {/if}
    <tbody>
    <tr>
      {#each headers as header, i (header)}
        <th
          class='sort-control'
          class:-selected={i === sortBy}
          aria-sort={sortStatus[header]}
          role='columnheader'
          scope='col'
        >
          {#if sort}
            <FakeButton
              onClick='{() => updateSortStatus(header)}'
            >
              {header}
              {#if i === sortBy}
                <Icon
                  icon={sortStatus[header]==='ascending'?'ArrowUp':'ArrowDown'}
                  size={16}
                  weight='fill'
                />
              {/if}
            </FakeButton>
          {:else}
            {header}
          {/if}
        </th>
      {/each}
    </tr>

    {#each sortedRows as row (row)}
      <tr>
        {#each row as column, i}
          {#if rowHeaders && i === 0}
            <th scope='row'>{column}</th>
          {:else}
            <td>{column}</td>
          {/if}
        {/each}
      </tr>
    {/each}
    </tbody>
  </table>
</div>
<style lang='scss'>
  table {
    width: 100%;
    margin-bottom: 1rem;
    table-layout: fixed;
    border-collapse: collapse;
  }

  table > caption,
  td,
  th {
    text-align: left;
  }

  caption {
    font-weight: bold;
    font-size: 1.2em;
  }

  td,
  th {
    padding: 0.4rem;
    vertical-align: top;
    word-wrap: break-word;
    border: none;
    border-bottom: 1px solid var(--Gray-Light);
  }

  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .sort-control {
    &.-selected {
      background-color: var(--Gray-Light)
    }

    > :global(span) {
      display: flex;
      width: 100% !important;
      cursor: pointer;
    }
  }

  tr:nth-child(2n) {
    background-color: var(--Gray-Lighter);
  }
</style>
