<script lang='ts'>
  import { navigate } from 'svelte-routing'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import { storeKeys, userStorredConfig } from '@/store'

  if (location.search === '?register') {
    notifier.success($_('auth.thanksForRegistration'))
  }

  let loading = true
  const LOADING_TIMEOUT = 1000
  const interval = setInterval(() => {
    loading = false
  }, LOADING_TIMEOUT)

  $:if ($userStorredConfig?.id_lang_learning) {
    const learningLanguage = getIsoFromId($userStorredConfig.id_lang_learning)
    if (learningLanguage) {
      navigate('/' + learningLanguage)
      clearInterval(interval)
    } else {
      console.error($userStorredConfig, localStorage.getItem(storeKeys.userConfig))
    }
  }
</script>
<div>
  {#if !loading}
    <h2>{$_('home.chooseLearningLang')}</h2>
  {/if}
</div>
