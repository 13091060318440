export const formatWordProps = (word: any, iso: string, props: any) => {
  let content = word.content || ''
  if (iso === 'deu' && word.id_pos === 1) {
    content = content.charAt(0).toUpperCase() + content.slice(1)
    const gender = genderFromProps(props)
    if (gender === 'male') {
      content = 'der ' + content
    } else if (gender === 'female') {
      content = 'die ' + content
    } else if (gender === 'neuter') {
      content = 'das ' + content
    }
  } else if (iso === 'spa') {
    const gender = genderFromProps(props)
    if (gender === 'male') {
      content = 'el ' + content
    } else if (gender === 'female') {
      content = 'la ' + content
    }
  }
  return content
}

export const genderFromProps = (props: { group_name: string, type_name?: string }[]) => {
  if (props?.length > 0) {
    const genderProp = props.find((el: { group_name: string, type_name?: string }) => el.group_name === 'gender')
    if (genderProp) {
      return genderProp?.type_name
    }
  }
  return undefined
}
