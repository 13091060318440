<script lang='ts'>
  import { onMount } from 'svelte'
  import { link } from 'svelte-routing'

  import { getIdFromIso } from '@/helpers/apiCall'
  import { _ } from '@/helpers/i18n'

  import Flag from '@/components/forum/Flag.svelte'
  import Icon from '@/components/icons/Icon.svelte'

  export let scripts: Record<number, any[]> = {}
  export let editScript = (script: any) => {}
  export let deleteScript = (script: any) => {}
  export let iso = ''
  let languageId: number
  onMount(() => {
    languageId = getIdFromIso(iso)
  })

</script>
{#if languageId && Object.keys(scripts).length}
  <table>
    <thead>
    <tr>
      <th>{$_('mix.original')}</th>
      <th>{$_('mix.learn')}</th>
      <th>{$_('mix.translate')}</th>
      <th />
      <th />
    </tr>
    </thead>
    {#each Object.keys(scripts) as originalScriptId}
      {@const rowScripts = scripts[originalScriptId]}
      <tr class='script-block'>
        <td class='flags-list'>
          <div>
            {#each rowScripts as script}
              {#if script.id_language === languageId}
                <Flag id={script.id_language} noClick /> {script.title}
              {/if}
            {/each}
          </div>
        </td>
        <td class='flags-list'>
          <div>
            {#each rowScripts as script}
              {#if script.id_language !== languageId}
                <a href='/{iso}/advanced/learnScript/{script.id}' use:link>
                  <Flag id={script.id_language} />
                </a>
              {/if}
            {/each}
          </div>
        </td>
        <td class='flags-list'>
          <div>
            {#each rowScripts as script}
              {#if script.id_language === languageId}
                <a href='/{iso}/advanced/translateScript/{script.id}' use:link>
                  <Icon icon='Plus' weight='regular' />
                </a>
              {/if}
            {/each}
            {#each rowScripts as script}
              {#if script.id_language !== languageId}
                <a href='/{iso}/advanced/translateScript/{script.id}' use:link>
                  {#key script.id_language}
                    <Flag id={script.id_language} />
                  {/key}
                </a>
              {/if}
            {/each}
          </div>
        </td>
        {#each rowScripts as script}
          {#if script.id_language === languageId}
            <td class='medium flags-list'>
              <div>
                <a href='#top' on:click|preventDefault={() => {editScript(script)}}>
                  <Icon icon='PencilSimple' />
                </a>
                <a href='#top' on:click|preventDefault={() => {deleteScript(script)}}>
                  <Icon icon='Trash' />
                </a>
              </div>
            </td>
            <td class='short'>
              {#if script.public === 'yes'}
                {$_('mix.public')}
              {/if}
            </td>
          {/if}
        {/each}
      </tr>
    {/each}
  </table>
{/if}
<style lang='scss'>
  tr {
    > td, > th {
      border-right: none;
      border-left: none;
    }
  }

  .script-block {

    > .short, > .medium {
      width: 4rem;
      padding-right: 0;
      padding-left: 0;
      text-align: center;
    }

    > .medium {
      width: 10rem;
    }
  }

  .flags-list {
    padding: 0;

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem;
      align-items: center;
      min-height: 4.2rem;
      padding: 0.4rem;
    }
  }
</style>
