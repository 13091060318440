<script lang='ts'>
  import Icon from '@/components/icons/Icon.svelte'
  import VerticalMenu from '@/components/menu/VerticalMenu.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  let startX: number
  let endX: number
  let drawerVisible = false

  // Here I added thresholds to trigger a swipe left
  const threshold = 20

  function handleTouchStart (evt: TouchEvent) {
    startX = evt.touches[0].clientX
  }

  function handleTouchEnd (evt: TouchEvent) {
    endX = evt.changedTouches[0].clientX
    swipe()
  }

  function swipe () {
    if (endX - startX > threshold || endX - startX < -threshold) {
      drawerVisible = !drawerVisible
    }
  }

  function hideDrawer () {
    drawerVisible = false
  }

  function hideDrawerIfLink (e: MouseEvent) {
    if (e.target instanceof HTMLAnchorElement) {
      hideDrawer()
    }
  }
</script>

<div
  class='swipe-area'
  role='button'
  tabindex='0'
  on:touchstart={handleTouchStart}
  on:touchend={handleTouchEnd}
  on:click={() => {drawerVisible = !drawerVisible}}
  on:keypress={() => {}}
>
  <Icon icon={drawerVisible?'CaretDoubleLeft':'CaretDoubleRight'} size={16} weight='regular' />
</div>
<div
  class='cockpit-menu'
  class:-open={drawerVisible}
  role='button'
  tabindex='0'
  on:touchstart={handleTouchStart}
  on:touchend={handleTouchEnd}
>
  <FakeButton class='close' onClick={hideDrawer}>
    <Icon icon='X' weight='regular' />
  </FakeButton>
  <div
    class='icons-list'
    role='button'
    tabindex='0'
    on:keypress={() => {}}
    on:click={hideDrawerIfLink}
  >
    <VerticalMenu />
  </div>
</div>
<style lang='scss'>
  .cockpit-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 90;
    width: 100vw;
    height: 100vh;
    padding: 1.6rem;
    background: var(--main-background);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    &.-open {
      transform: translateX(0);
    }

    > :global(.close) {
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 20;
    }
  }

  .icons-list {
    overflow: scroll;
    width: 100%;
    height: 100vh;
  }

  .swipe-area {
    position: fixed;
    bottom: 12rem;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0.2rem 0.4rem 0;
    color: var(--Primary-Dark);
    background: var(--Primary-Ligther);
    border-radius: 0 0.4rem 0.4rem 0;
    cursor: pointer;

    &:hover {
      color: var(--Primary-Ligther);
      background: var(--Primary-Dark);
    }
  }

  @media (min-width: 1024px) {
    .cockpit-menu,
    .swipe-area {
      display: none;
    }
  }
</style>
