<script lang='ts'>
  import { createEventDispatcher } from 'svelte'

  export let show = false,
    variant = 'normal'

  const className = variant === 'normal' ? '' : ` ${variant}`
  const toggleShow = () => (show = !show)

  const dispatch = createEventDispatcher()
  $: dispatch('optionVisability', show)
</script>

<div class={'accordion-container _gap24' + className} class:-show={show}>
  <div
    class='accordionHeader'
    role='button'
    tabindex='0'
    on:click={toggleShow}
    on:keypress={toggleShow}
  >
    <slot name='header' />
  </div>
  <div class='accordionContent'>
    <hr class='divider' />
    <slot name='content' />
  </div>
</div>
<style lang='scss'>
  .accordion-container {
    position: relative;
    width: 100%;

    > .accordionHeader {
      /* this hack allow more clickable space in accordion */
      margin: -1rem 0 -1rem -1rem;
      padding: 1rem 0 1rem 1rem;
      font: var(--Medium-600);
      background: url(/images/arrowdown.svg) no-repeat 100% 60%;
      cursor: pointer;
    }

    > .accordionContent {
      display: none;
      width: 100%;

      > .divider {
        margin-top: 0;
        margin-bottom: 2.4rem;
      }
    }

    &.-show {
      > .accordionHeader {
        background-image: url(/images/arrowup.svg);
      }

      > .accordionContent {
        display: block;
      }
    }
  }

  @media (max-width: 768px) {
    .accordion-container > .accordionContent > .divider {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
    }
  }
</style>
