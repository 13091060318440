<script lang='ts'>
  import { languages } from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import { UserGuide } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  export let guides: UserGuide[] = [] as UserGuide[]

  const getGuides = async () => {
    guides = await fetchData('learn/getGuides')
  }

  getGuides()

  let guidesList: Record<string, UserGuide[]> = {}
  let guidesDate: string[] = []
  const learningLang = $userStorredConfig.id_lang_learning

  $:if (guides) {
    guidesList = {}
    for (const key in guides) {
      const date = guides[key].guide_date
      if (!guidesList[date]) {
        guidesList[date] = []
      }
      guidesList[date].push(guides[key])
    }
    guidesDate = Object.keys(guidesList)
  }

  const getNumOfWords = (params: string) => {
    const list = JSON.parse(params)?.wordsForReview
    let sum = 0
    if (list) {
      Object.keys(list).forEach(el => {
        sum += list[el]
      })
    }
    return sum
  }
</script>
<div class='_gap24'>
  <div class='guides-page'>
    {#if guides.length}
      <h2 class='heading'>{$_('guides.guides')}</h2>
    {:else}
      <h2 class='heading'>{$_('guides.noGuidesFound')}</h2>
    {/if}
    <div class='guidesLanguage'>
      {languages.filter(lang => lang.id === learningLang)?.[0]?.name}
    </div>

    {#each guidesDate as date, index}
      <div class='daily-guide' class:-first={index === 0}>
        {#if index === 0}
          <h3 class='todayHeading -first'>{$_('guides.todaySuggestions')}</h3>
        {:else if index === 1}
          <h4 class='todayHeading'>{$_('guides.guidesHistory')}</h4>
        {/if}
        <h4 class='guideDate' class:-first={index === 0}>{date}</h4>
        {#each guidesList[date] as day}
          <div class='single-guide type-{day.guide_type}'>
            <!--
            todo - include params
            todo - add logic to have different options for different guide types
             -->
            <span class='bullet'>{day.status === 'active' ? '•' : '☑'}</span>
            <span title={`${day.guide} Params: ${day.params}`}>
              {$_('guides.' + day.guide)}
              {#if day.guide === 'tooManyWordsForReview'}
                {@const count = getNumOfWords(day.params)}
                {@html $_('guides.youHave', {
                  values: {
                    count: `<strong class='param'>${count}</strong>`
                  }
                })}
              {/if}
            </span>
          </div>
        {/each}
      </div>
    {/each}

  </div>
</div>

<style lang='scss'>
  .guides-page {
    > .heading {
      margin: 0;
    }

    > .guidesLanguage {
      margin: 0.6rem 0;
      font-weight: bold;
    }
  }

  .single-guide {
    > .bullet {
      font-weight: bold;
      font-size: 2rem;
      color: var(--Accent-Medium);
    }
  }

  .daily-guide {
    padding: 1.2rem;
    font: var(--Regular-400);
    border: 0.2rem dotted var(--Gray-Light);

    &.-first {
      margin-bottom: 3.2rem;
      font-size: 1.4rem;
      background-color: var(--Gray-Lighter);
      border: 0.1rem dashed var(--Gray-Medium);
      border-radius: 1rem;
      box-shadow: var(--Shadow-Z);
    }

    > .todayHeading {
      display: block;
      margin: 0;
      font-weight: bold;
      text-align: center;;

    }

    > .guideDate {
      margin: 0;
      padding: 1.2rem 0;
      font-weight: bold;

      &.-first {
        margin-top: 0
      }
    }
  }
</style>
