<script>
  import Icon from '@/components/icons/Icon.svelte'
</script>

<a href='https://www.youtube.com/@langoidapp' rel='noopener noreferrer' target='_blank'>
  <Icon icon='YoutubeLogo' />
</a>
<a href='https://www.facebook.com/langoidApp' rel='noopener noreferrer' target='_blank'>
  <Icon icon='FacebookLogo' />
</a>
<a href='https://www.instagram.com/langoidapp/' rel='noopener noreferrer' target='_blank'>
  <Icon icon='InstagramLogo' />
</a>
<a href='https://twitter.com/LangoidApp' rel='noopener noreferrer' target='_blank'>
  <Icon icon='TwitterLogo' />
</a>
<a href='https://www.linkedin.com/company/tutor-d-o-o' rel='noopener noreferrer' target='_blank'>
  <Icon icon='LinkedinLogo' />
</a>
<a href='https://discord.gg/WTpx3fvWDs' rel='noopener noreferrer' target='_blank'>
  <Icon icon='DiscordLogo' />
</a>
<style lang='scss'>
  a {
    display: inline-block;
    margin: 0 1rem 0 0;
  }
</style>
