<script lang='ts'>
  import { ISO_LENGTH } from '@/config/constants'

  import { getIdFromIso, getIsoFromId } from '@/helpers/apiCall'
  import { navigateWithReset } from '@/helpers/navigateWithReset'

  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'

  import { menuLanguage, userStorredConfig } from '@/store'

  export let currentIso: string

  const setLanguage = (id: number) => {
    const iso = getIsoFromId(id)
    menuLanguage.set(iso)
    userStorredConfig.setKey('id_lang_learning', id)
    const segments = location.pathname.split('/')
    if (segments[1].length === ISO_LENGTH) {
      const newLocation = `/${iso}/` + location.pathname.slice(5)
      if (segments[2] === 'lessons' && segments[4] && /^\d+$/.test(segments[4] || '')) {
        navigateWithReset(`/${iso}/lessons`)
      } else if (segments[2] === 'task' && segments[5] && /^\d+$/.test(segments[5] || '')) {
        if (segments[4] === 'correct') {
          navigateWithReset(`/${iso}/task/${segments[3]}/find`)
        } else {
          navigateWithReset(`/${iso}/task/${segments[3]}/select`)
        }
      } else {
        navigateWithReset(newLocation)
      }
    } else {
      navigateWithReset('/')
    }
  }
</script>
<LanguageSelect
  onChange={setLanguage}
  selectedLanguage={getIdFromIso($menuLanguage || currentIso)}
  type='my'
  variant='menu'
/>
