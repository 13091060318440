<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { shuffle } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'

  import { Bread, ProgressLoadApiData, ReadLevels, ReadQuestions, ReadTheory } from '@/definitions/langoid'

  export let iso = ''
  let levels: ReadLevels[] = []
  let level = 1
  let variant: 'uppercase' | 'lowercase'

  let questions: ReadQuestions[] = []
  let theory: ReadTheory[][] = []
  let results: Record<number, boolean> = {}
  let breads: Bread[] = []
  let answers: Record<number, any> = {}
  let lastAnswer = ''
  const answerBlocks: Record<number, HTMLInputElement> = {}

  load(1)

  async function load (lvl: number) {
    answers = {}
    results = {}
    const data: ProgressLoadApiData = await fetchData('read/progressLoad', { level: lvl })
    level = data.level
    variant = data.variant
    lastAnswer = data.lastAnswer
    levels = Object.values(data.levels)
    questions = shuffle(data.questions)
    const tmpTheory: Record<any, any> = {}
    for (let i = 0; i < data.theory.length; i++) {
      const el = data.theory[i]
      if (el.slug !== '') {
        if (!tmpTheory[el.slug]) {
          tmpTheory[el.slug] = []
        }
        tmpTheory[el.slug].push(el)
      } else {
        tmpTheory[Math.random()] = [el]
      }
    }
    theory = Object.values(tmpTheory)
    breads = [{ text: '', url: `/${iso}` }, { text: 'levelPage.learnToRead', url: `/${iso}/reading-learn` }, { text: 'levelPage.practice' }]
  }

  async function apiCheck () {
    // eslint-disable-next-line sort-keys
    const data = await fetchData('read/progressCheckAnswers', { answers, level, lastAnswer })
    levels = Object.values(data.levels)
    for (const idResult in data.results) {
      const id = parseInt(idResult)
      const elem = answerBlocks[id]
      if (elem) {
        results = data.results
      }
    }
    if (data.pass) {
      notifier.success($_('testsPage.levelPassed'))
    } else {
      notifier.error($_('testsPage.levelFailed'))
    }
  }

  function checkAnswers () {
    if (Object.values(answers).length < questions.length || Object.values(answers).includes('')) {
      notifier.error($_('testsPage.answerAllQuestions'))
      return
    }

    const convertFunction = (x: string, variant: 'uppercase' | 'lowercase') => variant === 'uppercase' ? x.toUpperCase() : x.toLowerCase()
    let showMessage = false
    for (const id in answers) {
      const elem = answerBlocks[id]
      if (elem) {
        if (answers[id] !== convertFunction(answers[id], variant)) {
          elem.style.color = 'blue'
          elem.style.borderColor = 'red'
          showMessage = true
        }
      }
    }
    if (showMessage) {
      const errorMessage = variant === 'uppercase' ? $_('testsPage.upperCase') : $_('testsPage.lowerCase')
      notifier.error(errorMessage)
    } else {
      apiCheck()
    }
  }
</script>
<Breadcrumb {breads}>
  <h2 class='heading-mobile'>{$_('read.title')}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='reading' />
  </div>
</Breadcrumb>
<div class='levels-block'>
  {#each levels as lev, index}
    {@const levelProgress = (lev.progress / lev.questionsNum * 100).toFixed(1)}
    <div class='level-wrapper' class:-finished={lev.status === 'finished'}>
      <div
        class='each-level'
        class:-active={lev.level === level}
        class:-finished={lev.status === 'finished'}
        class:-started={lev.status === 'active'}
        role='button'
        tabindex='0'
        on:click={() => load(index + 1)}
        on:keypress={() => load(index + 1)}
      >
        <span>L{index + 1}</span>
        <small>{lev.questionsNum === 0 ? 0 : lev.status === 'finished' ? 100 : levelProgress}%</small>
        {#if lev.status === 'finished'}
          <span class='checked'><Icon icon='CheckCircle' size={14} weight='fill' /></span>
        {/if}
      </div>
      <ProgressBar maxProgress={lev.questionsNum} progress={lev.status === 'finished'? lev.questionsNum : lev.progress} />
    </div>
  {/each}
</div>
<div class='_gap24'>
  <h2>{$_('read.learn')}</h2>
  <hr />
  <div class='slots-block'>
    {#each theory as theoryItem}
      <div class='slot' class:-multiple={theoryItem.length > 1}>
        {#each theoryItem as slot, index}
          <div class='theory-item'>
            {#if slot.audio}
              <AudioPlayer src={slot.audio} variant='middle' />
            {/if}
            {variant === 'lowercase' ? slot.content || '' : slot.content.toUpperCase() || ''}
            {#if slot.ipa}
              <span class='ipa'>/<span>{slot.ipa}</span>/</span>
            {/if}
          </div>
        {/each}
      </div>
    {/each}
  </div>
</div>
<div class='_gap24'>
  <h2>{$_('read.practice')}</h2>
  <span class='alert-note'>
      {#if variant === 'lowercase'}
        {$_('read.noteLowercase')}
      {:else}
        {$_('read.noteUppercase')}
      {/if}
      </span>
  <hr />
  <div class='questions-block'>
    {#key level}
      <form on:submit|preventDefault={checkAnswers}>
        <div class='questions-list'>
          {#each questions as question, index}
            <div class='questionsDiv'>
              {#if question.audio}
                <AudioPlayer src={question.audio} variant='middle' />
              {/if}
              <input
                bind:this={answerBlocks[question.id]}
                class='inputField'
                class:-correct={results[question.id] === true}
                class:-wrong={results[question.id] === false}
                autocomplete='off'
                type='text'
                bind:value={answers[question.id]}
                name='answer'
              />
            </div>
          {/each}
        </div>
        <hr />
        <input
          class='checkAnswersButton'
          type='submit'
          value={$_('read.checkAnswers')}
        />
      </form>
    {/key}
  </div>
</div>
<style lang='scss'>
  .levels-block {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  .level-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    > :global(.progress-container) {
      position: absolute;
      bottom: -0.3rem;
      left: 0.6rem;
      width: 80%;
    }

    &.-finished {
      > :global(.progress-container .progress-bar-cover .progress-bar-status.-filled) {
        background-color: var(--success-text);
      }
    }
  }

  .each-level {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 6.4rem;
    min-height: 5.6rem;
    padding: 1rem;
    font: var(--Semibold-400);
    text-align: center;
    color: var(--text-primary-color);
    background-color: var(--Gray-Medium);
    border-radius: 1.2rem;
    cursor: pointer;

    &:hover {
      background-color: var(--Gray-Med);
    }

    &.-started {
      color: var(--accent-text);
      background: var(--accent-background);

      &:hover {
        background-color: var(--Accent-Light);
      }
    }

    &.-finished {
      color: var(--success-text);
      background: var(--success-background);

      &:hover {
        background-color: var(--Success-Light);
      }
    }

    &.-active {
      color: var(--Base-White);
      background: var(--Primary-Medium);

      &:hover {
        background-color: var(--Primary-Dark);
      }
    }

    > .checked {
      position: absolute;
      top: 0;
      right: 0.4rem;
      z-index: 20;
      color: var(--success-text);
    }
  }

  .slots-block {
    display: grid;
    grid-column: 1/5;
    grid-template-columns: repeat(5, 1fr);
    gap: 2.4rem;
    align-items: center;

    > .slot {
      position: relative;
      display: flex;
      min-width: 12.8rem;
      min-height: 4.8rem;

      &.-multiple {
        display: flex;
        flex-direction: column;
        background: var(--Error-Light);
      }
    }
  }

  h2 + .alert-note {
    font: var(--Regular-400);
    color: var(--text-primary-color);
  }

  .theory-item {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
    font: var(--Regular-600);
    color: var(--text-primary-color);

    > .ipa {
      font-size: 0.8em;
      color: var(--text-primary-color);

      > span {
        color: var(--Warning-Light);
      }
    }
  }

  .questions-list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;
    justify-content: center;

    > .questionsDiv {
      display: flex;
      gap: 0.8rem;

      > .inputField {
        width: 100%;
        height: 4rem;
        padding: 0.4rem;
        color: var(--Gray-Darker);

        &.-correct {
          background: var(--Success-Lighter);
          border-color: var(--Success-Medium);
        }

        &.-wrong {
          background: var(--Error-Lighter);
          border-color: var(--Error-Medium);
        }
      }
    }

    + hr {
      margin: 2.4rem 0;
    }
  }

  @media (max-width: 768px) {
    .questions-list {
      grid-template-columns: repeat(1, 1fr);
      margin: 3.2rem 0;
      background: var(--Gray-Lighter);
      border: 0.1rem solid var(--Primary-Lighter);
    }

    .slots-block {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }

    .levels-block {
      grid-template-columns: repeat(2, 1fr);
    }
  }
</style>
