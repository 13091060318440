<script lang='ts'>
  import { writable } from 'svelte/store'

  const slideIndex = writable(0)
  let totalSlides: number
  let slider: HTMLDivElement

  const slides = [
    { description: 'The fastest way to get conversation in a new language!', heading: 'Learn a language super fast!', img: '/images/home/slides/lesson.png' },
    { description: 'You will get real tools for learning!', heading: 'This is not game!', img: '/images/home/slides/tests.png' },
    { description: 'Be corrected by other users of our app!', heading: 'Get feedback from real users', img: '/images/home/slides/tasks.png' },
    {
      description: 'This is not just chat, this is set of tools for guided learning.',
      heading: 'Learn with the help of other users',
      img: '/images/home/slides/chat.png'
    },
    { description: 'While using forum you are still learning language!', heading: 'Talk with other users in the forum!', img: '/images/home/slides/forum.png' },
    {
      description: 'Set real goal and let app to track it. It will even tell you if what you set is not realistic! Also while learning it will give you feedback are you slower or faster than expected!',
      heading: 'Set goals!',
      img: '/images/home/slides/goals.png'
    },
  ]

  $slideIndex = $slideIndex % slides.length // ensure slideIndex stays within bounds

  totalSlides = slides.length
  const dotsCount: number[] = Array.from({ length: totalSlides }, (_, i) => i)

  const activateDot = (index: number) => {
    slideIndex.set(index)
  }

  // Reactive statement to handle slide transition when slideIndex changes
  $: slider && (slider.style.transform = `translateX(-${$slideIndex * (100 / totalSlides)}%)`)

  // handle swipe
  let startX: number
  let endX: number

  const handlePointerDown = (e: PointerEvent) => {
    startX = e.clientX
  }

  const handlePointerUp = (e: PointerEvent) => {
    endX = e.clientX
    const diff = startX - endX

    if (Math.abs(diff) < 20) { // Consider it a swipe only if the movement is big enough
      return
    }
    let newIndex: number
    if (diff > 0) {
      newIndex = ($slideIndex + 1) % slides.length
    } else {
      newIndex = ($slideIndex - 1 + slides.length) % slides.length // adding length before modulo operation to handle negative values
    }
    slideIndex.set(newIndex)
  }
  // end handle swipe
</script>

<div class='home-slides-wrapper _desktopOnly'>
  <div
    bind:this={slider}
    class='slider-images'
    on:pointerdown={handlePointerDown}
    on:pointerup={handlePointerUp}
    style:width={slides.length*100+'%'}
  >
    {#each slides as slide, i}
      <div class='slide' style:width={100/slides.length+'%'}>
        <img alt='' src={slide.img} on:dragstart|preventDefault />
        <div>
          <h2>{slide.heading}</h2>
          <p>{slide.description}</p>
        </div>
      </div>
    {/each}
  </div>
  <div class='slider-dots'>
    {#each dotsCount as _, i}
      <span
        class='dot'
        class:-active={i === $slideIndex}
        role='button'
        tabindex={1}
        on:click={() => activateDot(i)}
        on:keypress={e => e.preventDefault()}
      />
    {/each}
  </div>
</div>

<style lang='scss'>
  .home-slides-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6.4rem;
    overflow: hidden;
    width: 100%;
    max-width: 58.4rem;
    min-height: calc(100vh - 3.2rem);
    text-align: center;
    background: var(--Gray-Light);
    border-radius: 1.6rem;
  }

  .slider-images {
    display: flex;
    transition: margin-left 0.5s;
    transition: transform 0.5s ease-out;

    > .slide {
      display: flex;
      flex-direction: column;
      gap: 3.2rem;
      justify-content: center;
      padding: 3.2rem;

      > img {
        width: 100%;
        border: solid 0.4rem var(--Gray-Lighter);
        border-radius: 1.6rem;
      }
    }
  }

  .slider-dots {
    display: flex;
    justify-content: center;

    > .dot {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin: 0 2px;
      background-color: var(--Gray-Medium);
      border-radius: 1rem;
      cursor: pointer;
      transition: background-color 0.6s ease;

      &.-active {
        background-color: var(--Gray-Darker);
      }
    }
  }
</style>
