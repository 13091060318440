import { MS_PER_SEC } from '@/config/constants'

import { CHAT_URL, getCookie } from '@/helpers/apiCall'
import { logOnServer } from '@/helpers/errorsHelper'

const jwt = getCookie('jwt')

let socket: WebSocket
let socketOpen = false
const DEFAULT_SOCKET_ERROR_LINE = 36
const RECONNECTION_DELAY = 5000
let reconnectAttempts = 0
const MAX_RECONNECT_ATTEMPTS = 10
const createSocket = () => new WebSocket(`${CHAT_URL}?id=${jwt}`)
const getSocket = () => {
  if (!socket) {
    socket = createSocket()
  }

  return socket
}
let globalOnMessage: any

export const initializeSocket = (onMessage: any) => {
  const socket = getSocket()
  globalOnMessage = onMessage

  socket.addEventListener('open', handleSocketOpen)
  socket.addEventListener('error', handleSocketError)
  socket.addEventListener('message', onMessage)
  socket.addEventListener('close', handleSocketClose || handleSocketOpen)

  return socket
}

export const send = (obj: any, type = 'sendMessage') => {
  try {
    // eslint-disable-next-line no-console
    console.log(type, { ...obj, jwt: '' })
    obj.jwt = jwt
    obj.type = type
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(obj))
    } else {
      // eslint-disable-next-line no-console
      console.log('Attempted to send message to closed connection')
    }
  } catch (e) {
    logOnServer(`send socket error ${type}`, window.location.href, DEFAULT_SOCKET_ERROR_LINE)
  }
}

function handleSocketOpen () {
  if (socket.readyState === 1) {
    // eslint-disable-next-line no-console
    console.log('WebSocket is open now.')
    send({ open: 'open' })
    socketOpen = true
  } else if (!socketOpen) {
    logOnServer('trying to reconnect: ', window.location.href)
    setTimeout(() => {
      handleSocketOpen()
    }, MS_PER_SEC)
  }
}

function reconnectSocket () {
  // Avoid infinite reconnection attempts
  if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
    return
  }
  reconnectAttempts++

  // Close the current socket if it is still open
  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.close()
  }

  // Create a new socket
  socket = createSocket()

  // Attach your event handlers again
  socket.addEventListener('open', handleSocketOpen)
  socket.addEventListener('error', handleSocketError)
  socket.addEventListener('message', globalOnMessage)
  socket.addEventListener('close', handleSocketClose)
}

export function handleSocketClose () {
  // eslint-disable-next-line no-console
  console.log('WebSocket is closed now.')
  setTimeout(reconnectSocket, RECONNECTION_DELAY)
}

export function handleSocketError (event: Event) {
  console.error('WebSocket error observed:', event)
}
