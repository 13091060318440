<script lang='ts'>
  import { link, navigate } from 'svelte-routing'

  import { resizeTextarea } from '@/helpers/actions/resizeTextarea'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'

  export let iso = ''
  let showForm = false,
    name: string,
    content: string,
    stories: {
      id: number,
      name: string
    }[] = []

  loadTexts()

  function importNew () {
    showForm = !showForm
  }

  async function doImport () {
    const data = await fetchData('learn/importNew', { content, name })
    navigate(`/${iso}/advanced/import/${data.id}`)
  }

  async function loadTexts () {
    const data = await fetchData('learn/importList', {})
    const tmp = []
    const texts = data.texts
    const keys = Object.keys(texts).reverse()
    for (const i in keys) {
      const key = keys[i]
      tmp.push({ id: parseInt(key), name: texts[key] })
    }
    stories = tmp
  }
</script>
<div class='_gap24'>
  <Breadcrumb breads={[{text:'',url:`/${iso}`},{text: 'Imports'}]} />
  <button type='button' on:click={importNew}>{$_('import.importText')}</button>
  {#if showForm}
    <form class='import-form _form' action='' method='post' on:submit|preventDefault={doImport}>
      <p><label for='title'>{$_('import.title')}</label>
        <input class='aaa' type='text' bind:value={name} name='title' /></p>
      <p><label for='content'>{$_('mix.content')}</label>
        <textarea id='content' class='content-to-import' bind:value={content} use:resizeTextarea /></p>
      <p>
        <br />
        <input type='submit' value={$_('mix.save')} /></p>
    </form>
  {:else}
    {#each stories as story}
      <div>
        <a href='/{iso}/advanced/import/{story.id}' use:link>{story.name}</a>
      </div>
    {/each}
  {/if}
</div>
<style lang='scss'>
  .content-to-import {
    min-height: 20rem;
  }
</style>
